import React, { FC } from 'react' 
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react'
import {
    CardStateApi,
    AddedCard,
} from '../CardState';
import {
    selectCardImageUrl,
    themeToCategory,
    categoryToColors,
} from '..'; 
import {
    getRarityLabel,
    getRaritySymbol,
} from '../rarity';

const Wrap = styled.div`
    position: fixed;
    top: 2rem;
    left: 0;
    width: 100vw;
    min-height: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    z-index: 999;
`;


const cardAnim = keyframes`
    from {
        transform: translate3d(0, 2rem, 0) scale(1.2);
        opacity: 0;
        max-width: 0;
    }
    20% {
        transform: translate3d(0, 5px, 0);
        opacity: 1;
        max-width: 5rem;
    }
    80% {
        transform: translate3d(0, -5px, 0);
        opacity: 1;
        max-width: 5rem;
    }
    to {
        transform: translate3d(0, -5rem, 0) scale(1);
        opacity: 0;
        max-width: 0;
    }
`;
const CardMini = styled.div<{ borderColor: string; backgroundColor: string; }>`
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 3em;
    padding: .25em;
    background: linear-gradient(-8deg, #00000044, #00000022 8%, #00000000 60%),
                linear-gradient(180deg, #f4f1e0 20%, #e8e5d5 80%);
    border: 2px solid ${props => props.borderColor};
    border-bottom: .5em solid ${props => props.backgroundColor};
    > p {
        margin: 0;
        padding: .2rem 0;
        color: #000000cc;
    }
    overflow: hidden;
    opacity: 0;
    max-width: 0;
    animation: ${cardAnim} 5s ease;
`;
const CardImg = styled.img`
    width: 100%;
    height: auto;
    aspect-ratio: 1;
    border-radius: 0 .2em 0 .2em;
    display: block;
    background-color: #00000066;
`;

export const AddedCards: FC<{
    added: AddedCard[],
    showCard: CardStateApi['showCard'],
}> = ({
    added,
    showCard,
}) => {
    return !added.length ? null : (
        <Wrap>
            {added.map(a => {
                const category = themeToCategory[a[0]];
                const colors = categoryToColors[category];

                return (
                    <CardMini
                        key={a.join('')}
                        borderColor={colors.iconsBorder}
                        backgroundColor={colors.iconsBg}
                        onClick={() => showCard(a[0], a[1])}
                    >
                        <CardImg
                            src={selectCardImageUrl(a[0], a[1])}
                        />
                        <p>
                            {getRarityLabel(a[0], a[1])}
                            {getRaritySymbol(a[0], a[1])}
                        </p>
                    </CardMini>
                );
            })}
        </Wrap>
    );
};

