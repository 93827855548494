import { Scene } from "./ScenesState";
import { EmojiStringImageEncoder, ImageDataBuilder } from "image";
import { Item, ItemConfig } from "plugins/items";
import { MapApp } from "maps";
import { theme } from "color";
import { HudData } from "components";
import { Coord } from "coords";
import { Throttler } from "game";
import { Skin } from "plugins/player";
import { Bus } from "message";

export class Robots extends Scene<[
    'robotHead',
    'robotArm',
    'robotLeg',
    'battery',
    'software',
    'monster',
    'warning',
    'vulnerable',
]> {
    totalMonsters = 50;
    hudId = 'robotsHud';
    bus: Bus;
    enableSkin: (skin: Skin) => void;
    setSkin: (skin: Skin | undefined) => void;
    getSkin: () => Skin | undefined;
    adjustMarbles: (qty: number) => number | false;
    constructor(
        emojiImage: EmojiStringImageEncoder,
        bus: Bus,
        enableSkin: (skin: Skin) => void,
        setSkin: (skin: Skin | undefined) => void,
        getSkin: () => Skin | undefined,
        adjustMarbles: (qty: number) => number | false,
    ) {
        super(
            emojiImage.makePlugin({
                robotHead: { icon: '🤖', size: 12, shrink: 2 },
                robotArm: { icon: '🦾', size: 12, shrink: 2 },
                robotLeg: { icon: '🦿', size: 12, shrink: 2 },
                battery: { icon: '🔋', size: 12, shrink: 2 },
                software: { icon: '💾', size: 12, shrink: 2 },
                monster: { icon: '👾', size: 14, shrink: 2 },
                warning: { icon: '💥', size: 18, shrink: 2 },
                vulnerable: { icon: '🟡', size: 16, shrink: 2 },
            })
        );
        this.bus = bus;
        this.enableSkin = enableSkin;
        this.setSkin = setSkin;
        this.getSkin = getSkin;
        this.adjustMarbles = adjustMarbles;
    }
    getPrimaryItemsConfig(map: MapApp): ItemConfig[] {
        const itemMinMax = map.gps.generateFromCenter();

        return [
            {
                name: 'robotHead',
                ...itemMinMax(10),
                minDist: 30,
                minLevel: 3,
                color: theme.player.core,
                customRender: this.emojis.makeRenderer('robotHead'),
                showOnFullRender: true,
                onCollect: this.onCollectHead,
            },
            {
                name: 'robotArm',
                ...map.gps.zoomMinMaxQuandrant(2, 3),
                minDist: 200,
                minLevel: 3,
                color: theme.player.core,
                customRender: this.emojis.makeRenderer('robotArm'),
                showOnFullRender: true,
                showOverFog: true,
                conceal: true,
                onCollect: this.onCollectPart,
            },
            {
                name: 'robotLeg',
                ...map.gps.zoomMinMaxQuandrant(4, 1),
                minDist: 200,
                minLevel: 3,
                color: theme.player.core,
                customRender: this.emojis.makeRenderer('robotLeg'),
                showOnFullRender: true,
                showOverFog: true,
                conceal: true,
                onCollect: this.onCollectPart,
            },
            {
                name: 'battery',
                ...map.gps.zoomMinMaxQuandrant(3, 2),
                minDist: 200,
                minLevel: 3,
                color: theme.player.core,
                customRender: this.emojis.makeRenderer('battery'),
                showOnFullRender: true,
                showOverFog: true,
                conceal: true,
                onCollect: this.onCollectPart,
            },
            {
                name: 'software',
                ...map.gps.zoomMinMaxQuandrant(1, 4),
                minDist: 200,
                minLevel: 3,
                color: theme.player.core,
                customRender: this.emojis.makeRenderer('software'),
                showOnFullRender: true,
                showOverFog: true,
                conceal: true,
                onCollect: this.onCollectPart,
            },
            ...(
                new Array(this.totalMonsters).fill({
                    name: 'monster',
                    ...itemMinMax(70),
                    minDist: 20,
                    minLevel: 3,
                    color: theme.scenes.baddie,
                    onCollect: this.onCollectMonster,
                    customRender: this.renderMonster,
                    showOnFullRender: true,
                    minOtherItemDist: 50,
                })
            )
        ];
    }
    getSecondaryItemsConfig(map: MapApp): ItemConfig[] {
        return [];
    }

    renderMonster = (
        data: ImageDataBuilder,
        itemDataLocation: Coord,
        monster: Item
    ) => {
        if (this.getSkin() === 'robot') {
            this.emojis.apply('vulnerable', data, itemDataLocation);
        } else if (monster.found) {
            this.emojis.apply('warning', data, [
                itemDataLocation[0] - 1,
                itemDataLocation[1] - 2,
            ]);
        }
        this.emojis.apply('monster', data, itemDataLocation);
    };
    onCollectHead = (map: MapApp, head: Item) => {
        this.primary('robotArm').config.conceal = false;
        this.primary('robotLeg').config.conceal = false;
        this.primary('battery').config.conceal = false;
        this.primary('software').config.conceal = false;

        this.updateHud();
        return false;
    };
    onCollectPart = () => {
        const { partsFound } = this.selectPartsData();

        // there are 4 parts and this part won't yet be "found"
        if (partsFound === 3) {
            this.enableSkin('robot');
            this.setSkin('robot');
        }

        this.updateHud();
        return false;
    }

    monsterThrottler = new Throttler(3);
    onCollectMonster = (map: MapApp, monster: Item) => {
        if (this.getSkin() === 'robot') {
            this.adjustMarbles(1);
            this.updateHud();
            return false;
        }
        if (this.monsterThrottler.waits()) {
            return true;
        }
        if (monster.found) {
            this.bus.addQuiet(
                this.adjustMarbles(-1) === false
                    ? 'No marbles left to take!'
                    : 'OUCH!',
                3
            );
        } else {
            this.bus.addQuiet('WARNING! Monsters take marbles! Use robot to defeat.', 3);
        }
        return true;
    };
    
    selectPartsData = () => {
        const arm = this.primary('robotArm').found;
        const leg = this.primary('robotLeg').found;
        const battery = this.primary('battery').found;
        const software = this.primary('software').found;
        const partsFound = (arm ? 1 : 0) + (leg ? 1 : 0) + (battery ? 1 : 0) + (software ? 1 : 0);

        return {
            arm,
            leg,
            battery,
            software,

            partsFound,
            allFound: partsFound === 4,
        };
    }
    getHudData(): HudData | undefined {
        const head = this.primary('robotHead');

        if (!head.found) {
            return undefined;
        }

        const {
            partsFound,
            allFound,
            arm,
            leg,
            battery,
            software,
        } = this.selectPartsData();

        const monsters = this.allPrimary('monster');
        const monstersRemoved = monsters.reduce(
            (acc, monster) => acc + (monster.removed ? 1 : 0),
            0
        );
        const incomplete = monstersRemoved < monsters.length;

        return {
            modal: !allFound || monstersRemoved === 0 || !incomplete,
            status: incomplete
                ? 'open'
                : 'completed',
            content: !incomplete ? (
                'The monster threat has been neutralized. The citizens of this land can rest easy.'
            ) : partsFound === 0 ? (
                'There are monsters around the map, be careful not to touch them! I can help, but you have to find my parts and put me back together! Check your map.'
            ) : allFound ? (
                `ROBOT SYSTEMS OPERATIONAL. Wear the robot suit to destroy monsters. ${monsters.length - monstersRemoved} remaining.`
            ) : (
                `You've found ${partsFound} parts, ${4 - partsFound} to go! Remember, don't touch the monsters!`
            ),
            emojis: allFound
                ? this.emojis.config.robotHead.icon
                : this.emojis.config.monster.icon,
            items: allFound ? [
                {
                    symbol: this.emojis.config.monster.icon,
                    current: monstersRemoved,
                    total: monsters.length,
                }
            ] : [
                {
                    symbol: this.emojis.config.robotHead.icon,
                    current: 1,
                },
                {
                    symbol: this.emojis.config.robotArm.icon,
                    current: arm ? 1 : 0,
                },
                {
                    symbol: this.emojis.config.robotLeg.icon,
                    current: leg ? 1 : 0,
                },
                {
                    symbol: this.emojis.config.battery.icon,
                    current: battery ? 1 : 0,
                },
                {
                    symbol: this.emojis.config.software.icon,
                    current: software ? 1 : 0,
                },
            ]
        }
    }
};
