import { get, set } from 'idb-keyval';
import { BaseStore } from "./BaseStore";

export class IdbStore<T extends object> extends BaseStore<T> {
    select = async () => await get(this.key);
    hasSaved = async () => !!(await get(this.key));
    process = async () => {
        const queued = this.queue.shift();
        if (!queued || this.saving) {
            return;
        }
        this.saving = true;
        const {
            data,
            onStart,
            onEnd,
        } = queued;
        onStart && onStart();
        try {
            await set(
                this.key,
                data
            );
        } catch(caught: any) {
            window.alert(`Problem saving: ${caught && caught.message}`);
        } finally {
            onEnd && onEnd();
            this.saving = false;
            if (this.queue.length) {
                this.process();
            }
        }
    };
};

