import { MapApp } from 'maps';
import { theme } from 'color';
import {
    Coord,
    distance,
    angle,
    distFromCoordMinMax,
} from 'coords';
import { Bus } from 'message/Bus';
import { ItemState, Item, ItemSerialized, ItemConfig } from 'plugins/items';
import { StatusState } from 'game';
import { EmojiStringImageEncoder } from 'image';
import { GiveCard } from 'cards';
import { Skin } from '../player';

const directions = [
    'w',
    'nw',
    'n',
    'ne',
    'e',
    'se',
    's',
    'sw',
];

export type Direction = typeof directions[number];

export type VelocityStateSerialized = {
    max: number;
    direction: Direction;
    multipliers: {
        mountains: number,
        road: number,
        trees: number,
        normal: number,
        waterShallow: number,
        waterMid: number,
        waterDeep: number,
    }
    items: ItemSerialized[];
};

export type Speed = keyof VelocityStateSerialized['multipliers'];

const APPLY_INTERVAL = 1000 / 24;
const zeroZero: Coord = [0, 0];

type Multipliers = Record<Speed, number>;
const defaultMultipliers: Multipliers = {
    mountains: .1,
    trees: .5,
    road: 1,
    normal: .9,
    waterShallow: .2,
    waterMid: 0,
    waterDeep: 0,
};


const skinSlideMultipliers: Partial<
    Record<Skin, Partial<Multipliers>>
> = {
    elephant: {
        mountains: .2,
        waterShallow: .15,
        waterMid: 0,
        waterDeep: 0,
        trees: .2,
        normal: .2,
    },
    monkey: {
        trees: .1,
    },
    duck: {
        mountains: 1,
        waterShallow: .4,
        waterMid: .2,
        waterDeep: .2,
        trees: 1,
        normal: .7,
    },
    fish: {
        mountains: 1,
        waterShallow: .05,
        waterMid: .05,
        waterDeep: .05,
        normal: .1,
    },
};
const selectSlideMultiplier = (
    speed: Speed,
    skin?: Skin,
): number | undefined => {
    if (!skin) return undefined;

    const slideMultipliers = skinSlideMultipliers[skin];
    if (!slideMultipliers) return undefined;

    return slideMultipliers[speed];
};
const skinMultipliers: Record<Skin, Partial<Multipliers>> = {
    robot: {
        waterShallow: 0,
        waterMid: 0,
    },
    raccoon: {
        mountains: .1,
        waterShallow: .1,
        waterMid: 0,
        trees: .5,
        normal: .5,
    },
    lightning: {},
    elephant: {
        mountains: .4,
        waterShallow: .4,
        trees: .5,
        normal: .5,
        waterMid: .05,
        waterDeep: 0,
    },
    monkey: {
        waterShallow: .2,
        waterMid: 0,
        trees: 1.7,
        normal: .7,
    },
    horse: {
        trees: .4,
        normal: 2,
        road: 2,
        waterShallow: 1.8,
        waterMid: .2,
        waterDeep: .1,
    },
    duck: {
        waterShallow: 1.1,
        waterMid: 1.4,
        waterDeep: 1.5,
        trees: .2,
        normal: .4,
    },
    fish: {
        mountains: 0,
        waterShallow: 2,
        waterMid: 2,
        waterDeep: 2,
        trees: 0,
        normal: 0,
    },
    unicorn: {
        mountains: 2.5,
        waterShallow: 2.5,
        waterMid: 2.5,
        waterDeep: 2.5,
        trees: 2.5,
        normal: 2.5,
    },
};

export class VelocityState {

    max: number = 1;
    direction: Direction = 's';

    items: ItemState;
    bus: Bus;
    status: StatusState;
    emojiImage: EmojiStringImageEncoder;
    private _v: Coord | undefined = undefined;
    private multipliers = defaultMultipliers;
    giveCard: GiveCard;

    constructor(
        items: ItemState,
        bus: Bus,
        status: StatusState,
        emojiImage: EmojiStringImageEncoder,
        giveCard: GiveCard,
    ) {
        this.items = items;
        this.bus = bus;
        this.status = status;
        this.emojiImage = emojiImage;
        this.giveCard = giveCard;
    }

    initialize() {
        let primaryMountains: Item[];
        const baseMountainConfig: ItemConfig = {
            name: 'mountain',
            min: [0, 0],
            max: [0, 0],
            minDist: 0,
            minLevel: 5,
            color: theme.levels.land.hills,
            showOnFullRender: true,
            showOnFullRenderAtHome: true,
            hidePointer: true,
            collectRadius: 12,
            onCollect: this.onTouchMountain,
            customRender: this.emojiImage.makeApply('mountain'),
        };

        this.items.addFactory(this, map => {
            const itemMinMax = map.gps.generateFromCenter();

            primaryMountains = [
                new Item({
                    ...baseMountainConfig,
                    ...map.gps.zoomMinMaxQuandrant(1, 3),
                    failureMinMax: map.gps.zoomMinMaxQuandrant(1, 1),
                    minDist: 200,
                }),
                new Item({
                    ...baseMountainConfig,
                    ...map.gps.zoomMinMaxQuandrant(1, 2),
                    failureMinMax: map.gps.zoomMinMaxQuandrant(1, 1),
                    minDist: 200,
                }),
                new Item({
                    ...baseMountainConfig,
                    ...map.gps.zoomMinMaxQuandrant(2, 1),
                    failureMinMax: map.gps.zoomMinMaxQuandrant(2, 2),
                    minDist: 200,
                }),
                new Item({
                    ...baseMountainConfig,
                    ...map.gps.zoomMinMaxQuandrant(2, 4),
                    failureMinMax: map.gps.zoomMinMaxQuandrant(2, 2),
                    minDist: 200,
                }),
                new Item({
                    ...baseMountainConfig,
                    ...map.gps.zoomMinMaxQuandrant(4, 2),
                    failureMinMax: map.gps.zoomMinMaxQuandrant(4, 4),
                    minDist: 200,
                }),
                new Item({
                    ...baseMountainConfig,
                    ...map.gps.zoomMinMaxQuandrant(4, 3),
                    failureMinMax: map.gps.zoomMinMaxQuandrant(4, 4),
                    minDist: 200,
                }),
                new Item({
                    ...baseMountainConfig,
                    ...map.gps.zoomMinMaxQuandrant(3, 4),
                    failureMinMax: map.gps.zoomMinMaxQuandrant(3, 3),
                    minDist: 200,
                }),
                new Item({
                    ...baseMountainConfig,
                    ...map.gps.zoomMinMaxQuandrant(3, 1),
                    failureMinMax: map.gps.zoomMinMaxQuandrant(3, 3),
                    minDist: 200,
                }),
            ];

            return [
                new Item({
                    name: 'shoes',
                    ...itemMinMax(6),
                    minDist: 30,
                    minLevel: 3,
                    color: theme.player.core,
                    showOnFullRender: true,
                    onCollect: this.onCollectShoes,
                    customRender: this.emojiImage.makeApply('shoes'),
                }),
                new Item({
                    name: 'boots',
                    ...itemMinMax(50),
                    minDist: 400,
                    minLevel: 3,
                    color: theme.player.core,
                    showOnFullRender: true,
                    onCollect: this.onCollectBoots,
                    customRender: this.emojiImage.makeApply('boots'),
                }),
                new Item({
                    name: 'goggles',
                    ...itemMinMax(30),
                    minDist: 100,
                    minLevel: 2,
                    maxLevel: 2,
                    color: theme.player.core,
                    showOnFullRender: true,
                    onCollect: this.onCollectGoggles,
                    customRender: this.emojiImage.makeApply('goggles'),
                }),
                new Item({
                    name: 'surfboard',
                    ...itemMinMax(50),
                    minDist: 300,
                    minLevel: 1,
                    maxLevel: 1,
                    color: theme.player.core,
                    showOnFullRender: true,
                    onCollect: this.onCollectSurfboard,
                    customRender: this.emojiImage.makeApply('surf'),
                }),
                ...primaryMountains,
            ];
        });
        this.items.addFactory(this, map => {
            const newMountains = primaryMountains.reduce(
                (acc, primaryMtn) => {
                    return [
                        ...acc,
                        new Item({
                            ...baseMountainConfig,
                            ...distFromCoordMinMax(primaryMtn.loc, 120),
                            failureMinMax: distFromCoordMinMax(primaryMtn.loc, 100),
                            minDist: 80,
                            minMaxDistFrom: primaryMtn.loc,
                            minLevel: 4,
                            omitOnError: true,
                            customRender: primaryMtn.config.customRender,
                        }),
                        new Item({
                            ...baseMountainConfig,
                            ...distFromCoordMinMax(primaryMtn.loc, 160),
                            failureMinMax: distFromCoordMinMax(primaryMtn.loc, 200),
                            minDist: 130,
                            minMaxDistFrom: primaryMtn.loc,
                            minLevel: 4,
                            omitOnError: true,
                            customRender: primaryMtn.config.customRender,
                        }),
                    ];
                },
                [] as Item[]
            );
            primaryMountains = [
                ...primaryMountains,
                ...newMountains,
            ]
            return newMountains;
        });
        this.items.addFactory(this, map => {
            return primaryMountains.reduce(
                (acc, primaryMtn) => {
                    const mtnRange = new Array(
                        map.ints.next(8, 15)
                    ).fill(null).map(() =>
                        new Item({
                            ...baseMountainConfig,
                            ...distFromCoordMinMax(primaryMtn.loc, 100),
                            minDist: 5,
                            minMaxDistFrom: primaryMtn.loc,
                            minLevel: 4,
                            omitOnError: true,
                            customRender: primaryMtn.config.customRender,
                        })
                    );
                    return [
                        ...acc,
                        ...mtnRange,
                    ];
                },
                [] as Item[]
            )
        });
    }

    load(serialized: VelocityStateSerialized) {
        this.max = serialized.max;
        this.direction = serialized.direction;
        this.multipliers = {
            ...defaultMultipliers,
            ...serialized.multipliers
        };

        const handlers = {
            'shoes': this.onCollectShoes,
            'boots': this.onCollectBoots,
            'goggles': this.onCollectGoggles,
            'surfboard': this.onCollectSurfboard,
            'mountain': this.onTouchMountain,
        };
        const renders = {
            'shoes': this.emojiImage.makeApply('shoes'),
            'boots': this.emojiImage.makeApply('boots'),
            'goggles': this.emojiImage.makeApply('goggles'),
            'surfboard': this.emojiImage.makeApply('surf'),
            'mountain': this.emojiImage.makeApply('mountain'),
        };


        this.items.addFactory(this, map =>
            serialized.items.map(itemSerialized =>
                Item.load(
                    itemSerialized,
                    // @ts-ignore
                    handlers[itemSerialized.config.name],
                    // @ts-ignore
                    renders[itemSerialized.config.name],
                )
            )
        );
    };

    touchingMountain: boolean = false;
    onTouchMountain = () => {
        this.touchingMountain = true;
        return true;
    };

    onCollectShoes = () => {
        this.status.put('toolShoes', true);
        this.giveCard('shoes');

        this.max = 3;
    };

    onCollectBoots = () => {
        this.bus.addQuiet('you found boots. you can move through trees faster.', 10);
        this.status.put('toolBoots', true);
        this.giveCard('boots');

        this.max = 3;
        this.multipliers.mountains += .15;
        this.multipliers.trees += .15;
        this.multipliers.normal += .15;
    };

    onCollectGoggles = () => {
        this.bus.addQuiet('you found goggles. you can swim deeper water.', 10);
        this.status.put('toolGoggles', true);
        this.giveCard('goggles');

        this.multipliers.waterShallow += .2;
        this.multipliers.waterMid = this.multipliers.waterShallow;
    };

    onCollectSurfboard = () => {
        this.bus.addQuiet('you found a surfboard. you will move faster on water.', 10);
        this.status.put('toolSurfboard', true);
        this.giveCard('surfboard');

        this.multipliers.waterShallow += .2;
        this.multipliers.waterMid = this.multipliers.waterShallow;
        this.multipliers.waterDeep = this.multipliers.waterShallow;
    }

    setDirection() {
        if (!this._v || (this._v[0] === 0 && this._v[1] === 0))
            return;

        let angle = (
            Math.atan2(
                this._v[1],
                this._v[0]
            ) * 180 / Math.PI
        );

        if (angle < 0) {
            angle = 360 + angle;
        }

        this.direction = directions[
            Math.round(
                (angle / 360) * directions.length
            )
        ] || directions[0];
    }

    get current() {
        return this._v;
    }
    set current(velocity: Coord | undefined) {
        if (velocity) {
            const dist = distance(zeroZero, velocity);

            if (dist > this.max) {
                const angleDeg = angle(zeroZero, velocity);
                const angleRad = angleDeg / 180 * Math.PI;

                this._v = [
                    this.max * Math.cos(angleRad),
                    this.max * Math.sin(angleRad),
                ];
            } else {
                this._v = velocity;
            }
        } else {
            this._v = undefined;
        }

        this.setDirection();
    };

    private lastApply = 0;

    private getSpeed = (
        depth: number,
        isInTrees: boolean,
        isOnRoad: boolean,
    ) => {
        if (isOnRoad) {
            return 'road';
        }
        if (this.touchingMountain) {
            return 'mountains';
        }
        if (depth > 2) {
            return isInTrees ? 'trees' : 'normal';
        }
        if (depth > 1) {
            return 'waterShallow';
        }
        if (depth > 0) {
            return 'waterMid';
        }
        return 'waterDeep';
    };

    _lastAppliedSpeed: Speed = 'normal';
    apply = (map: MapApp, skin?: Skin) => {
        const now = Date.now();
        if (now - this.lastApply < APPLY_INTERVAL) {
            return;
        }
        this.lastApply = now;

        if (!this._v) {
            map.gps.apply(
                selectSlideMultiplier(this._lastAppliedSpeed, skin)
            );
            return;
        }

        const [vx, vy] = this._v;
        const velocityCoord: Coord = [
            map.gps.targetLoc[0] - vx,
            map.gps.targetLoc[1] - vy,
            // map.gps.location[0] - vx,
            // map.gps.location[1] - vy,
        ];
        const viewCenter = map.gps.viewCenter;
        const newCenter: Coord = [
            Math.round(velocityCoord[0] + viewCenter[0]),
            Math.round(velocityCoord[1] + viewCenter[1]),
        ];

        const level = map.levels.getLevelAt(newCenter);
        const speed = this.getSpeed(
            level.depth,
            map.isInTree(newCenter),
            map.isOnRoad(newCenter)
        );

        const skinMultiplier = skin && skinMultipliers[skin][speed];

        const multiplier = typeof skinMultiplier === 'number'
            ? skinMultiplier
            : this.multipliers[speed];
        const coord: Coord = [
            map.gps.targetLoc[0] - (vx * multiplier),
            map.gps.targetLoc[1] - (vy * multiplier),
        ];
//         console.log(`
// speed: ${speed}
// multiplier ${multiplier}
// vx: ${vx}, with multiplier: ${(vx * multiplier)}
// vy: ${vy}, with multiplier: ${(vy * multiplier)}
// ${JSON.stringify(coord)}
// ${JSON.stringify(velocityCoord)}
//         `);

        map.gps.setLocation(coord);
        this.touchingMountain = false;

        const { locationCenter } = map.gps;
        const locationSpeed = this.getSpeed(
            map.levels.getLevelAt(locationCenter).depth,
            map.isInTree(locationCenter),
            map.isOnRoad(locationCenter)
        );
        this._lastAppliedSpeed = locationSpeed;

        map.gps.apply(
            selectSlideMultiplier(locationSpeed, skin)
        );
    };

    toJSON(): VelocityStateSerialized {
        return {
            max: this.max,
            direction: this.direction,
            multipliers: this.multipliers,
            items: this.items.selectMine(this)?.map(item => item.toJSON()),
        }; 
    };
}
