import React, { FC } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react'
import { emojiStrings } from 'image';
import { Status } from 'game';
import { useMarbleChange, marbleChangeAnimMs } from './useMarbleChange';

const Wrap = styled.div`
    text-align: center;
    position: relative;
`;
const ToolTray = styled.div`
    display: inline-block;
    background-color: rgba(0, 0, 0, .6);
    border-radius: 5px 5px 0 0;
    letter-spacing: .2em;
    padding: .2em 0 0 .2em;
    position: relative;
    i {
        font-family: CustomEmoji;
        font-style: normal;
    }
`;
const SaveIndicator = styled.div`
    position: absolute;
    bottom: 100%;
    left: -.5em;
    right: -.5em;
    background-color: rgba(0, 0, 0, .4);
    border-radius: 5px;
    margin-bottom: .3em;
    padding: .25em .5em;
    font-size: 0.8em;
    text-shadow: 0 0 10px black;
`;

const marbleChangeAnim = keyframes`
    from {
        transform: translate3d(0, 0, 0);
        opacity: .8;
    }
    80% {
        opacity: .4;
    }
    to {
        transform: translate3d(0, -2rem, 0) scale(.9);
        opacity: 0;
    }
`;
const MarbleChange = styled.div`
    position: absolute;
    font-family: 'Elsie Swash Caps', cursive;
    font-weight: bold;
    right: 0;
    bottom: 100%;
    background-color: black;
    color: white;
    padding: .05rem .5rem;
    border-radius: 1rem;
    animation: ${marbleChangeAnim} ${marbleChangeAnimMs}ms ease;
`;

const emptyIcon = '⬛';
export const toolString = ({
    toolCandle,
    toolFlashlight,
    toolShoes,
    toolBoots,
    toolGoggles,
    toolSurfboard,
}: Status) => [
    toolFlashlight ? emojiStrings.flashlight
        : toolCandle ? emojiStrings.candle
        : emptyIcon,
    toolBoots ? emojiStrings.boots
        : toolShoes ? emojiStrings.shoes
        : emptyIcon,
    toolSurfboard ? emojiStrings.surf
        : toolGoggles ? emojiStrings.goggles
        : emptyIcon,
].join('');
export const marblesString = ({
    marblesRemaining,
}: Status) => emojiStrings.marble + marblesRemaining;

export const StatusBar: FC<{
    status: Status,
}> = ({ status }) => {
    const { marblesOwned } = status;
    const changes = useMarbleChange(marblesOwned);

    return (
        <Wrap>
            <ToolTray>
                {status.saving && (
                    <SaveIndicator>
                        <i>💾</i> Saving
                    </SaveIndicator>
                )}
                <i>{toolString(status)}  {emojiStrings.marble}</i>{marblesOwned}
                {changes.map(([id, change]) => (
                    <MarbleChange key={id}>
                        {change > 0 ? '+' : ''}{change}
                    </MarbleChange>
                ))}
            </ToolTray>
        </Wrap>
    );
};

