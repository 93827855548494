import { Coord, CoordCache } from 'coords';
import { NoiseMaker, Noise } from 'noise';
import { Color } from 'color';

export class Levels {
    noise: NoiseMaker;
    weight: number;
    levels: Level[];
    private cache: CoordCache<Level>;
    constructor(levels: Level[], noise?: NoiseMaker) {
        this.noise = noise || new Noise();
        this.weight = 0;
        this.levels = levels.map((level, i) => {
            level.depth = i;
            this.weight += level.weight;
            return level;
        });
        this.cache = new CoordCache<Level>();
    };

    getLevelAt = (coord: Coord): Level => {
        const cached = this.cache.get(coord);
        if (cached)
            return cached;

        let thisWeight = this.noise.at(coord) * this.weight;

        const level = this.levels.find(level => {
            thisWeight -= level.weight;

            return thisWeight <= 0;
        }) || this.levels[0];

        return this.cache.set(coord, level);
    };
};

export class Level {
    depth: number = 0;
    weight: number;
    color: Color;
    constructor(weight: number, color: Color) {
        this.weight = weight;
        this.color = color;
    }
    isAbove = (level: Level) => this.depth > level.depth;
    isBelow = (level: Level) => this.depth < level.depth;
};

