import { MapApp } from 'maps';
import { Color } from 'color';
import { Coord, distance } from 'coords';
import {
    GenerationProgress,
    ProgressGenerator,
} from 'generate';
import { GenderName, ImageDataBuilder, randomGender, randomSkinToneName, SkinToneName } from 'image';

type DefaultDataType = Record<string, string | number | boolean | void>;

export type ItemSerialized<T = DefaultDataType> = {
    loc: Coord;
    found: boolean;
    removed: boolean;
    data: T;
    config: {
        name: string;
        min: Coord;
        max: Coord;
        failureMinMax?: {
            min: Coord;
            max: Coord;
        };
        color: Color;
        minDist?: number;
        minMaxDistFrom?: Coord;
        maxDist?: number;
        minLevel?: number;
        maxLevel?: number;
        hidePointer?: boolean;
        conceal?: boolean;
        showOnFullRender?: boolean;
        showOnFullRenderAtHome?: boolean;
        showOverFog?: boolean;
        omitOnError?: boolean;
        collectRadius?: number;
        skinTone?: SkinToneName;
        gender?: GenderName;
        generatePerson?: boolean;
        minOtherItemDist?: number;
    };
};

export type ItemConfig<T = DefaultDataType> = ItemSerialized<T>["config"] & {
    onCollect: (map: MapApp, self: Item) => boolean | null | undefined | void;
    customRender?: (
        data: ImageDataBuilder,
        itemDataLocation: Coord,
        item: Item,
    ) => void;
};

type ItemFactory = (map: MapApp) => Item | Item[];

export class Item<T = DefaultDataType> {
    loc: Coord;
    found: boolean = false;
    removed: boolean = false;
    data: ItemSerialized<T>["data"];
    config: ItemConfig;
    private loaded: boolean = false;
    constructor(config: ItemConfig) {
        this.loc = [0, 0];
        this.config = config;
        this.data = {} as T;
    }
    toJSON(): ItemSerialized<T> {
        const {
            onCollect,
            customRender,
            ...configSerializable
        } = this.config;
        return {
            loc: this.loc,
            found: this.found,
            removed: this.removed,
            data: this.data,
            config: configSerializable,
        };
    }
    static load(
        serialized: ItemSerialized,
        onCollect: ItemConfig['onCollect'],
        customRender?: ItemConfig['customRender'],
    ) {
        const item = new Item({
            ...serialized.config,
            onCollect,
            customRender,
        });

        item.loc = serialized.loc;
        item.found = serialized.found;
        item.removed = serialized.removed;
        item.data = serialized.data;
        item.loaded = true;

        return item;
    }
    async * generate(map: MapApp, getItems: () => Item[]): ProgressGenerator {
        if (this.loaded) {
            return [1, 1];
        }

        await new Promise(r => setTimeout(r, 0));
        yield [0, 2];
        let done = false;
        let c: Coord;
        let tries: number = 0;
        let { min, max } = this.config;
        const {
            minLevel,
            maxLevel,
            minDist,
            minMaxDistFrom,
            maxDist,
            failureMinMax,
            minOtherItemDist,
        } = this.config;
        do {
            if (tries === 500 && failureMinMax) {
                ({ min, max } = failureMinMax);
                console.warn(`${this.config.name} failed to find spot within 500 iterations, falling back`);
            }
            if (tries > 1000) {
                throw new Error(`whats taking so long with ${this.config.name}?`)
            }
            tries++;

            c = [
                map.ints.next(min[0], max[0]),
                map.ints.next(min[1], max[1]),
            ];

            if (minLevel || maxLevel) {
                const level = map.levels.getLevelAt(c);
                if (minLevel && level.depth < minLevel) {
                    yield [1, 2];
                    continue;
                }
                if (maxLevel && level.depth > maxLevel) {
                    yield [1, 2];
                    continue;
                }
            }
            if (minDist || maxDist) {
                const dist = distance(
                    c,
                    minMaxDistFrom || map.gps.locationCenter
                );
                if (minDist && dist < minDist) {
                    yield [1, 2];
                    continue;
                }
                if (maxDist && dist > maxDist) {
                    yield [1, 2];
                    continue;
                }
            }
            const otherItems = getItems();
            let itemTooClose: boolean = false;
            for (let i = 0; i < otherItems.length; i++) {
                const otherItemMinDist = otherItems[i].config.minOtherItemDist || 1;
                const maxDist = Math.max(otherItemMinDist, minOtherItemDist || 20);
                if (
                    otherItems[i].loc
                    && typeof otherItems[i].loc[0] === 'number'
                    && typeof otherItems[i].loc[1] === 'number'
                    && distance(c, otherItems[i].loc) < maxDist
                ) {
                    itemTooClose = true;
                    break;
                };
            };
            if (itemTooClose) {
                yield [1, 2];
                continue;
            };

            done = true;
        } while (!done);
        this.loc = c;
        if (this.config.generatePerson) {
            this.config.skinTone = randomSkinToneName();
            this.config.gender = randomGender();
        }
        return [2, 2];
    };
};

type IdentityValue = {
    factories: ItemFactory[];
    items: Item[];
};

export class ItemState {
    items: Item[];
    identities: Map<object, IdentityValue>;

    constructor() {
        this.items = [];
        this.identities = new Map();
    }
    addFactory = (identity: any, factory: ItemFactory) => {
        if (!this.identities.has(identity)) {
            this.identities.set(identity, { factories: [], items: [] });
        }
        this.identities.get(identity)!.factories.push(factory);
    };
    selectMine(identity: any) {
        const items = this.identities.get(identity)?.items || [];
        return items?.filter(item => !item.removed);
    };
    remove = (itemToRemove: Item) => {
        this.items = this.items.filter(item => item !== itemToRemove);
    };
    async * generate(map: MapApp): ProgressGenerator {
        const identityKeys = Array.from(this.identities.keys()).sort((a, b) =>
            JSON.stringify(a, null, 2).length - JSON.stringify(b, null, 2).length
        );
        const factoriesTotal = Array.from(this.identities.values()).reduce(
            (acc, ident) => acc + ident.factories.length,
            0
        );
        let factoriesDone = 0;
        for (let identIter = 0; identIter < identityKeys.length; identIter++) {
            const identity = identityKeys[identIter];
            const value = this.identities.get(identity)!;
            for (let factoryIter = 0; factoryIter < value.factories.length; factoryIter++) {
                const result = value.factories[factoryIter](map);
                let items = Array.isArray(result)
                    ? result
                    : [result];

                const generators = items.map(item => item.generate(map, () => [
                    ...this.items,
                    ...items,
                ]));
                const generatorsDone = [];
                let allDone = false;
        
                while (!allDone) {
                    for (let i = 0; i < generators.length; i++) {
                        if (generatorsDone[i]) {
                            continue;
                        }
                        try {
                            const result = await generators[i].next();
                            if (result.done) {
                                generatorsDone[i] = true;
                            }
                        } catch (failure) {
                            if (!items[i].config.omitOnError) {
                                alert(`item error :: ${items[i].config.name}[${i}]`);
                                throw failure;
                            }
                            generatorsDone[i] = true;
                            console.warn(`marking ${items[i].config.name}[${i}] as failed`);
                            items[i].data.failed = true;
                        }
                    }
                    const doneCount = generatorsDone.reduce(
                        (acc, done) => acc + (done ? 1 : 0),
                        0
                    );
                    allDone = generators.length === doneCount;
                    await new Promise(r => setTimeout(r, 0));

                    yield [
                        factoriesDone,
                        factoriesTotal,
                    ];
                }

                items = items.filter((item, iter) => {
                    const keep = !item.data.failed;
                    if (!keep) {
                        console.warn(`removing ${items[iter].config.name}[${iter}]`);
                    }
                    return keep;
                });

                factoriesDone++;

                this.items = [
                    ...this.items,
                    ...items,
                ].sort((
                    {loc: locA, config: {name: nameA}},
                    {loc: locB, config: {name: nameB}}
                ) =>
                    nameA === 'mountain' && nameB !== 'mountain' ? -1
                    : nameA !== 'mountain' && nameB === 'mountain' ? 1
                    : nameA === 'mountain' && nameB === 'mountain' ? locA[1] - locB[1]
                    : 0
                );
                this.identities.get(identity)!.items = [
                    ...this.identities.get(identity)!.items,
                    ...items,
                ]
            };
        };
        return [1, 1] as GenerationProgress;
    }
};

