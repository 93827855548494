import { makeNoise2D } from 'open-simplex-noise';
import seedrandom from 'seedrandom';
import {
    Coord,
    coordToIndex,
    indexToCoord,
} from 'coords';
import { ProgressGenerator } from 'generate';

export interface NoiseMaker {
    at: (coord: Coord) => number;
}

export class RandomInts {
    private _: ReturnType<typeof seedrandom>;
    constructor(seed: number) {
        this._ = seedrandom(`fidget${seed}map`);
    };
    next = (min: number, max: number) => {
        const rawValue = this._();

        return Math.round(
            ((rawValue/1) * (max-min)) + min
        );
    };
}

export class Noise implements NoiseMaker {
    seed: number;
    generator: ReturnType<typeof makeNoise2D>;
    scale: number;
    constructor(scale: number = 20, seed?: number) {
        this.seed = seed || Date.now() + Math.random();
        this.generator = makeNoise2D(this.seed);
        this.scale = scale;
    }

    // noise is from -1 to 1.
    // add 1 makes it always positive from 0 to 2
    // divide by 2 makes it from 0 to 1
    private singleNoiseValue = ([x, y]: Coord, scale: number) => (
        this.generator(
            x / scale,
            y / scale
        ) + 1
    ) / 2;
    
    at = (coord: Coord) => (
        (this.singleNoiseValue(coord, this.scale * .5)*.1)
        + (this.singleNoiseValue(coord, this.scale * 4)*1)
        + (this.singleNoiseValue(coord, this.scale * 2)*.5)
    ) / (.1 + 1 + .5);
};

const pause = async () => new Promise(
    resolve => setTimeout(resolve, 0)
);

export class NoiseGrid implements NoiseMaker {
    width: number;
    height: number;
    data: Uint16Array;
    private divisor: number = 65535;
    private noise: Noise;
    constructor(
        width: number,
        height: number,
        scale?: number,
        seed?: number,
    ) {
        this.width = width;
        this.height = height;
        // values must be integers between 0 - 65535
        this.data = new Uint16Array(width*height);
        this.noise = new Noise(scale, seed);
    };

    get seed(): number {
        return this.noise.seed;
    }

    at = (coord: Coord) => this.data[
        coordToIndex(this.width, coord)
    ] / this.divisor;

    put = (coord: Coord, value: number) => {
        this.data[
            coordToIndex(this.width, coord)
        ] = Math.max(
            Math.min(
                Math.floor(value * this.divisor),
                this.divisor
            ),
            0
        );
    };

    async * generate(): ProgressGenerator {
        let i = 0;
        // const chunkSize = Math.floor(this.data.length / 34); // 3% bursts?
        const chunkSize = Math.floor(this.data.length / 17); // 6% bursts?
        for (; i < this.data.length; i++) {
            if (!(i%chunkSize)) {
                await pause();
                yield [i, this.data.length];
            }
            this.data[i] = Math.floor(
                this.noise.at(
                    indexToCoord(this.width, i)
                ) * this.divisor
            );
        }
        return [i, this.data.length];
    }
}


