import { CardTheme, WeightedUrl } from "./types";
import { cardImageUrls, themeWeights } from "./data";
import { randomInt } from "generate";

export const RARITY = {
    common: 100,
    rare: 70,
    epic: 40,
};

export type RarityNames = keyof typeof RARITY;

export const raritySymbols: Record<RarityNames, string> = {
    common: '',
    rare: '◬',
    epic: '⟁',
}

const rarityLabels: Record<RarityNames, string> = {
    common: 'Common',
    rare: 'Rare',
    epic: 'Epic',
}

export const getRarityName = (theme: CardTheme, index: number): RarityNames => {
    const weight = cardImageUrls[theme][index].weight;
    if (weight <= RARITY.epic) {
        return 'epic';
    }
    if (weight <= RARITY.rare) {
        return 'rare';
    }
    return 'common';
}

export const getRaritySymbol = (theme: CardTheme, index: number): string => {
    const rarity = getRarityName(theme, index);

    return raritySymbols[rarity];
};

export const getRarityLabel = (theme: CardTheme, index: number): string => {
    const rarity = getRarityName(theme, index);

    return rarityLabels[rarity];
};

export const selectCardWeight = (
    theme: CardTheme,
    index: number,
) => cardImageUrls[theme][index]?.weight;

// const reduceFraction = (
//     numerator: number,
//     denominator: number
// ) => {
//     let a = numerator;
//     let b = denominator;
//     let c;
//     while (b) {
//         c = a % b;
//         a = b;
//         b = c;
//     }
//     return [numerator / a, denominator / a];
// };

export const cardRarity = (
    theme: CardTheme,
    index: number,
): string => {
    const cardWeight = selectCardWeight(theme, index);
    const themeWeight = themeWeights[theme];

    // const [n, d] = reduceFraction(
    //     cardWeight,
    //     themeWeight
    // );

    // return `${n}/${d}`;
    // return ((n/d)*100).toFixed(1) + '%';
    return ((cardWeight/themeWeight)*100).toFixed(1) + '%';
};

export const weightedRandomCard = (theme: CardTheme): number => {
    // // inspired by https://medium.com/@peterkellyonline/weighted-random-selection-3ff222917eb6
    const images: WeightedUrl[] = cardImageUrls[theme];
    let randomWeight = randomInt(1, themeWeights[theme]);

    for (let i = 0; i < images.length; i++) {
        randomWeight -= images[i].weight;
        if (randomWeight <= 0)
            return i;
    }
    throw new Error('Problem in your randomness function?');
};

