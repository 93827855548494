import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { cardImageUrls } from 'cards';
import { FullHeight } from './FullHeight';

const backgroundImages = [
    ...cardImageUrls.meadows.map(weightedUrl => weightedUrl.url),
    ...cardImageUrls.beachWoods.map(weightedUrl => weightedUrl.url),
];
const randomBackground = () => backgroundImages[Math.floor((Math.random()*backgroundImages.length))];


const StyledImage = styled(FullHeight)<{ url: string; }>`
    position: fixed;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
    opacity: 0.2;

    background-image: url(${props => props.url});
    background-size: cover;
    background-repeat: no-repeat;
`;

export const BackgroundImage: FC = () => {
    const [url, setUrl] = useState<string>(randomBackground);

    useEffect(() => {
        const interval = setInterval(() => {
            setUrl(randomBackground());
        }, 10000);
        return () => clearInterval(interval);
    }, []);

    return <StyledImage url={url} />;
};
