import React, { FC } from 'react';
import styled from '@emotion/styled';
import { cardImageUrls, CardTheme } from 'cards';

const Img = styled.img`
    position: fixed;
    top: -9999px;
    left: -9999px;
    height: 1px;
    width: 1px;
    opacity: 0.1;
`;

const themes = Object.keys(cardImageUrls);

export const PreloadImages: FC = () => {
    return <>
        {themes.map(theme =>
            cardImageUrls[theme as CardTheme].map(weightedUrl =>
                <Img src={weightedUrl.url} key={weightedUrl.url} />
            )
        )}
    </>;
}
