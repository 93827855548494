import {
    CardTheme,
    WeightedUrl,
} from './types';
import { themeToThemeName } from './mappings';
import { RARITY } from './rarity';

const makeGoogleImageUrl = (googleImageId: string) => `https://drive.google.com/uc?export=view&id=${googleImageId}`;

const expandWeightedUrl = (
    [weight, id]: [number, string]
) => ({
    weight,
    id,
    url: makeGoogleImageUrl(id)
});

export const themes: CardTheme[] = Object.keys(themeToThemeName) as CardTheme[];

export const cardImageUrls: Record<CardTheme, WeightedUrl[]> = {
    meadows: ([
        [RARITY.common, '19luZaSFvoPVvfk88nro53Xia5CWgu3QC'],
        [RARITY.common, '1LuCqqNVB-n8v8x6fL1Qum4WB3In_7FtF'],
        [RARITY.common, '1En6Jv7Q5BFufGEdbtXH0H1OU2xnVoJYB'],
        [RARITY.common, '157fe98YX4wo1Nzh5dnElzUMxoKbbn0-W'],
        [RARITY.common, '1XLSKlIyays7NveOUDo3OaaBrxGcLAmxE'],
        [RARITY.common, '1ZqamJ1L_VZGXsNx1G80XTnvUs96THG9s'],
        [RARITY.common, '1zNzsSwpQjAiAfU69r96fET4G6czVCfOI'],
        [RARITY.common, '1k_J7s3POzA2pGJl-0PEuJLmiwvc-1O8-'],
        [RARITY.common, '1_wouMcKYBTPIFNzv2qDv3qtLJ5jUY_ls'],
        [RARITY.rare, '15ZAtBUXd8cn3GeT_sy_1xSqbdJc7-t2-'],
        [RARITY.rare, '180Z_uIu4QtnpbqYCCBjz6cKtqsoL9eEL'],
        [RARITY.rare, '1BXi_5HA8HtPRDhKVzfid-Kmpux06nBcp'],
        [RARITY.rare, '1JumdxpI7iFYrCGgDIk0CmDoOsJSwlNmZ'],
        [RARITY.rare, '1JyLAgmX8lv10LTqttLVAk5W3nJAilugf'],
        [RARITY.rare, '14azQj54c6X633B196hHRbh2ZuCdssDyy'],
        [RARITY.rare, '1LVnZmTyBO6aBnfHNA5jbRDi7QdB5bABF'],
        [RARITY.epic, '1lTAijjAeVyICiBs9RkothP24CDBY1Hjg'],
        [RARITY.epic, '1rBbanWtQl27R0ij7BBvCWz_HBl9wtinw'],
    ] as [number, string][]).map(expandWeightedUrl),
    beachWoods: ([
        [RARITY.common, '1hgJYfw_jifVwV0ceV9gBi8psVtjBlzFE'],
        [RARITY.common, '1apaKzN65-62TOsJF470-8VOYZSMBXZio'],
        [RARITY.common, '1ZCmgowYUz5-gx4lWe73KAZLoJNDGrVTb'],
        [RARITY.common, '1YxTFEo-96fWvcBwc5kgm_VR_8Jf5c1HT'],
        [RARITY.common, '1gfmiIWpqHOOZVc-ne4R6SPeC5agv3wmD'],
        [RARITY.common, '1-9DJz9Uickn6ba6EsaBwG-ZYOCiQUmJo'],
        [RARITY.common, '1FC6W7M9ZHWwWtd8z_QnI5tdBkcxK0jbm'],
        [RARITY.common, '1NGWEseCqMPMv2uH3fOIBIrY2jbH_u-R7'],
        [RARITY.common, '1Zch1hnXUihWevN7yLlppwWN3C-duxnTy'],
        [RARITY.rare, '1axD4vb4LftB7ONG2Ak9rgxYJSP33wuoD'],
        [RARITY.rare, '1njz6rFIPltp3SkC0eFfmM2hExS5xUx9j'],
        [RARITY.rare, '1qAqra7fkkTQXI7F0OSl_StxS-4WKYx6Y'],
        [RARITY.rare, '1qDOR-CgODhkIeirp6SXz3XqAaXxiNX_U'],
        [RARITY.rare, '1qMgbU5DNAeNJeX-eMDoi3PJVUjWSCPLH'],
        [RARITY.rare, '1rgAXPCqyvgUMzpxPVoCITP2EEuBddRcs'],
        [RARITY.rare, '1dWw-9FbWDnGGYYyEsEUZoNlNd2XnULWl'],
        [RARITY.epic, '1ENVSXncfWrbpLzhFIsGHIj7lmRWc8byJ'],
        [RARITY.epic, '1u5NZL3cN1QyumkRLl5PcDMQoABhrbCii'],
    ] as [number, string][]).map(expandWeightedUrl),
    beachPath: ([
        [RARITY.common, '1cCIBLcWq5__XK-3sV7YOtMZylt0oW03I'],
        [RARITY.common, '1cEDjg-EfIj4X1YJnzNWAJ_EG-G0-Ofq_'],
        [RARITY.common, '1cEG8cQnDsQM9HLWJcXEeTufdevmZ6TSJ'],
        [RARITY.common, '1cELZDb_oc2MOPWDZIz2RF2dhsLtWaAdr'],
        [RARITY.common, '1cEeeVG-8vjMz6N8FFlwlSrs7yozzGIVa'],
        [RARITY.common, '1cGJpHa43dZOHkN1Kv2DuhyH3sBlY8IFu'],
        [RARITY.common, '1cHY0F5zdiktAuAM6yYskq7UwhRUanNRR'],
        [RARITY.common, '1cIlKaMLLl_cjHA-1nNKXcDVQ3bWZWA3H'],
        [RARITY.common, '1cJlRmdl9vzeFQnyFSyocxTGMPLEYTQL-'],
        [RARITY.rare, '1cMY_MJUHZ2jP9WZPs44oretgstLsvjsB'],
        [RARITY.rare, '1cN92qMP7nuwKSdWdQpo8fbklNaijosm7'],
        [RARITY.rare, '1cPkGyonDJBbn8McxJ8ED6AHCZDa5v9G_'],
        [RARITY.rare, '1cTv2oMQcriPPz0B9X6FLLCFDMH0-WNtq'],
        [RARITY.rare, '1cVOCeM5LdilwptYyOUo0KJM47Pg542vy'],
        [RARITY.rare, '1cbq1bK5dWKAtT5LU37P8Kk__hGembgJk'],
        [RARITY.rare, '1ciC-f7M_7C5w2Qd1QrXaTm6eY7xae7w4'],
        [RARITY.rare, '1ckomY9IT7KWsRBMg6cZBXhH5KutNII77'],
        [RARITY.epic, '1cnIkENsclQ0g6MEwAifvlyWeyyTfEHnt'],
    ] as [number, string][]).map(expandWeightedUrl),
    boots: ([
        // [RARITY.common, '15yAEc5TW-FBXO3OBzNduLVur4JqqyJ30'],
        // [RARITY.common, '18nw-uo4vgBE1aXmc7_wynARo8_BmiPN7'],
        // [RARITY.common, '1KwTnQIvCbgSZ_z9gbaJRgDRUTPR_TM5_'],
        // [RARITY.common, '1j6U36FN1Nyx7KxkVz1ULzYf-n8er3ls9'],
        // [RARITY.common, '11FEK_Hm7jVW_ixd5lfnS3ONAwYEJagOq'],
        // [RARITY.common, '16rTHRdZsMm2D98JcJtBf2KAYi910F8J4'],
        // [RARITY.common, '18TdK5K4iL4W1CMeqGCVcD2nzZ3iOaV0_'],
        // [RARITY.common, '1YKLnfATvFNfwECFXJZNBvaw3yUcUcnIO'],
        // [RARITY.common, '1EVZj8wP_TM6Ni4upjuggHjJ251uyT7TI'],
        [RARITY.common, '18mCB0JolsTnshoQZjOEocMR-MAXj1hCW'],
        [RARITY.common, '1EpsSi8kSBAeXijpjUVeSH4oW6uajgMb8'],
        [RARITY.common, '1I6Pqk7YU2fbHhF-hjg2lDZMdyeLwX5RD'],
        [RARITY.common, '1Kgd-gDhMQA_7a1tEgPNiavcZWeXpN0Jo'],
        [RARITY.common, '1KuJLSeMJu4mtgcQaAwk_ztv-tRL7B08p'],
        [RARITY.common, '1RptfFZjzrVFQXNvAer6xiNmVfIP7V7jE'],
        [RARITY.common, '1pvTqteIUo4KuoGvlooaQWIt1WUpVsa5Q'],
        [RARITY.common, '1xx1L3Lf9V1BIrjzvnLkNrEm6W7fzU3bB'],
        [RARITY.rare, '1FkP3cWyhAOjzLJ7x-6ILtP41gn3Pl23G'],
        [RARITY.rare, '1S4L3JiVo-SmkM0_oRqM1LFEKnMflaLXt'],
        [RARITY.rare, '1WksF044hxj6bU34mN8X6cMJnMZzJ9WST'],
        [RARITY.rare, '1cjQ6WROJfnWc7o0kwF96226WvRobRr4e'],
        [RARITY.rare, '1dBzsFsuAY0g-9K7LaVHxOkZc0Sfa732o'],
        [RARITY.rare, '1tQRNxBBMHctYjjBb-p4wGRlsXEmcUIqf'],
        [RARITY.epic, '19JILqxL7MEcaFR6MJN3asBmsJ7AY62jO'],
    ] as [number, string][]).map(expandWeightedUrl),
    cabins: ([
        [RARITY.common, '1-4e0mu-7VtGXjSobCJFGT6un86ae-FiO'],
        [RARITY.common, '1-DuQkr9bP46eokpTX6liwrkjbcIGIcgS'],
        [RARITY.common, '1-c_HeRRgNb6QDSGUTA9f-oIgJ30Dx05R'],
        [RARITY.common, '11FTf4xP9nDlVkQmo_c3zW_mgNJGcMELR'],
        [RARITY.common, '14rc0p5PF84ayL1RtcWto3iPtKdLb6Evr'],
        [RARITY.common, '15KRBHV5jJ8dLf0ejQL5kl9F_WuzrBzVo'],
        [RARITY.common, '17pP5TRFcfqeCCiPVcjy9vYnxjelZXiJO'],
        [RARITY.common, '18HjOwKeV2dsA3wPpoFBHn-w57NgF6OM7'],
        [RARITY.common, '18Y2MwG48V9ZjpbWoGxYSlmlP6q4xRYIZ'],
        [RARITY.common, '1Bh8rCRiXDLgYCdOh9mxLonrkpsoeTpvP'],
        [RARITY.common, '1CEZOZ5j5uEN9DFQAWXGIri5xSWqKGsKV'],
        [RARITY.common, '1FAXLCjtr0ImjfvpEZQNbXEWLSN-RfRjQ'],
        [RARITY.common, '1GJjVrzRXmhSWeK1e9wHasOnwohuRxOwg'],
        [RARITY.common, '1Hu4SGqP7j__z2OS50hjEiv3KMwYY1Cso'],
        [RARITY.common, '1JE5W4vsvs2-M76qEbio0UaaqG0nAZKAO'],
        [RARITY.common, '1LmktGUFut7XpPYPrCTMthsYORvt6gHfN'],
        [RARITY.common, '1MnMxqnCaEgSzdiWnFA4_wS_BAHg7vcwg'],
        [RARITY.common, '1NLxmcrC2CHfwqgtXarBquiqclqYUzqMa'],
        [RARITY.common, '1QZ-7dwo5a6jq21LNEdqh1pNGI7tk8O8Z'],
        [RARITY.common, '1Z7wTBFD3IzgPkulxRIsJQoAG230EUFqQ'],
        [RARITY.common, '1_14k869oEW4sYr6c5_B6NkLavDLeW1eZ'],
        [RARITY.common, '1czMrFMZjjdAu6L_iqoe8Yj-IoChlRwRP'],
        [RARITY.common, '1d-oZ2SQ5Zq3g1inVh6ATAna9wqQoChxp'],
        [RARITY.common, '1d3d8IqAO6YsqIgTPbTVj9dic5urnaNZr'],
        [RARITY.common, '1d3uI3TwRB28q0qKKwLx6cphlvIJVTlTT'],
        [RARITY.common, '1d3yf5eCcrE-5iD5Y6PlczXDwUq4uEmqA'],
        [RARITY.common, '1d9avAlRHSFr9vD2dEWoomCmSU0uyH3r1'],
        [RARITY.common, '1d9jeXMo0zFQDnWnjRbXdTEo-I_x0Gd8x'],
        [RARITY.common, '1d9tAANe5vqP5_PL8zvJOj_5avAZjQgJp'],
        [RARITY.common, '1d_agkD4ksbct-jZidOsRZKB-BxrlvcJH'],
        [RARITY.common, '1hiw21e3Dt2_I3H8S9dHAMDt4gG34ihAy'],
        [RARITY.common, '1j8hHTQifIjADGIyFjNndUeqNrDQ-GdH5'],
        [RARITY.common, '1oM_mAviT1Pg8N4nczk_TkJViCL3MTiNv'],
        [RARITY.common, '1q8TQAFVOzs_jjfWxRK8xQhcjwlvJM3T7'],
        [RARITY.common, '1qTJmYbVYl7AEFe92AKrvdaL5y5tESX2J'],
        [RARITY.common, '1r1lnec0oECdZJ8Htxl8xFsPi97PTCwMG'],
        [RARITY.common, '1ukYEwCda6Yn_tOgeWo4uA25aHW3xfAN8'],
        [RARITY.common, '1wq3K_3p4EUOTy4m2VIzq9JH9-YedXMpJ'],
        [RARITY.common, '1xCdCLepyk87uTwaZmZpaAlScj5Pdrndb'],
        [RARITY.common, '1xcbPbw4bIzwkRT3in2RFn2nNpgZeK39c'],
        [RARITY.common, '1yha_IFwkSmcnQXKkawHG1_2J-Gi3ulAO'],
        [RARITY.common, '1z8rTo9SobihEuEjCXBioptDUh6V9tmrw'],
        [RARITY.common, '1zNMMNbGIvdrI70TvGsj7ZhEKA0OGtJ4n'],
        [RARITY.rare, '15gzkLGYi6woaCJZBsoxvxC9X00Hvo9n0'],
        [RARITY.rare, '1_itO25-UUVIG7No_GPbMfGAv5WxAWlgK'],
        [RARITY.rare, '1D-rd2u1cyhIsThKBl-YYJDj82VGtVI6n'],
        [RARITY.rare, '1FWS3Yepi-SFYfZYtmzcI4Y0FdJ_D2dEe'],
        [RARITY.rare, '1J-V03Jk0wr12-7fl5kyhf05J5ezh31yN'],
        [RARITY.rare, '1Ntsle-Afb78NrSURH0k4cohSqmg6ZBtN'],
        [RARITY.rare, '1Pjlkk-XWV4E_vUUSg6kKCQ8AtO1nN5d6'],
        [RARITY.rare, '1XdtbXUDPjQh1-OvKKax1y3qCUo1W1Mem'],
        [RARITY.rare, '1ctfDJRPzrSnpbh9f2Qwjf3j5u_Q4agFU'],
        [RARITY.rare, '1cwyGdNjo6BoHKKLjyuIvfaeoS-2sTTZk'],
        [RARITY.rare, '1d0Fxgw2eiHlnSLWbiPGDx1V-_-0ZMaOV'],
        [RARITY.rare, '1d0rwT_dPT98nl8dxAfQ80cgbb1l9uwtW'],
        [RARITY.rare, '1dA7M4N9p1MhOoUaOzFC1Yt1JdGWZo6CZ'],
        [RARITY.rare, '1dBXWdiSYZpAUEe9gtU45Jlu6rx3iWjbv'],
        [RARITY.rare, '1dELsZ6l_Xmm5yJibecAEPVcLCAfT8_pw'],
        [RARITY.rare, '1dEQ3r2KEVgGI-LKakG_c_Z3L6SCpgVrV'],
        [RARITY.rare, '1dK6UD-tqqi5Jrlv72fsDb7DcJkNsvBhb'],
        [RARITY.rare, '1dP9qjQ5CJXKXpapsOCZA3Kpk7Xbprmgr'],
        [RARITY.rare, '1dReyRM4GXJoLwZlY4UXCNLxgTuqq2lJ7'],
        [RARITY.rare, '1dUaDI47DHtkndq9JeOIXRX5AzX5o_j2d'],
        [RARITY.rare, '1dXY6TH48M5eZTYyfp8HjUMDJXpGDK8WR'],
        [RARITY.rare, '1dXYuf004we-WTZf_4eZJb8xZQmQoU5jN'],
        [RARITY.rare, '1dYz7V345fpQNWB1Cbi1FTFsOJklq-joM'],
        [RARITY.rare, '1dcoxsuCkt5ulEgiR6Lq8qB-JXEjy_7sH'],
        [RARITY.rare, '1deGPSXSRx4zQFd_bCWZBO9GuWkbisdTk'],
        [RARITY.rare, '1dg9tucXx8LtKS5gzm0HLmVTxlKbY7yTn'],
        [RARITY.epic, '1Uq9sbA7rERNQqrJpFdOWlsI1_KJ2hJu8'],
        [RARITY.epic, '1bK2br9bA20OcwS5o1f6UlJhIFlOrpbKq'],
        [RARITY.epic, '1cnay6N_R5N3OoephDuFNk6471kR0s7Y6'],
        [RARITY.epic, '1cnc5p0XIyXyNq_9WS_DEUtGjHxGiTZYB'],
        [RARITY.epic, '1ct6Igb3B6smtEG_zuAI1xgjw36vA5uNM'],
        [RARITY.epic, '1d2FZHA9r0G3vHP5v-u1cX_c8WY52G1si'],
        [RARITY.epic, '1d7a9LLTYlmMzUI-mqVwPvhjN1J3ZQ1O8'],
        [RARITY.epic, '1dFTqVS_jKgu9vmYwlJjVzBWb7LYgovVA'],
        [RARITY.epic, '1dNk7IRA7EfFdi9kjHBTNLG0aDXJHVNS5'],
    ] as [number, string][]).map(expandWeightedUrl),
    candle: ([
        // [RARITY.common, '11cGycztlHHCd01RoTXT9y2hzYXYTFm8U'],
        // [RARITY.common, '18xNit5TDFrzbzgsqi7BBm7Aom5k34vQr'],
        // [RARITY.common, '1EkZaW5r-Dc_pTUhgsXq7K7amBD9ebGDo'],
        // [RARITY.common, '1XzR_UAeayndYyOGV67fR2tk-22y5OxVG'],
        // [RARITY.common, '1qJqIZ6ZVS_2Lytc9k19bereKtNAa5dRr'],
        // [RARITY.common, '115kwktCmUWn4A5CbEAQseiiVDcCIfOXe'],
        // [RARITY.common, '1WwvvL2b3NwnV-YwIQfmILD7LQL1yDnIA'],
        // [RARITY.common, '1YYeQnb68YoPukIcRm-Nc-ItZgtY2g_PZ'],
        // [RARITY.common, '1q8zwIifY1hz-D461F4p80hxxC7Cbwnxr'],
        [RARITY.common, '175cqm0OCzTFrwLfxJc9OYT81vY0GrKWJ'],
        [RARITY.common, '1DLPbbDHLa1cEja47NFheX1a_EyPQXS_P'],
        [RARITY.common, '1KutU1h5GYxr4fnzhwY8zF_WclPInPWLo'],
        [RARITY.common, '1Mb_v0TyKRAKi0EzQsmeDUWizQPeeAUTg'],
        [RARITY.common, '1Rv2UP2ccWw6BlJkN-xSmi52qnpwgEGtl'],
        [RARITY.common, '1aiVmjye08v8FBiwG6o3Q1CnACItIgrNJ'],
        [RARITY.common, '1dwhep5X4BUeJ_mP5M5Q45Gvh6uScEthT'],
        [RARITY.common, '1g-nt4JWtmzP7O8w2rwr389ti7iFiJ2eh'],
        [RARITY.common, '1i2goqlkEtMnj99wWjwqEw8rCraPa8bMG'],
        [RARITY.common, '1llb0Abfq8UKcrEB9SCI0ZqXE-vEKPSnx'],
        [RARITY.common, '1wUZ-scIbSMuk9-Npnx56aKAkWU4TZQnE'],
        [RARITY.common, '1xvSXrfaalQ57ZQFseFBrYiZNag5UK_jl'],
        [RARITY.common, '1yv7Xi6_Hn3CLYcU7cdgxVkibXGbPY85Z'],
        [RARITY.common, '1iEmUbaiu5u5UVPI4RzCPKwruSX39EO64'],
        [RARITY.rare, '1eD9wH8we-e2sUgylBR-R9ibJkynRFbvj'],
        [RARITY.rare, '18T-1Xz5EDCxJjDuvBTlJp4ytbzxh0iCZ'],
        [RARITY.rare, '1L8ftnnniu10qKh9XfaavIsuL5CR4X6iQ'],
        [RARITY.rare, '1PzUHbevstSzJ_QNdgwbIk3dWCNTLCrlA'],
        [RARITY.rare, '16MRsAbW5Lb0q3llwT5ueaIsGjzArpQ9A'],
        [RARITY.rare, '1__jHeMdFOTJKzhpQVgT-7GM7jvjQD0FN'],
        [RARITY.rare, '1Aba69WWEKZh2GmXqqs4QBAcygKiS9UXX'],
        [RARITY.rare, '1GCDc744LQwDRe64TsqelHzWHO761CvXg'],
        [RARITY.rare, '1ZJtqjZARzfW-IACb2r1BWZVjCp987DSv'],
        [RARITY.rare, '1syVca_aWuBONormAnLd_x91h0mnwaxe4'],
        [RARITY.rare, '1GhuqTevfOMruY4hPVJtZW2ezqSCh849g'],
        [RARITY.epic, '17iCoRRkIxTsTlgOLdrSjADVv29-KOiv3'],
        [RARITY.epic, '1BwghmYQeoXH8l7dLn8NGqIhD7ZP9_aDb'],
    ] as [number, string][]).map(expandWeightedUrl),
    castle: ([
        [RARITY.common, '1ZJ2EnK7xydvFm8vb063SQDems7YE4u5g'],
        [RARITY.common, '1ZOCHD8gzgMLKR7CjU_Ebk34M2cbZQ_1n'],
        [RARITY.common, '1Zn2m3zcEcZM3LNdeCWbLCiYS4yaygnuQ'],
        [RARITY.common, '1ZtSB3b96nT0Y4LLVBRY3cA-Q0Dw24ibI'],
        [RARITY.common, '1ZtY3kBerqTyEyspF8nNaEBB1b93cvy72'],
        [RARITY.common, '1_0Mt-w6MLz-9vMEDcnlcys4cjI5tnOHt'],
        [RARITY.common, '1_5wPidl32pnXsPkPRRng4dmB5SfqqYWs'],
        [RARITY.common, '1_61i7um1BbIO60_jL_s9wIghJi8ODuRC'],
        [RARITY.common, '1_7tcUOm_iR3AZJBGhot2_siv4TDvmBqf'],
        [RARITY.common, '1_8eJFhw6qkKXye4N87rbEYf8JVq2eYID'],
        [RARITY.common, '1_DeTuuUH9tjf_xb50i0KqzS6fFsA7exN'],
        [RARITY.common, '1_INeqtv47pDKr1KNoQ0F33kSrof1FVHG'],
        [RARITY.common, '1_LqZ6Y7sjC3EG4ZZXOH--pdB1mbTn7_D'],
        [RARITY.common, '1_OW6fTPQ6IGnYvwdp6GY9k2nJHNN3s-v'],
        [RARITY.common, '1_PCZg0On-4uUyOyAWVjzzxvO4PD0b9c1'],
        [RARITY.rare, '1Zp5sktLOIUpJzpAR8ifRt1DwhpFS1Ufc'],
        [RARITY.rare, '1ZhxhZj_mJrYcdlbi8V6NcwXs-UzNiCrT'],
        [RARITY.rare, '1Zgcgy_lbyvwovRgf7tkYzv5M74gLynXl'],
        [RARITY.rare, '1Zd1WLxT9N0xYHbmxCuoOuvZE7UOAkUri'],
        [RARITY.rare, '1ZWtUzu4_sio0xpEfoDQYav_aVrMr63Y7'],
        [RARITY.rare, '1ZOehWjuBuPJMXkLeNXsIMwN1zWvX8UWU'],
        [RARITY.rare, '1ZIA7fYaEl3BE_39uklcV9r1FDK8PtcST'],
        [RARITY.rare, '1ZGUdbc4gxgI6-gJ5AVFnIqakXlsmmZQQ'],
        [RARITY.rare, '1ZXZc80GKcxTrX-SMjW7nek9vbyQSHVqe'],
        [RARITY.rare, '1ZbgosX8gwKKuskobbMv8jed_WL5YhVfE'],
        [RARITY.epic, '1ZLnG_ui3RXGea9kC4sO9PuBauaL53U-B'],
        [RARITY.epic, '1ZsZJUZM_9qQYRoXhs8HwR0ChYKl0-tUl'],
    ] as [number, string][]).map(expandWeightedUrl),
    flashlight: ([
        // [RARITY.common, '13Hn66zEqmiKC3qDmZrmyIiSPmB-AYY6Z'],
        // [RARITY.common, '18MijUysjYZjykvC1hhl8qjoT45y1YFMn'],
        // [RARITY.common, '18bGx7nCUPx4jOptDgCGmOe7dnqU6R5G2'],
        // [RARITY.common, '198gfbPSi31kzniOyWHnGCsruwtN5Lpzs'],
        // [RARITY.common, '1ObhDAHFnB30R83pWCkPhUGJqSpQVpYU7'],
        // [RARITY.common, '1QGZV6psyV5q5pClIeHGixDxHAC55YQw3'],
        // [RARITY.common, '1asaRqt3pBbPbumTZ9l4bg-lHuWA4_54I'],
        // [RARITY.common, '1hJw1hhJ_Y5j_65oTz_iHBeMs6p666yMd'],
        // [RARITY.common, '1raLc8uD8i7GYr0V7NJbnC6hBGssSeY7K'],
        // [RARITY.common, '13ATCMAOWRaBmmHaoZztmcABrN4gAU1yF'],
        // [RARITY.common, '1PpAXtk65jwPD_WqMXqvzLGvhwi0FSvY1'],
        // [RARITY.common, '1crQl2x70xilZH94fkl8fMToo6TEB7yoR'],
        [RARITY.common, '1-OdG45Qyra4PMGoX2Y27mikcSCiQcHrI'],
        [RARITY.common, '16V_dt8A1YwWouPmQ1DroPE1hjXX9o-eV'],
        [RARITY.common, '18NG-PIZoo0N8R3SFT3uJb4hBWPMP0znd'],
        [RARITY.common, '1FVmfF-76rw7NiXjvEeXzXdd2-THXz9Ib'],
        [RARITY.common, '1GbOy7yf8HwnOcYdmmRY19rKbBRp_Jx_e'],
        [RARITY.common, '1GqTskUcRchlbgXy3gVVs3hKFKgdgg96-'],
        [RARITY.common, '1Mrp4NLsxbm_ZtNImWf2XcFn5TL1zZGI7'],
        [RARITY.common, '1cRuo5WP7TQ0rDbVc_1MGlB3HINA-kqJ-'],
        [RARITY.common, '1gbaVf66uZZrTTur4bVi6IniAA8cxZrwW'],
        [RARITY.common, '1gdXduRLJcDQJSLh6aEu9WsgMu09DZOKx'],
        [RARITY.common, '1is3560Z3sKg9_4ZT03XYfP5CYjiAp8S_'],
        [RARITY.common, '1iz0-IqBJl0iT59vl55oeXEg0YYHb0eZA'],
        [RARITY.common, '1t_Nqo_Qt6j1Me-SCZLOzWa1nkOxG44KT'],
        [RARITY.common, '1v6JVB75gDR5NeocPj5A9sWSlSM_ZKMux'],
        [RARITY.common, '1wpXz6DkSLu5vLBFrNzJPspZkRxmToFk_'],
        [RARITY.common, '1z9_JbPReNn01FTMM5XSx--pr4uBNvEWb'],
        [RARITY.rare, '1ZxgDnOLrlkIm8iwbmvTgA6_ljPxmx4bL'],
        [RARITY.rare, '1TTRiDFIiFVwBv4KTTWb4Pjo4Nr9A7Al8'],
        [RARITY.rare, '14a_z3KUxqEXuaoa7Q2liylFag_3x2ZU0'],
        [RARITY.rare, '1OJ5DnXM4kLGDjcL2DI7YhX9WOAT1Rz6J'],
        [RARITY.rare, '1Dltm7Zbw7OwIOCHHyEn5USnWcoUt-AGv'],
        [RARITY.rare, '13FeK5cXf2-j7kIpaTv7BOuqVxcgDzhGK'],
        [RARITY.rare, '1BhNiz9Xmf7TkjtHRYR_wrijrJzzKyNuY'],
        [RARITY.rare, '1Wz0PzCz5HBS20-yNIdSHimmJsOwPEyap'],
        [RARITY.rare, '1rVbDBDe2pc3ShHKVS9BcbA4-QBuNnsbx'],
        [RARITY.rare, '1_pu2NLiCZi0FPAqG1HvGwyJC85HjNlYu'],
        [RARITY.rare, '1kNPKFSLDCSU3THn7hD5Ha97FYqBmnzx1'],
        [RARITY.rare, '1p7tAkOPP3gnb2JCMIjcSrElDLcTrBHnt'],
        [RARITY.rare, '1w6ZfuUvdH2LABbWaVeldgdrcH8wo3Jqj'],
        [RARITY.epic, '1jIcEJOztUA7HmAwdmGBZnggmLzIRhoJE'],
        [RARITY.epic, '1gFjsNN1NnWG61he7OHc55So4O0t1yGxb'],
    ] as [number, string][]).map(expandWeightedUrl),
    goggles: ([
        [RARITY.common, '1aoNfh0EiYn8-ebMXKYw-LPvhf7hbv9RQ'],
        [RARITY.common, '1arfzvT54sNgReoTkab8SpZJeYFnRZrdC'],
        [RARITY.common, '1aruPx-fNlnXqJYwPYnk7ctGCkJ6txWj0'],
        [RARITY.common, '1auzk57bct1BaOWcyuUEGknqA0fnwTnwC'],
        [RARITY.common, '1avrM6Bw5pQ8j9g-mf5fOBopbbSM8imWC'],
        [RARITY.common, '1dgg5nAuhk7IJc99zcbioQvAMNv5TNZAP'],
        [RARITY.common, '1djoeaF9q_2B7FB9ByJ0TLsawvOI9WMN1'],
        [RARITY.common, '1dmD7Z1y9vRQZYuwPSXVx9v4XEXGLB-mB'],
        [RARITY.common, '1dmgggMcAvPtA0jhrhOPzijwFzgbfpq-N'],
        [RARITY.rare, '1ayK1YTZpDpyjiI3MgfHtziKwOHhtNyCk'],
        [RARITY.rare, '1dmsAd0NA5I24Fo6aFRBKgGPN694gRf4A'],
        [RARITY.rare, '1dk9tNcbc5zoyruwApry_hBqjbMaQ3RRk'],
        [RARITY.rare, '1dhPEomL29JNJ_QPJnwRr_ZXMw2_OF9Y2'],
        [RARITY.rare, '1auiIz53wLUcMr0nW4RpShrNkpnXHbQp7'],
        [RARITY.rare, '1diCIoZwLT6_BqZTCPou_ehLsx-PWXZ_F'],
        [RARITY.rare, '1aqUPkFtHUhySbwIjmlawNmzleVudxjwN'],
        [RARITY.epic, '1an9c-i0VQqo0D0HrERbZfk-LH0GV0kfn'],
    ] as [number, string][]).map(expandWeightedUrl),
    housefire: ([
        // [RARITY.common, '1Go3UaBR7CaNZdkLb5BbCOZ8CYVN47URp'],
        // [RARITY.common, '1pGFO8p5S9-CHhPzVPE8xQsyok5ZUeslO'],
        // [RARITY.common, '1Mrlo-wDG7XM-RBNC0hc0v1BBHM9zo9Ut'],
        [RARITY.common, '1-FzUfiUi5oCuqpsQTuajfC3pVucjDR9G'],
        [RARITY.common, '11k_OL6E0d1NJj0dZAEulD8ryoh5DgVr1'],
        [RARITY.common, '12u3tTRKNTHb1w5BHdZi4QKW1O67dNIqn'],
        [RARITY.common, '15d4z1f1VXFYyAHN5gHJahouRD_XV0IiK'],
        [RARITY.common, '1BXRbYj31VviCCQ0nWxNMXZ9Uz_6Ojo8o'],
        [RARITY.common, '1EwmPQjZ56z0AI-kw0EcMma4ISix4_6rD'],
        [RARITY.common, '1IofaWyufOmZEHNy2cCjsH08AsylDdCPe'],
        [RARITY.common, '1KZMJBqFRVY1cFU1VTGPS1a2Ek5WA6T05'],
        [RARITY.common, '1UQvvLP2LcyGphC1XTGvOtauZtr5TrsbV'],
        [RARITY.common, '1X1tqAXTq9qHF5lnXmqmzXh92fJoF9kjE'],
        [RARITY.common, '1ZdDcBPoy8Eu225IOESrJomjIZkO_EPRH'],
        [RARITY.common, '1_CkTd8a_uDa-N7wUoDk9menNt-OQiBWG'],
        [RARITY.common, '1_tEnCE9cM1Czy3KFMnWMMRbpfGuh5YsX'],
        [RARITY.common, '1gAQtRcE74F8kL5juaym9ZtY7qEtPajNb'],
        [RARITY.common, '1jKXIpt87VQNIZvHXvs9-STJbqLR5gnrT'],
        [RARITY.common, '1m48iHVr_HG7fcK6uBvWBPmbPXfjagUMZ'],
        [RARITY.common, '1qmINornR7ZZKTmsnreojtRPwV5EUGX1L'],
        [RARITY.common, '1rF0Ofsx8s1L4B7CAgOeNJ1uOVbwb2qTD'],
        [RARITY.common, '1vlzMH1CokRcjZrIrDzd1Ryug6aqq4G1X'],
        [RARITY.common, '1waXcVOf-ZIEa2ygzoFlt14IfWwPkuU8a'],
        [RARITY.common, '1yfqKcYpq57ZRCFTG6thxvrfy0RhfLmP4'],
        [RARITY.common, '1zyEVkkfcUL3dAjtCXdHrjyLhSwzWl-Ci'],
        [RARITY.rare, '1SckbgsgGHex2PYNE7IeoK3-fUbuYrSCl'],
        [RARITY.rare, '1FomPtpcGM0GrhyS2yBkPaTsKgd0f5tfe'],
        [RARITY.rare, '1aqNLQCK476Jwri4ndGUYUgQSLm9V0TCv'],
        [RARITY.rare, '1NO9U5ehHUbEO9InTHcgerl1Qm-lQ-jf9'],
        [RARITY.rare, '1Uvsd9z-KjbjLwMMtp1kKAVc7CYXufqIv'],
        [RARITY.rare, '1FWUSU1l8NoeqNPDRchMbLEPXsBzJYvr9'],
        [RARITY.rare, '16DCX2EU4-vBv0YxKYeJag6K6DYq770rm'],
        [RARITY.rare, '14cK72u7cI4WhABc8EYx4XzENwKj6yerr'],
        [RARITY.rare, '13D_D9l08J4_x8SZm6VwN3XoMQEj0HDC5'],
        [RARITY.rare, '133MrE6aI3UVtfSv8iLOV22ZcTvzMUkd3'],
        [RARITY.rare, '1-_8kaE_INLD3g2PQ6AvfoL_fXF7lxVMj'],
        [RARITY.rare, '1-4eAitHeVAtioasu9y6IqwhcrOVLMzkO'],
        [RARITY.rare, '1dj1iKwVz9Zzmkqt5b54U5TTOREmVO2ar'],
        [RARITY.rare, '1rJqJCsPZ4W2yDXh29vhhx1r1ViJ8XuC5'],
        [RARITY.rare, '1rL0dB-wt_klLvAM_gAmcP8JsrCg3K_XA'],
        [RARITY.epic, '1ggvosFe-Fxb6shxGwopn4tKQGGJVgF0d'],
        [RARITY.epic, '1G_vQsFxmFpitnSAq9G0Oz7qQ5cWn2oGK'],
        [RARITY.epic, '1oj31HE599_XTqG62zzrmj94dfZazfSq9'],
    ] as [number, string][]).map(expandWeightedUrl),
    overlook: ([
        // [RARITY.common, '1ahJpj8Eql6MvbAta6F4fPejww3taEARp'],
        [RARITY.common, '1_kRx1EsXsyAEuay-y3GX79rzzeKUNZz8'],
        [RARITY.common, '1_kfYUjQXZe_tixqbd561tVS2G5VYFqME'],
        [RARITY.common, '1_wfGt3WgHPvd7ePSGfLhRBJ3LlxGVYkK'],
        [RARITY.common, '1_ws2H6GaCzUSRDRPAkIf46CQ4zz-4KF3'],
        [RARITY.common, '1_yf_5hosiMlRHTeJC57uuVfVbcwle4-L'],
        [RARITY.common, '1a1zt0y1pC3QJUdJQ2wn00IHwVRDqDPee'],
        [RARITY.common, '1a20lkEXOIJ3mLBt9D-El6FCfKTBhTQcu'],
        [RARITY.common, '1a3vHhgGYlEttoPWItTD9ztJ3TdE2CbRa'],
        [RARITY.common, '1a5nxEx6TUfNL4odfjAriitshuc2sCLtg'],
        [RARITY.common, '1a6-P8cb596j9_tp4aD1bL4SnPfTFdisU'],
        [RARITY.common, '1aV2RspyU1rxeoL-tsv1JH8Hfgc6nlqlo'],
        [RARITY.common, '1abEz_xcp3b1bHgT6mDpucxvyzS-cvAMb'],
        [RARITY.common, '1acln8Bh__CKfZOLDJb0Y9HLjBAKDJAti'],
        [RARITY.common, '1acsep2fC2P4s5meEWzuQp0DDwqk_8lzS'],
        [RARITY.rare, '1aj4Rueq-fLVhKDw7PaurEzRqbN4y_Acz'],
        [RARITY.rare, '1aMfM4_zj3FS8AD_z2q-OSHjr9wc83F8X'],
        [RARITY.rare, '1aM75ik9D7-f4ghPS7Z2j7CrkERoHEuuP'],
        [RARITY.rare, '1aSLk0BHG0pHJiVUCkezvdgyVmD97WU4P'],
        [RARITY.rare, '1aDTyD8YrcZm0fp2w8Ulzu8MHo-h0zphw'],
        [RARITY.rare, '1aW83L-w2523hDm8bX58PPpjFgng0COXc'],
        [RARITY.rare, '1aF7KV8fk958Rl7i4cYLlY53fJLlEEg3o'],
        [RARITY.rare, '1aL2Xuq19r5T5G8R6_cSateZq0mmRRgyB'],
        [RARITY.rare, '1aCuP-afTkNCTRtiSpMD0E7Sb8mzRGSD2'],
        [RARITY.rare, '1alDi20VexU5Vp-A3RauSAKC17mDmF6bJ'],
        [RARITY.epic, '1ajYx1N2QYBV9nOnQLJAKa6ErM67K5hik'],
        [RARITY.epic, '1an0J_QDtOh5cjKbt_YJUjsAdgwefCnin'],
    ] as [number, string][]).map(expandWeightedUrl),
    path: ([
        [RARITY.common, '1buhYMM63Ih6YRz3YpPpD0BrYIfD7d8z6'],
        [RARITY.common, '1bwRrejRz7cjalmWY-B0-pqMqlaq28CxX'],
        [RARITY.common, '1bxjerL1Ywqb6rQnJwYpj70_LMN9nAl0-'],
        [RARITY.common, '1bz550nePCuz7EFsCdgEjYqAGY5mi4ua5'],
        [RARITY.common, '1c-pCISdvTaFXcUCCDCKdmy4_owumOhXd'],
        [RARITY.common, '1c0U2_WR0hmotQg2F38_z8JGA_j7hPM1l'],
        [RARITY.common, '1c2HS7ZknXzEtj2YDhB29rf55bvSusF2X'],
        [RARITY.common, '1c9504S6UKWI3AmoULCS4ClxbhsJdSIXJ'],
        [RARITY.rare, '1bV81ey3TK75hDLquIOcnSzjXz6LeU0K-'],
        [RARITY.rare, '1bYDNhsXPL64_pw4OCYSGd0GZEsTAuT0d'],
        [RARITY.rare, '1bYIjO4-SxSQ7L3QlaA7mOfPWH4Yu5QWN'],
        [RARITY.rare, '1bc1nXWNlVehCKZ3NQYMswTL6Ri6nxcB1'],
        [RARITY.rare, '1bgKU4mm1kNcV_zmNUcJiGpFXz3l7VLMX'],
        [RARITY.rare, '1bhelHfIluXlbfFckCEWXOsqTKc7W0C8A'],
        [RARITY.rare, '1bkQzT1NeTQ9difQj4Bb3QtrRUj2DaGwW'],
        [RARITY.rare, '1bqoJ03cyUEpcZBNluBwGFKJbyFgnkDqC'],
        [RARITY.epic, '1buLv9GGxxo2Xxq7qtH3Aml7BOdjrS7tu'],
        [RARITY.epic, '1bwVKzy1hSl5N1ytfr0reYa0JfPNcvJfN'],
    ] as [number, string][]).map(expandWeightedUrl),
    shoes: ([
        // [RARITY.common, '15DUz2fNcAVEQ3FIcPTBHEO564_LvkhRI'],
        // [RARITY.common, '19D7PiLBHLojmsP9azBDFcRFeiMp5OS5Y'],
        // [RARITY.common, '19YDh1d66kXis5ewN1kfXjjNJ3Sk72CW8'],
        // [RARITY.common, '1Galeaqqx0jKgiWeck05M7oInU5Jr7JJF'],
        // [RARITY.common, '1NJYUMVeoZAq96ssd41zSfWNpJon4yB1v'],
        // [RARITY.common, '1fVDNy4gytADJhVVvDLNzXyiegT9Vk-RE'],
        // [RARITY.common, '1mneD650M1WI7j5EcZcIR8vKIBlj5jwid'],
        // [RARITY.common, '1r6pwBk6HMRkz84rIi_eIfk1Ic78bfEaP'],
        // [RARITY.common, '1-Ex-uJQnPS26SZAnkPyGX3QBuMYZCjng'],
        [RARITY.common, '13g_QBTSNqUxZFybGUObr9u4ZGRsmmVYp'],
        [RARITY.common, '18AvQlAVgCQUUx1JUUKpzQFqTIS7odfaE'],
        [RARITY.common, '19QcsmmYonwT6WsCiBL7OTxftV2ThnnuS'],
        [RARITY.common, '1BJwg1xZvAB3dy-zp59cSFw-PRsuM-Ts1'],
        [RARITY.common, '1DdnuTP45g9u5dmJsh3vzXFhaSNegy4ja'],
        [RARITY.common, '1EYAAYS4l_uqkEg7VPX8zwWoMps7uqDJJ'],
        [RARITY.common, '1L1UMroshadJ8H0DZLmkK-LJWcLuKLecg'],
        [RARITY.common, '1VUPLr1UqZvzOMcPzURtw6MpTuU4byQJd'],
        [RARITY.common, '1a2rOFgco5c1CYxW2LXWrsN5bIHoukW2L'],
        [RARITY.common, '1jFUFODXTEcweruxpFbykCXLATrQAzNEc'],
        [RARITY.common, '1mkgaDgoqnF4Bx2Cy10Y3EJiW8tXfolcE'],
        [RARITY.common, '1x7AQ9dmf4nZmlknUTatmGXlkzjAYQF5c'],
        [RARITY.common, '1yLolb9UdomRVwq0Rngy43JGnxGb1zKZf'],
        [RARITY.common, '1ysUVzv8V-cM_miESnruretBcLwodOIug'],
        [RARITY.rare, '1Khzi9_saNP-4ACeIwrnVwwh_7nq3vwL-'],
        [RARITY.rare, '1JYsxp5XiG-_j9CNVksw99SDHp9CWzmWj'],
        [RARITY.rare, '171ejM1pBeOt4feDnRzBTS7Y4C4qHuoB4'],
        [RARITY.rare, '1M6AuZoRYWG1KWbtuIeTC2sDsXWOjdJ36'],
        [RARITY.rare, '1OOjAGuUOVbVPsc5uhuupKbVFSPcH3GUu'],
        [RARITY.rare, '1OdywsPzUG-VYtNEO_oz6le78asJrx82C'],
        [RARITY.rare, '1Se3u3_jq6PqvpKAdJH4XO8vwRwPipfA6'],
        [RARITY.rare, '1USleYFNSdB_fx43CQtQT7i0JPN4iFTOy'],
        [RARITY.rare, '1_sCwJycjbglrzLl-qjqRtP_vS42ggWQj'],
        [RARITY.rare, '1dQMp20JnLQql8noX1fSGprU0dAL49rJD'],
        [RARITY.rare, '1lxxuePJGDELg2Zi6jasvxVItbQltOcQ8'],
        [RARITY.rare, '1spnwHeCvSNPBkzhrSj5X6MbDfls7NSgP'],
        [RARITY.epic, '10637ryOOSjm3IObwpgncdXokAgE_H5hc'],
    ] as [number, string][]).map(expandWeightedUrl),
    silhouette: ([
        [RARITY.common, '109KRYSCQYuX_43ucI8Mf82GMCNPRzx5s'],
        [RARITY.common, '1EqQayNeatoomkBmMbFLI_Qf8PUSPcqDQ'],
        [RARITY.common, '1IWLYKWLLXBCHNUV1R2LXA5gAepQ1Mllp'],
        [RARITY.common, '1IYCbpGF84ALIWlvRsZJL0qWPAafdj_Qs'],
        [RARITY.common, '1IYa6DMRUr36-rn82B84kZ6XxQtiyYLOr'],
        [RARITY.common, '1Ic3TeOCwKP6EEPEdmbGET7_z3VX-rq7q'],
        [RARITY.common, '1RKprTxN7hX3E0tVE9meR6zPFisDtZBYX'],
        [RARITY.common, '1SDCLPRsGOZLRoArJsyhIMyOdNFemNeRH'],
        [RARITY.common, '1Upe-QXgQwA9qs4nY0diUieK93RyKc3EF'],
        [RARITY.common, '1ZBz8GMc-kEhxsMksRnG40AEctPD_e0Ni'],
        [RARITY.common, '1gXO8tiNCOSgJB5hRm9Eh9v15XnjHrOAe'],
        [RARITY.common, '1ls5fOJj_94KgUX2_ohah12PATQBmlXBZ'],
        [RARITY.common, '1mU6OUliu928fGANA-XGK2KH_IM012NvB'],
        [RARITY.common, '1msInaGelSLVsS5eF8DZlZEW9xzQBRcH0'],
        [RARITY.rare, '17ZMO7ZdN64cIsaU3yWHUhVV3eEqanO17'],
        [RARITY.rare, '1L51pfbyGtUeHDm9iDtT9Dg2F2BbZ5WX4'],
        [RARITY.rare, '1O-zKCGUZPQOmrhJCD4rgm4H3Z2d-OODC'],
        [RARITY.rare, '1OU_sqH4BSAOsPvW9YDgmxe3DQEaAPQki'],
        [RARITY.rare, '1PFSjFPlmfuA_qstX15NCJfYoonDs9dAl'],
        [RARITY.rare, '1SOnPpcEtENpo3Fklmi6g8Fl-4UbTVn0n'],
        [RARITY.rare, '1kAi6ReH7g5fV_6EOIejjRcs04iKLWC6p'],
        [RARITY.rare, '1n9p0IokuXdNKiD3Kt2npYQwJ1Pt9mFoF'],
        [RARITY.rare, '1qAxcZsWFDzCiRrZ5C6Fb6fYFdyMDNdWW'],
        [RARITY.rare, '1rWzVguI7uH4nQMX3Wwiwx8QfVzeJir0s'],
        [RARITY.epic, '1RJSz2X490cVPCuiLNy0Iz_ceoCi8ss4M'],
        [RARITY.epic, '1p9v_DgcnyKjcOwaZz4RiOyTcLMdugjxq'],
    ] as [number, string][]).map(expandWeightedUrl),
    surfboard: ([
        [RARITY.common, '1dnjzfcFOVJ7Mt531iYioTuNYiIP0euXX'],
        [RARITY.common, '1droB66Z4AVn1m-TSXWTUs_CJl7IUdvZ2'],
        [RARITY.common, '1dtJL53dumXFVZOPz02udWEyv5L93psuu'],
        [RARITY.common, '1duWl1LcQ0LBV7dCqODqbMzUQjqSxiwzO'],
        [RARITY.common, '1dvsiVBR1e98WVbBh46_GSMdOAWVwGAwi'],
        [RARITY.common, '1dzXnsUirj_8GzreOuxgLyCOtLeeAD2Ia'],
        [RARITY.common, '1e1N_1gFZWw8aeUFEaHwNLZfbdmJd4hcb'],
        [RARITY.common, '1e5aEgsFmn6hYDoOMzV9YnNXmd2OnfIZN'],
        [RARITY.common, '1eD8_JaheqE4EdzrA0tgwe4c95PuxKLvw'],
        [RARITY.rare, '1e-X0uUNjZnH7rFEfQl4vcErXHy6eZ2K5'],
        [RARITY.rare, '1e4y-JxYKfhPu7MDI-sWVVeg_gXSWgCHW'],
        [RARITY.rare, '1e5vQAguN_dlI-tGXZDovrc6C2KYGpr8_'],
        [RARITY.rare, '1e88fKcU6hKZINty5xt9gOG2n68TdyMP6'],
        [RARITY.rare, '1eDbHgb8KQLhw7VjlndH1WjRtWGXxZzEO'],
        [RARITY.rare, '1eGG0NZfX6ItVsd91EwDPQCnken9LUtrU'],
        [RARITY.rare, '1eMnwAuSa21qUKZUyh93TLBw7fv-W1Klg'],
        [RARITY.rare, '1ePrhZnxmP6SVDNHma8u2JqqkL_SUfS5T'],
        [RARITY.rare, '1eQJEbyWwmgHofmIsOP7Lak5igvD1fnq9'],
        [RARITY.epic, '1eGqwjE0mJKdZAQCDXyYzNeIYKBSi6tgw'],
    ] as [number, string][]).map(expandWeightedUrl),
};


export const themeWeights: Record<CardTheme, number> = Object.keys(cardImageUrls)
    .reduce(
        (acc, _theme) => {
            const theme = _theme as CardTheme;

            acc[theme] = cardImageUrls[theme]
                .reduce(
                    (acc, item) => acc + item.weight,
                    0
                );

            return acc;
        },
        {} as Record<CardTheme, number>
    );
