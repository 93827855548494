import React, {
    useState,
    FC,
    ReactElement,
} from 'react';
import styled from '@emotion/styled';

const Trigger = styled.button<{ open?: boolean }>`
    border: none;
    background: none;
    color: inherit;
    text-align: left;
    width: 100%;
    padding: .75em 0;
    border-bottom: 1px solid #444;
    position: relative;
    ::before {
        content: '›';
        font-size: 1.5em;
        position: absolute;
        right: 0;
        top: .25em;
        transition: transform .5s ease;
        ${props => props.open ? `
            transform: rotate(90deg);
        ` : `
            transform: rotate(0deg);
        `}
    }
`;
const Content = styled.div<{ open?: boolean; }>`
    ${props => props.open ? `
        max-height: 100em;
        opacity: 1;
    ` : `
        max-height: 0em;
        opacity: 0;
    `}
    transition: max-height .5s ease, opacity 0.3s ease;
    overflow: hidden;
`;
export const Accordion: FC<{
    buttonText: string;
    expandContent: ReactElement;
}> = ({
    buttonText,
    expandContent,
}) => {
    const [open, setOpen] = useState<boolean>(false);

    return <>
        <Trigger
            onClick={() => setOpen(o => !o)}
            open={open}
        >
            {buttonText}
        </Trigger>
        <Content open={open}>
            {expandContent}
        </Content>
    </>;
};