import { Color } from './types';

export const hexToColor = (hex: string): Color => [
    parseInt(hex.substring(0, 2), 16),
    parseInt(hex.substring(2, 4), 16),
    parseInt(hex.substring(4, 6), 16),
    hex.length === 8 ? parseInt(hex.substring(6, 8), 16) : 255,
];

export const combineColors = (
    colorA: Color,
    colorB: Color
): Color => {
    // if (colorB[3] < 20) {
    //     return colorA;
    // }
    // if (colorA[3] < 20) {
    //     return colorB;
    // }
    // return colorA;

/*
// https://stackoverflow.com/a/52993128
def get_color(colorRGBA1, colorRGBA2):
    alpha = 255 - ((255 - colorRGBA1[3]) * (255 - colorRGBA2[3]) / 255)
    red   = (colorRGBA1[0] * (255 - colorRGBA2[3]) + colorRGBA2[0] * colorRGBA2[3]) / 255
    green = (colorRGBA1[1] * (255 - colorRGBA2[3]) + colorRGBA2[1] * colorRGBA2[3]) / 255
    blue  = (colorRGBA1[2] * (255 - colorRGBA2[3]) + colorRGBA2[2] * colorRGBA2[3]) / 255
    return (int(red), int(green), int(blue), int(alpha))
*/
    const alpha = 255 - ((255 - colorA[3]) * (255 - colorB[3]) / 255);
    const red = (colorA[0] * (255 - colorB[3]) + colorB[0] * colorB[3]) / 255;
    const green = (colorA[1] * (255 - colorB[3]) + colorB[1] * colorB[3]) / 255;
    const blue = (colorA[2] * (255 - colorB[3]) + colorB[2] * colorB[3]) / 255;

    return [
        Math.round(red),
        Math.round(green),
        Math.round(blue),
        Math.round(alpha),
    ];
};

