import { FogState, FogStateSerialized } from 'plugins/fog';
import { VelocityState, VelocityStateSerialized } from 'plugins/location';
import { Item, ItemSerialized, ItemState } from 'plugins/items';
import {
    ScenesState,
    SceneStateSerialized,
    Village,
    BurningHouse,
    ScientistCollect,
    Robots,
} from 'plugins/scenes';
import { PlayerState, PlayerStateSerialized } from 'plugins/player';
import { Bus } from 'message/Bus';
import { theme } from 'color';
import { Coord, Gps } from 'coords';
import { BaseStore } from 'store';
import {
    emojiStrings,
    EmojiStringImageEncoder,
} from 'image';
import { GiveCard } from 'cards';
import { MapApp } from 'maps';
import { Throttler } from 'game';
import { HudStateSubscriber, QuestHudState } from 'components';
import { stopJoystick } from 'plugins/location';
import { Elephant } from 'plugins/scenes/Elephant';
import { addRoads1to2, addRoads2to3, addRoads3to1, RoadFunction } from 'plugins/roads';
import { Bureaucrat } from 'plugins/scenes/Bureaucrat';
import { Status, StatusState, StatusSubscriber, marblesOwned } from './StatusState';

export const START_ISLAND_MULTIPLIER = 1.5;

export type GameStateSerialized = {
    seed: number,
    // refactored poorly named "flags" but leave this for people's save games
    flags: Status,
    items: ItemSerialized[],
    fog: FogStateSerialized,
    velocity: VelocityStateSerialized,
    player: PlayerStateSerialized,
    scenes: SceneStateSerialized,
    location: Coord,
};

export const dailySeed = (dayDelta: number = 0) => {
    const seedDate = new Date();
    seedDate.setUTCHours(0, 0, 0, 0);

    if (dayDelta) {
        seedDate.setDate(seedDate.getDate() + dayDelta);
    }

    return seedDate.getTime();
}

const getSeed = (mode: GameMode) => {
    if (mode === 'daily') {
        return dailySeed();
    }

    if (typeof mode === 'number') {
        return mode;
    }

    return 0; // will be loaded and replaced anyway;
}

export type GameMode = 'daily' | number;

type CastleKey = "1" | "2" | "3";
const CASTLE_TO_ROAD_FUNC: Record<CastleKey, RoadFunction> = {
    1: addRoads1to2,
    2: addRoads2to3,
    3: addRoads3to1,
};

export class GameState {
    mode: GameMode;
    seed: number;
    bus: Bus;
    status: StatusState;
    items: ItemState;
    fog: FogState;
    velocity: VelocityState;
    player: PlayerState;
    scenes: ScenesState;
    huds: QuestHudState;
    gps: Gps;
    loaded: boolean = false;
    store: BaseStore<GameStateSerialized>;
    emojiImage: EmojiStringImageEncoder;
    giveCard: GiveCard;
    constructor(
        mode: GameMode,
        sub: StatusSubscriber,
        bus: Bus,
        store: BaseStore<GameStateSerialized>,
        emojiImage: EmojiStringImageEncoder,
        gps: Gps,
        giveCard: GiveCard,
        hudSubscriber: HudStateSubscriber,
    ) {
        this.mode = mode;
        this.bus = bus;
        this.store = store;
        this.emojiImage = emojiImage;
        this.gps = gps;
        this.giveCard = giveCard;
        this.seed = getSeed(mode);
        this.status = new StatusState();
        this.status.subscribe(sub);
        this.huds = new QuestHudState(hudSubscriber);
        this.items = new ItemState();
        this.player = new PlayerState(emojiImage);
        this.fog = new FogState(
            this.items,
            bus,
            this.status,
            emojiImage,
            this.giveCard,
        );
        this.velocity = new VelocityState(
            this.items,
            bus,
            this.status,
            emojiImage,
            this.giveCard,
        );
        const village3 = new Village(emojiImage, [3, 3], 700, this.onCollectVillage.bind(this, '3'));
        this.scenes = new ScenesState(
            {
                'village01': new Village(emojiImage, [1, 2], 100, this.onCollectVillage.bind(this, '1')),
                'village02': new Village(emojiImage, [2, 4], 500, this.onCollectVillage.bind(this, '2')),
                'village03': village3,
                'bureaucrat': new Bureaucrat(
                    emojiImage,
                    () => village3.primary('castle')
                ),
                'elephant': new Elephant(
                    emojiImage,
                    this.player.enableSkin,
                    this.player.setSkin,
                    this.player.getSkin
                ),
                'burningHouse01': new BurningHouse(
                    emojiImage,
                    80,
                    400,
                    this.onClearBurningHouse,
                    this.bus,
                    this.player.enableSkin,
                    this.player.setSkin,
                ),
                'scientistCollect': new ScientistCollect(
                    emojiImage,
                    bus,
                    this.giveCard,
                    this.player.enableSkin,
                    this.player.setSkin,
                ),
                'robot': new Robots(
                    emojiImage,
                    this.bus,
                    this.player.enableSkin,
                    this.player.setSkin,
                    this.player.getSkin,
                    this.adjustMarbles
                ),
            },
            this.items,
            gps,
            this.status,
            emojiImage,
            this.huds.setHud,
        );
    }

    async initialize(shouldLoad: boolean) {
        if (shouldLoad) {
            // get setup from localstorage then decide whether to initialize or load
            const saved = await this.store.select();
            if (!saved) {
                throw new Error('tried to enter loaded mode with no save data');
            }
            this.load(saved);
            return;
        }
        this.fog.initialize();
        this.velocity.initialize();
        this.scenes.initialize();
        this.items.addFactory(this, map => {
            const itemMinMax = map.gps.generateFromCenter();
            const marbleCount = map.ints.next(30, 50);
            this.status.put('marblesRemaining', marbleCount);
            return new Array(marbleCount).fill(null).map(() =>
                new Item({
                    name: 'marble',
                    ...itemMinMax(80),
                    minDist: map.gps.totalDim[0] * 0.1,
                    minLevel: 3,
                    color: theme.scenes.marble,
                    onCollect: this.onCollectMarble,
                    customRender: this.emojiImage.makeApply('marble'),
                    minOtherItemDist: 60,
                })
            );
        });
    }

    load(serialized: GameStateSerialized) {
        this.loaded = true;
        this.seed = serialized.seed;
        this.status.load({
            // refactored poorly named "flags" but leave this for people's save games
            ...serialized.flags,
            saving: false,
        });
        this.gps.load(serialized.location);
        this.fog.load(serialized.fog);
        this.velocity.load(serialized.velocity);
        this.scenes.load(serialized.scenes);
        this.player.load(serialized.player);

        this.items.addFactory(this, map =>
            serialized.items.map(itemSerialized => {
                // console.log(`item ${itemSerialized.loc[0]}, ${itemSerialized.loc[1]}`);
                return Item.load(
                    itemSerialized,
                    this.onCollectMarble,
                    this.emojiImage.makeApply('marble'),
                );
            })
        );
    }

    onLoadComplete(map: MapApp, loaded: boolean) {
        const villages: Record<CastleKey, Item> = {
            1: this.scenes.scenes.village01.primary('castle'),
            2: this.scenes.scenes.village02.primary('castle'),
            3: this.scenes.scenes.village03.primary('castle'),
        };
        Object.entries(villages).forEach(([key, village]) => {
            if (village.data.status === 'finished') {
                CASTLE_TO_ROAD_FUNC[key as CastleKey](
                    map,
                    this.scenes.scenes.village01.primary('castle'),
                    this.scenes.scenes.village02.primary('castle'),
                    this.scenes.scenes.village03.primary('castle'),
                );
            }
        });
        if (!loaded) {
            const locCenter = map.gps.locationCenter;
            const cutSize = 150; 
            this.fog.cutoutDisc(
                map.grid.totalWidth,
                [
                    Math.round(
                        locCenter[0] - (cutSize / 2)
                    ),
                    Math.round(
                        locCenter[1] - (cutSize / 2)
                    ),
                ],
                cutSize,
                cutSize,
            );
        }
        this.scenes.onLoadComplete(map);
    }

    save = (
        suppressStatus: boolean = false
    ) => this.store.save(
        this.toJSON(),
        () => !suppressStatus && this.status.put('saving', true),
        () => !suppressStatus && this.status.put('saving', false),
    );
    saveAfterRender = () => this._shouldSaveAfterRender = true;

    private _shouldSaveAfterRender: boolean = false;
    onMapRenderEnd = () => {
        if (this._shouldSaveAfterRender) {
            this.save();
            this._shouldSaveAfterRender = false;
        }
    }

    adjustMarbles = (qty: number): number | false => {
        if (this.status.values.marblesOwned + qty < 0) {
            return false;
        }
        this.status.put(
            'marblesOwned',
            this.status.values.marblesOwned + qty,
        );
        marblesOwned.save(
            this.status.values.marblesOwned
        );
        return this.status.values.marblesOwned;
    }

    villageThrottle = new Throttler(10);
    onCollectVillage = (
        whichCastle: '1' | '2' | '3',
        map: MapApp,
        castle: Item
    ) => {
        if (this.villageThrottle.waits()) {
            return;
        }
        if (castle.data.status === 'finished') {
            return;
        }
        const multpleOf = 5;
        const cost = Math.max(
            5,
            Math.floor(
                Math.floor(
                    (this.status.values.marblesOwned * .25) / multpleOf
                ) * multpleOf
            )
        );
        stopJoystick();
        const pay = window.confirm(
            `Join the kingdom by paying your taxes? ${cost} marbles`
        );
        if (!pay) {
            return;
        }
        if (this.adjustMarbles(-cost) === false) {
            this.bus.addQuiet(`not enough marbles`, 2);
            return;
        }
        castle.data.status = 'finished';
        CASTLE_TO_ROAD_FUNC[whichCastle](
            map,
            this.scenes.scenes.village01.primary('castle'),
            this.scenes.scenes.village02.primary('castle'),
            this.scenes.scenes.village03.primary('castle'),
        );
        this.scenes.scenes.bureaucrat.updateHud();
        this.fog.cutoutFromPlayer(map, 2);
        this.giveCard('castle');
        this.saveAfterRender();
    }

    onClearBurningHouse = () => {
        this.bus.addQuiet(`you saved my home! take this gift of 10 marbles ${emojiStrings.marble}`, 2);
        this.status.put(
            'marblesOwned',
            this.status.values.marblesOwned + 10,
        );
        marblesOwned.save(
            this.status.values.marblesOwned
        );
        this.giveCard('housefire');
        this.saveAfterRender();
    };

    onCollectMarble = () => {
        this.status.put(
            'marblesRemaining',
            this.status.values.marblesRemaining - 1,
        );
        this.status.put(
            'marblesOwned',
            this.status.values.marblesOwned + 1,
        );
        marblesOwned.save(
            this.status.values.marblesOwned
        );
        this.saveAfterRender();
    };

    toJSON(): GameStateSerialized {
        return {
            seed: this.seed,
            // refactored poorly named "flags" but leave this for people's save games
            flags: this.status.values,
            items: this.items.selectMine(this)?.map(item => {
                const json = item.toJSON();
                // console.log(`item ${json.loc[0]}, ${json.loc[1]}`);
                return json;
            }),
            fog: this.fog.toJSON(),
            velocity: this.velocity.toJSON(),
            scenes: this.scenes.toJSON(),
            player: this.player.toJSON(),
            location: this.gps.location,
        }; 
    };
}
