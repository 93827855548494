import React, {
    useRef,
    MutableRefObject,
    useEffect,
    useState,
    useCallback,
    FC,
} from 'react';
import styled from '@emotion/styled';
import { GameState, initialStatus, Status, GameMode } from 'game';
import { MapApp } from 'maps';
import { joystickPlugin } from 'plugins/location';
import { fogPlugin } from 'plugins/fog';
import { playerPlugin, Skin } from 'plugins/player';
import { homePlugin } from 'plugins/home';
import { fullRenderItemPlugin, itemPlugin } from 'plugins/items';
import { treePlugin } from 'plugins/trees';
import { roadPlugin } from 'plugins/roads';
import { GenerationProgress } from 'generate';
import { Bus, Message } from 'message/Bus';
import {
    FullHeight,
    Messages,
    toolString,
    marblesString,
    ToolBar,
} from 'components';
import { EmojiStringImageEncoder } from 'image';
import { Gps } from 'coords';
import { GiveCard } from 'cards';
import { HudRecord } from './questHuds';
// import { useWorker } from 'useWorker';

const Layout = styled(FullHeight)`
    position: fixed;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const MaxSize = styled.div<{ show?: boolean; }>`
    position: relative;
    flex: 1;
    min-width: 10px;
    min-height: 10px;
    max-width: 900px;
    max-height: 1280px;
    /* background-color: pink; */
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    opacity: ${props => props.show ? '1' : '0'};
    overflow: hidden;
`;
const Canvas = styled.canvas`
    background-color: #222;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
`;
const WaitText = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;

const useMapApplication = (
    store: GameState['store'],
    mode: GameMode,
    shouldLoad: boolean,
    giveCard: GiveCard,
) => {
    const ref = useRef<HTMLCanvasElement>(null);
    const [map, setMap] = useState<MapApp>();
    const [gameState, setGameState] = useState<GameState>();
    const [generateStatus, setGenerateStatus] = useState<string>('Please wait');
    const [progress, setProgress] = useState<GenerationProgress>([0, 1]);
    const [messages, setMessages] = useState<Message[]>([]);
    const [status, setStatus] = useState<Status>(initialStatus);
    const [huds, setHuds] = useState<HudRecord>({});
    const [sharing, setSharing] = useState<boolean>(false);

    const share = useCallback(async () => {
        const canvas = ref.current;
        if (!canvas) {
            throw new Error('where is teh canvas?');
        }

        setSharing(true);
        try {
            const blob = await new Promise<Blob>(resolve => {
                canvas.toBlob(blob => {
                    if (!blob) {
                        throw new Error('no blob?');
                    }
                    resolve(blob);
                });
            });
            const file = new File(
                [blob],
                "fidgetmap.png",
                {type: blob.type}
            );
            const shareConfig = {
                title: 'fidgetmap',
                text: [
                    toolString(status) + ' ' + marblesString(status),
                    `https://www.fidgetmap.com/?seed=${gameState!.seed}`
                ].join('\n'),
                files: [file],
            };
            await navigator.share(shareConfig);
        } catch(er) {
            console.log(er);
        } finally {
            setSharing(false);
        }
    }, [status, gameState, ref]);
    const flashCamera = useCallback(() => {
        if (!map || !gameState)
            return;

        const cutSize = 160; 
        const locCenter = map.gps.locationCenter;
        gameState.fog.cutoutDisc(
            map.grid.totalWidth,
            [
                Math.round(
                    locCenter[0] - (cutSize / 2)
                ),
                Math.round(
                    locCenter[1] - (cutSize / 2)
                ),
            ],
            cutSize,
            cutSize,
        );
        map.render();
    }, [gameState, map]);

    useEffect(() => {
        if (!mode) {
            setMap(undefined);
            setGameState(undefined);
            return;
        }
        const canvas = ref.current;
        if (!canvas) {
            throw new Error('where is teh canvas?');
        }
        const emojiImageData = new EmojiStringImageEncoder();
        const bus = new Bus({
            quiet: setMessages,
            loud: alert,
        });
        const gps = new Gps();
        const state = new GameState(
            mode,
            setStatus,
            bus,
            store,
            emojiImageData,
            gps,
            giveCard,
            setHuds
        );
        setGenerateStatus('Loading');
        let newMap: MapApp;
        let canceled: boolean = false;

        (async () => {
            await state.initialize(shouldLoad);
            if (canceled) return;
            newMap = new MapApp(
                canvas,
                [
                    roadPlugin(state),
                    treePlugin(state, 'top'),
                    playerPlugin(state),
                    treePlugin(state, 'bottom'),
                    itemPlugin(state, bus),
                    fullRenderItemPlugin(state),
                    homePlugin(state, giveCard),
                    fogPlugin(state),
                    fullRenderItemPlugin(state, true),
                    joystickPlugin(state),
                ],
                gps,
                state.onMapRenderEnd,
                state.seed
            );
            setGenerateStatus('Generating map');
            for await (const progress of newMap.generate()) {
                if (canceled) return;
                setProgress(progress); 
            }
            setGenerateStatus('Generating items');
            for await (const progress of state.items.generate(newMap)) {
                if (canceled) return;
                setProgress(progress); 
            }
            setGenerateStatus('Generating sprites');
            for await (const progress of emojiImageData.generate()) {
                if (canceled) return;
                setProgress(progress); 
            }
            if (canceled) return;

            state.onLoadComplete(newMap, shouldLoad);
            setGameState(state);
            setMap(newMap);
            bus.addQuiet('welcome to fidgetmap', 5);
            newMap.animate(() => {
                bus.step()
            });
        })();
        return () => {
            canceled = true;
            if (newMap)
                newMap.playing = false;
        };
    }, [ref, store, mode, shouldLoad, giveCard]);

    return {
        ref,
        drawFull: useCallback(
            (ref: MutableRefObject<HTMLCanvasElement | null>) => {
                if (ref.current && map)
                    map.renderFull(ref.current);
            },
            [map]
        ),
        saveGame: useCallback((
            suppressStatus: boolean = false
        ) => gameState!.save(suppressStatus), [gameState]),
        flashCamera,
        ready: !!map,
        seed: gameState?.seed,
        setSkin: useCallback(
            (skin: Skin | undefined) => gameState?.player.setSkin(skin),
            [gameState]
        ),
        getSkin: useCallback(
            () => gameState?.player.getSkin(),
            [gameState]
        ),
        getEnabledSkins: useCallback(
            (): Array<Skin> => gameState?.player.enabledSkins || [],
            [gameState]
        ),
        generateStatus,
        progress,
        messages,
        status,
        share,
        sharing,
        huds,
    };
};

export const MapComponent: FC<{
    mode: GameMode;
    store: GameState['store'];
    returnToMenu: () => void;
    shouldLoad: boolean;
    giveCard: GiveCard;
    setCardPanelOpen: (open: boolean) => void;
}> = ({
    mode,
    store,
    returnToMenu,
    shouldLoad,
    giveCard,
    setCardPanelOpen,
}) => {
    const {
        ref,
        drawFull,
        ready,
        seed,
        progress,
        generateStatus,
        messages,
        status,
        share,
        sharing,
        setSkin,
        getSkin,
        getEnabledSkins,
        saveGame,
        flashCamera,
        huds,
    } = useMapApplication(store, mode, shouldLoad, giveCard);

    useEffect(() => {
        const handler = (event: BeforeUnloadEvent) => {
            event.preventDefault();
            return event.returnValue = 'are you sure you want to exit?';
        }

        window.addEventListener('beforeunload', handler);
        return () => window.removeEventListener('beforeunload', handler);
    }, []);
    // const postMessage = useWorker<number>(
    //     'test',
    //     useCallback((event) => {
    //         alert(JSON.stringify(event.data));
    //     }, [])
    // );
    // <button
    //     style={{ position: 'fixed', top: '0', left: '0', zIndex: '99999999999'}}
    //     type="button"
    //     onClick={() => postMessage({ now: Date.now()  })}
    // >
    //     Send to worker
    // </button>

    return <>
        <Messages data={messages} />
        <Layout>
            {!ready && (
                <WaitText>
                    <p>
                        {generateStatus}: {Math.ceil((progress[0] / progress[1]) * 100)}%
                    </p>
                </WaitText>
            )}
            <MaxSize show={ready}>
                <Canvas ref={ref} />
                {ready && <>
                    <ToolBar
                        flashCamera={flashCamera}
                        drawOverview={drawFull}
                        setCardPanelOpen={setCardPanelOpen}
                        status={status}
                        huds={huds}
                        saveGame={saveGame}
                        shareImage={share}
                        setSkin={setSkin}
                        getSkin={getSkin}
                        getEnabledSkins={getEnabledSkins}
                        sharing={sharing}
                        returnToMenu={returnToMenu}
                        seed={`${seed}`}
                    />
                </>}
            </MaxSize>
        </Layout>
    </>;
}
