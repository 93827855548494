import {
    Coord,
    Dim,
    loopRectangle,
    loopDisc,
    distance,
} from 'coords';
import { NoiseGrid } from 'noise';
import { ProgressGenerator } from 'generate';
import {
    START_ISLAND_MULTIPLIER,
} from 'game';

export class MapGrid {
    canvas: HTMLCanvasElement;
    noise: NoiseGrid;
    totalWidth: number;
    totalHeight: number;
    blockSize: number;

    constructor(
        canvas: HTMLCanvasElement,
        blockSize: number,
        totalDim: Dim,
        seed?: number,
    ) {
        this.canvas = canvas;
        this.blockSize = blockSize;

        this.totalWidth = totalDim[0];
        this.totalHeight = totalDim[1];
        this.noise = new NoiseGrid(
            this.totalWidth,
            this.totalHeight,
            50,
            seed
        );
    };

    async * generate(): ProgressGenerator {
        for await (const progress of this.noise.generate()) {
            yield progress;
        }
        this.fadeEdges();
        this.raiseCenter();
    };

    raiseCenter = () => {
        const size = Math.round(
            Math.max(
                this.viewWidth,
                this.viewHeight
            ) * START_ISLAND_MULTIPLIER
        );
        const halfSize = Math.round(size / 2);
        const center: Coord = [
            Math.round(this.totalWidth / 2),
            Math.round(this.totalHeight / 2),
        ];
        loopDisc(
            size,
            [
                Math.round(
                    (this.totalWidth / 2) - halfSize
                ),
                Math.round(
                    (this.totalHeight / 2) - halfSize
                ) 
            ]
        )(coord => {
            const dist = distance(center, coord);
            const value = this.noise.at(coord);
/*

(halfSize-dist)       newVal
----            = ---------
halfSize          (1-value)

*/
            this.noise.put(
                coord,
                value + ((1-value)*((halfSize-dist)/halfSize))
            );
        });
    };

    fadeEdges = () => {
        const scalePoint = (
            coord: Coord,
            distance: number,
            totalDistance: number
        ) => this.noise.put(
            coord,
            this.noise.at(coord) * ((1 / totalDistance)*distance)
        );
        const xThickness = Math.floor(
            this.totalWidth * .14
        );
        const yThickness = Math.floor(
            this.totalHeight * .14
        );
        loopRectangle(this.totalWidth, yThickness)(coord => {
            const [x, y] = coord;
            scalePoint(coord, y, yThickness);
            scalePoint([x, this.totalHeight-y], y, yThickness);
        });
        loopRectangle(xThickness, this.totalHeight)(coord => {
            const [x, y] = coord;
            scalePoint([x, y], x, xThickness);
            scalePoint([this.totalWidth-x, y], x, xThickness);
        });
    };

    get viewWidth() {
        return Math.ceil(this.canvas.width / this.blockSize);
    };

    get viewHeight() {
        return Math.ceil(this.canvas.height / this.blockSize);
    };

};
