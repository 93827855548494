import React, {
    FC,
    useCallback,
    useEffect,
    useRef,
    useState,
    Children
} from 'react';
import styled from '@emotion/styled';

const Wrap = styled.div`
    position: relative;
    padding: 0;
    margin: 0;
`;

type ScrollableProps = {
    itemMinWidth: string;
};
const Scrollable = styled.div<ScrollableProps>`
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    max-width: 100vw;
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    padding-bottom: 1em;

    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;

    scroll-snap-type: mandatory;
    scroll-snap-points-x: repeat(${props => props.itemMinWidth});
    scroll-snap-type: x mandatory;

    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        height: 0px;
        background: transparent;
    }
    ::-webkit-scrollbar-thumb {
        background: transparent;
    }
`;
type GalleryItemProps = {
    itemMinWidth: string;
    highlight: boolean;
};
const GalleryItem = styled.div<GalleryItemProps>`
    scroll-snap-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: ${props => props.itemMinWidth};
    box-sizing: border-box;
    position: relative;
    ${props => props.highlight ? `
        ::after {
            content: ' ';
            position: absolute;
            top: -10%;
            left: -10%;
            width: 120%;
            height: 120%;
            border-radius: 50%;
            filter: blur(10px);
            background-color: white;
            z-index: -1;
        }
    ` : ''}
`;
const GalleryDots = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    height: 1em;
    overflow-x: hidden;
`;
const Dot = styled.div<{ selected: boolean; count: number; }>`
    position: relative;
    width: ${props => (100/props.count) + '%'};
    display: flex;
    ::after {
        content: ' ';
        position: absolute;
        ${props => props.selected ? `
            width: 110%;
            height: 1em;
            left: -5%;
            top: -.5em;
            background-color: #ccc;
            z-index: 1;
            border-radius: 1em;
        ` : `
            width: 100%;
            height: .7em;
            left: 0;
            top: -.35em;
            background-color: #444;
            transition: 0.2s ease-in-out;
        `}
    }
`;

export const ScrollGallery: FC<{
    highlightItem?: boolean;
    itemMinWidth?: string;
    onIndexChange?: (index: number) => void;
}> = ({
    children,
    highlightItem,
    itemMinWidth = '100vw',
    onIndexChange,
}) => {
    const childsLength = Children.count(children);
    const scrollRef = useRef<HTMLDivElement>(null);
    const [scrollIndex, setScrollIndex] = useState<number>(0);

    const calculcateScrollIndex = useCallback(() => {
        if (!scrollRef.current) {
            setScrollIndex(0);
            return;
        }

        const percentScrolled = (
            scrollRef.current.scrollLeft / (
                scrollRef.current.scrollWidth - scrollRef.current.clientWidth
            )
        ) * 100;

        const newScrollIndex = Math.round(
            (percentScrolled / 100) * (childsLength - 1)
        );

        setScrollIndex(newScrollIndex);
        onIndexChange && onIndexChange(newScrollIndex);
        // console.log('scroll index', newScrollIndex);
    }, [childsLength, onIndexChange]);

    useEffect(() => {
        calculcateScrollIndex();
    }, [calculcateScrollIndex]);
    
    return (
        <Wrap>
            <Scrollable
                itemMinWidth={itemMinWidth}
                ref={scrollRef}
                onScroll={calculcateScrollIndex}
            >
                {Children.map(
                    children,
                    (child, i) =>
                        <GalleryItem
                            highlight={!!(highlightItem && scrollIndex === i)}
                            itemMinWidth={itemMinWidth}
                            key={i}
                        >
                            {child}
                        </GalleryItem>
                )}
            </Scrollable>
            <GalleryDots>
                {Children.map(
                    children,
                    (child, i) =>
                        <Dot
                            key={i}
                            selected={scrollIndex === i}
                            count={childsLength}
                        />
                )}
            </GalleryDots>
        </Wrap>
    );
};