import { MapApp } from 'maps';
import { ImageDataBuilder } from 'image';
import {
    Coord,
    gridToPolar,
    angle,
    distance,
} from 'coords';
import { theme } from 'color';
import { GameState, Throttler } from 'game';
import { GiveCard } from 'cards';

const LINE_START_DIST = 10;
const LINE_LENGTH = 8;

export const homePlugin = (state: GameState, giveCard: GiveCard) => (map: MapApp) => {
    const image = new ImageDataBuilder(
        map.renderWidth,
        map.renderHeight
    );
    const homeDist = Math.round(
        Math.max(
            map.grid.viewWidth,
            map.grid.viewHeight
        ) * 1.5
    );
    const lineDist = 30;
    // const halfLineDist = Math.round(lineDist * 0.5);
    const drawLine = (locCenter: Coord) => {
        const currentAngle = angle(
            locCenter,
            map.gps.totalCenter
        );
        const lineStart: Coord = gridToPolar(
            map.gps.viewCenter,
            LINE_START_DIST,
            currentAngle 
        );
        const lineEnd: Coord = gridToPolar(
            lineStart,
            LINE_LENGTH,
            currentAngle
        );

        image.line(
            lineStart,
            lineEnd,
            theme.home.line 
        );
    };
    const throttler = new Throttler(3 * 60);

    return {
        postRender: () => {
            image.clear();

            const locCenter = map.gps.locationCenter;

            const currentDist = distance(
                locCenter,
                map.gps.totalCenter
            );

            if (currentDist < homeDist) {
                // only draw it if it's almost onscreen so we're not drawing it always.
                // image.disc(
                //     [
                //         map.gps.totalCenter[0] - map.gps.location[0] - halfLineDist,
                //         map.gps.totalCenter[1] - map.gps.location[1] - halfLineDist,
                //     ],
                //     theme.home.zone,
                //     lineDist
                // );
                state.emojiImage.apply(
                    'home01',
                    image,
                    [
                        Math.floor(map.gps.totalCenter[0] - map.gps.location[0]),
                        Math.floor(map.gps.totalCenter[1] - map.gps.location[1]),
                    ],
                );
            }

            if (currentDist > lineDist) {
                drawLine(locCenter);
                if (state.status.values.atHome) {
                    if (!throttler.waits()) {
                        giveCard('cabins');
                    }
                }
                state.status.put('atHome', false);
            } else {
                state.status.put('atHome', true);
            }

            return image;
        },
    };
};

