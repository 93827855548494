export * from './GameState';
export * from './StatusState';

/**
 * this won't work across maps/pageloads, which is probably okay?
 * example usage:
 * 
 * class MyThing {
 *      throttle = new Throttler(5);
 *      collectOrRender() {
 *          if (this.throttle.waits()) {
 *              return;
 *          }
 *          // this will only run every 5 seconds
 *      }
 * }
 */
export class Throttler {
    msWait: number;
    lastActed: number = 0;
    constructor(seconds: number) {
        this.msWait = seconds * 1000;
    }
    waits = (): boolean => {
        const now: number = Date.now();
        const waiting: boolean = (
            now - this.lastActed < this.msWait
        );
        if (!waiting) {
            this.lastActed = now;
        }
        return waiting;
    }
};
