import React, {
    FC,
    useState,
    useCallback,
    useEffect,
    useRef,
    useMemo,
    MouseEventHandler,
} from 'react';
import styled from '@emotion/styled';
import {
    CardTheme,
    cardCount,
    useCardImageUrl,
    themeToTitle,
    themeToDescription,
    themeToCategory,
    categoryToColors,
    themeToThemeSymbol,
    // useCardImageId,
} from '..'; 
import {
    cardRarity,
    getRarityLabel,
    getRarityName,
    getRaritySymbol,
    RarityNames,
} from '../rarity';

const HideableImg = styled.img<{ hide: boolean; }>`
    opacity: ${props => props.hide ? '0.1' : '1'};
    background-color: ${props => props.hide ? 'black' : 'none'};
    width: 100%;
    height: auto;
    aspect-ratio: 1;
    border-radius: 0 .4em 0 .4em;
    display: block;
`;



const CardPaper = styled.article<{ borderColor: string; }>`
    height: 60vh;
    aspect-ratio: 2.5 / 3.5;
    display: flex;
    justify-content: flex-start;
    border-radius: 1em 0 1em 0;
    box-shadow: -10px 10px 0 -5px ${props => props.borderColor},
                10px -10px 0 -5px ${props => props.borderColor};
    padding: 0;
    box-sizing: border-box;
    flex-direction: column;
    font-family: 'BenchNine', sans-serif;
    font-weight: 300;
    position: relative;
    overflow-y: hidden;
    margin: 0 auto;

    background: linear-gradient(-8deg, #00000044, #00000022 8%, #00000000 60%),
                linear-gradient(180deg, #f4f1e0 20%, #e8e5d5 80%);
`;
const IconBar = styled.footer<{ borderColor: string; backgroundColor: string; }>`
    position: relative;
    width: 100%;
    border-bottom: 2px solid ${props => props.borderColor};
    background-color: ${props => props.backgroundColor};
    font-weight: 700;
    font-size: .8rem;
    z-index: 999;
    color: rgba(0, 0, 0, 0.7);
`;
const DeckDetails = styled.dl`
    display: flex;
    margin: 0;
`;
const DetailsLabel = styled.dt`
    display: none;
`;
const DetailsData = styled.dd<{ rarity?: RarityNames; justify?: string; }>`
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${props => props.justify || 'center'};
    flex: 1;
    margin: 0;
    padding: .1em 0;
    text-transform: capitalize;
    white-space: nowrap;
    position: relative;
    color: #222;
    > span {
        margin-left: 0.25em;
    }
    ${props => {
        const rare = props.rarity === 'rare';
        const epic = props.rarity === 'epic';
        if (!rare && !epic) {
            return '';
        }

        const background = epic
            ? '#555555aa, #222222aa'
            : '#66666699, #33333399';
        return `
            color: #ddd;
            ::before {
                content: ' ';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 120%;
                background: linear-gradient(0deg, ${background});
                clip-path: polygon(0 0, 100% 0, 100% 80%, 50% 100%, 0 80%);
                z-index: -1;
            }
        `;
    }}
`;
const CardSymbol = styled.div<{ theme: CardTheme; }>`
    font-family: OutlineEmoji;
    font-size: 1.2rem;
    color: #ffffffbb;
    font-weight: 100;
    margin-right: .25rem;
    margin-left: .45em;
    ::before {
        content: '${props => themeToThemeSymbol[props.theme as CardTheme]}';
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
        height: 2rem;
        margin: -.5rem 0;
        border-radius: 50%;
        border: 2px solid #f4f1e0;
        box-shadow: 0 0 4px 1px ${props =>
            categoryToColors[
                themeToCategory[props.theme as CardTheme]
            ].cardBorder
        };
        background: ${props =>
            categoryToColors[
                themeToCategory[props.theme as CardTheme]
            ].cardBorder
        };
    }
`;
const RarityIcon = styled.div`
    font-size: 1.6em;
    margin: -.4em .2em -.4em .2em;
`;
const Header = styled.header`
    padding: .5rem 0 0 0;
`;
const HeaderText = styled.h1`
    font-family: 'Elsie Swash Caps', cursive;
    margin: 0;
    text-align: center;
    text-transform: capitalize;
    color: #444;
`;
const Overlay = styled.div`
    position: relative;
    width: 100%;
    padding: .5rem 1.2rem 0rem 1.2rem;
    transition: .4s ease-in-out;
    z-index: 1;
    box-sizing: border-box;
`;
const ImgWrap = styled.div<{ borderColor: string; }>`
    overflow: hidden;
    border-radius: 0 1em;
    box-shadow: -10px -10px 0 -5px ${props => props.borderColor},
                10px 10px 0 -5px ${props => props.borderColor};
`;
const ThemeWrap = styled.main`
    color: #222;
`;
const CardInfo = styled.aside`
    position: absolute;
    font-size: .8rem;
    bottom: 0rem;
    right: 0;
    width: 100%;
    padding: 0 1rem;
    background: #fff;
    overflow-y: scroll;
    box-sizing: border-box;
`;
const CardInfoList = styled.dl`
    display: flex;
    flex-wrap: wrap;
`;
const CardInfoLabel = styled.dt`
    width: 48%;
    padding: 0;
    margin: 0;
    display: block;
    box-sizing: border-box;
    font-weight: 400;
    text-align: right;
    padding-right: .5rem;
`;
const CardInfoValue = styled.dd`
    padding: 0;
    margin: 0;
    display: block;
    box-sizing: border-box;
    padding-left: .5rem;
`;
const CardInfoCite = styled.cite`
    font-size: .7rem;
    padding: 0 1rem 1rem;
    display: block;
`;


export const Card: FC<{
    theme: CardTheme,
    index: number,
    onClick?: MouseEventHandler<HTMLDivElement>,
}> = ({
    theme,
    index,
    onClick,
}) => {
    const imgRef = useRef<HTMLImageElement>(null);
    const imgSrc = useCardImageUrl(theme, index);
    const [imgLoaded, setImgLoaded] = useState<boolean>(false);
    const rarity = useMemo(
        () => cardRarity(theme, index),
        [theme, index]
    );
    const rarityName = useMemo(
        () => getRarityName(theme, index),
        [theme, index]
    );

    useEffect(() => {
        const loaded: boolean = !!(imgRef.current && imgRef.current.complete);
        // console.log('%cIT CHANGED, IS THE IMAGE ALREADY LOADED? ' + loaded, 'font-size: 3em;')

        setImgLoaded(loaded);
    }, [imgSrc]);

    const category = themeToCategory[theme];
    const colors = categoryToColors[category];

    return (
        <CardPaper
            onClick={onClick}
            borderColor={colors.cardBorder}
        >
            <IconBar
                borderColor={colors.iconsBorder}
                backgroundColor={colors.iconsBg}
            >
                <DeckDetails>
                    <DetailsLabel>Theme and Count</DetailsLabel>
                    <DetailsData justify="flex-start">
                        <CardSymbol theme={theme} />
                        {index + 1}/{cardCount(theme)}
                    </DetailsData>
                    <DetailsLabel>Rarity</DetailsLabel>
                    <DetailsData rarity={rarityName}>
                        {getRarityLabel(theme, index)}
                        <RarityIcon>{getRaritySymbol(theme, index)}</RarityIcon>
                        {rarity}
                    </DetailsData>
                    <DetailsLabel>Category</DetailsLabel>
                    <DetailsData>{category}</DetailsData>
                </DeckDetails>          
            </IconBar>
            <Header>
                <HeaderText>
                    {themeToTitle[theme]}
                </HeaderText>
            </Header>
            <Overlay>
                <ImgWrap borderColor={colors.imgBorder}>
                    <HideableImg
                        hide={!imgLoaded}
                        src={imgSrc}
                        alt='AI generated in DALL-E mini'
                        onLoad={useCallback(() => {
                            // console.log('%cCALLED ONLOAD CALLBACK', 'font-size: 3em;')
                            setImgLoaded(true);
                        }, [])}
                        ref={imgRef}
                    />
                </ImgWrap>
                <ThemeWrap>
                    <p>{themeToDescription[theme]}</p>
                </ThemeWrap>
            </Overlay>
            {false && (
                <CardInfo>
                    <CardInfoList>
                        <CardInfoLabel>Card Collected Date</CardInfoLabel>
                        <CardInfoValue>15 August 2022</CardInfoValue>
                        <CardInfoLabel>Seed Card Collected From</CardInfoLabel>
                        <CardInfoValue>1656201600000</CardInfoValue>
                        <CardInfoLabel>Card Release Date</CardInfoLabel>
                        <CardInfoValue>July 2022</CardInfoValue>
                    </CardInfoList>
                    <CardInfoCite>{`@misc{Dayma_DALL·E_Mini_2021,
                        author = {Dayma, Boris and Patil, Suraj and Cuenca, Pedro and Saifullah, Khalid and Abraham, Tanishq and Lê Khắc, Phúc and Melas, Luke and Ghosh, Ritobrata},
                        doi = {10.5281/zenodo.5146400},
                        month = {7},
                        title = {DALL·E Mini},
                        url = {https://github.com/borisdayma/dalle-mini},
                        year = {2021}
                    }`}</CardInfoCite>
                </CardInfo>
            )}
            {/*
            <input
                type="text"
                readOnly
                value={useCardImageId(theme, index)}
                onFocus={(e) => e.target.select()}
            />
            */}
        </CardPaper>
    );
};

