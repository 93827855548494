import React, {
    FC,
    useCallback,
    useEffect,
} from 'react';
import styled from '@emotion/styled';
import {
    cardImageUrls,
    CardTheme,
    categoryToColors,
    themes,
    themeToCategory,
    themeToThemeName,
    themeToThemeSymbol,
} from '..';
import { FullHeight } from 'components';
import { CardList } from './CardList';
import { AddedCards } from './AddedCards';
import { CardStateApi } from '../CardState';
import { BubbleCloseButton } from 'components';
import { ImportExport } from './ImportExport';

type ContainerProps = {
    open: boolean;
};

const Container = styled(FullHeight)<ContainerProps>`
    position: fixed;
    z-index: 99999999990;
    top: 0;
    transition: left ease .5s;
    left: ${props => props.open ? '0' : '-100vw'};
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
    box-sizing: border-box;
    padding-bottom: 3rem;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    /* justify-content: center; // breaks overflow */
    ::before, ::after {
        content: '';
        margin: auto; /* Make it push flex items to the center */
    }
`;
const Backdrop = styled.div`
    background-color: rgba(0, 0, 0, .5);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    z-index: -1;
`;

type FlexProps = {
    flexdir?: 'row' | 'column';
    align?: string;
    justify?: string;
    flex?: string;
};
const Flex = styled.div<FlexProps>`
    display: flex;
    flex: ${props => props.flex || '1'};
    flex-direction: ${props => props.flexdir || 'row'};
    align-items: ${props => props.align || 'center'};
    justify-content: ${props => props.justify || 'center'};
    flex-wrap: wrap;
`;
const ThemeButtons = styled(Flex)`
    width: 400px;
    width: min(400px, 100vw);
    min-width: 300px;
    max-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    align-items: flex-start;
`;
const ThemeButton = styled.button<{ theme: CardTheme }>`
    min-width: 33%;
    font-size: 1.2rem;
    padding: .5rem 0 .7rem 0;
    text-align: center;
    font-family: 'Elsie Swash Caps', cursive;
    background: linear-gradient(-8deg, #00000044, #00000022 8%, #00000000 60%),
        linear-gradient(180deg, #f4f1e0 20%, #e8e5d5 90%, ${props =>
            categoryToColors[
                themeToCategory[props.theme as CardTheme]
            ].cardBorder
        } 95%);
    ::before {
        content: '${props => themeToThemeSymbol[props.theme as CardTheme]}';
        font-family: OutlineEmoji;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: .2rem auto;
        box-sizing: border-box;
        font-size: 2rem;
        width: 3.2rem;
        height: 3.2rem;
        background: linear-gradient(180deg, ${props => {
            const colors = categoryToColors[
                themeToCategory[props.theme as CardTheme]
            ];

            return colors.iconsBg + ' 0%, ' + colors.cardBorder + ' 100%';
        }});
        color: #FFFFFFBB;
        border-radius: 50%;
    }
    > small {
        display: block;
        font-size: 1rem;
        margin-left: 0.4rem;
        font-weight: 600;
        color: #00000077;
        font-family: 'BenchNine', sans-serif;
    }
`;

export const CardsLayout: FC<CardStateApi> = ({
    loaded,
    showButton,
    hasAnyInTheme,
    hasCard,
    theme,
    setTheme,
    open,
    setOpen,
    index,
    setIndex,
    themeCardCount,
    added,
    showCard,
    importJson,
    exportJson,
}) => {
    const closeLayout = useCallback(
        () => setOpen(false),
        [setOpen]
    );

    const themeButtons = themes.map(theme => (
        hasAnyInTheme(theme) ? (
            <ThemeButton
                onClick={() => setTheme(theme)}
                key={theme}
                theme={theme}
            >
                <small>{themeCardCount(theme)} of {cardImageUrls[theme as CardTheme].length}</small>
                {themeToThemeName[theme]}
            </ThemeButton>
        ) : null
    )).filter(i => !!i);

    useEffect(() => {
        if (!loaded || !showButton) {
            setOpen(false);
        }
    }, [loaded, showButton, setOpen]);

    if (!loaded || !showButton) {
        return null;
    }

    return <>
        <Container open={open}>
            {theme ? (
                <CardList
                    theme={theme}
                    setTheme={setTheme}
                    hasCard={hasCard}
                    hasAnyInTheme={hasAnyInTheme}
                    index={index}
                    setIndex={setIndex}
                    themeCardCount={themeCardCount}
                />
            ) : (
                <Flex flexdir="column">
                    <ImportExport
                        importJson={importJson}
                        exportJson={exportJson}
                    >
                        <h2>Your Cards</h2>
                    </ImportExport>
                    <ThemeButtons flex="initial">
                        {themeButtons}
                    </ThemeButtons>
                </Flex>
            )}
            <Backdrop />
            <BubbleCloseButton onClick={closeLayout} />
        </Container>
        <AddedCards
            added={added}
            showCard={showCard}
        />
    </>;
};
