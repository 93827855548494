type Timestamp = number;
type Seconds = number;

export type Message = {
    text: string;
    expires: {
        created: Timestamp;
        limit: Seconds;
    };
};

type Subscriber = {
    loud: (text: string) => void,
    quiet: (
        messages: Message[]
    ) => void,
};

export class Bus {
    private _messages: Message[] = [];
    private _sub: Subscriber;

    constructor(sub: Subscriber) {
        this._sub = sub;
    }

    // // didn't need it after adding Subscriber
    // get messages() {
    //     return this._messages.map(
    //         message => ({ ...message })
    //     );
    // }

    step = () => {
        const startLength = this._messages.length;
        if (!startLength) {
            return;
        }
        const now = Date.now();
        this._messages = this._messages.filter(
            message => {
                const duration = (
                    now - message.expires.created
                ) / 1000;

                return duration < message.expires.limit;
            }
        );
        if (this._messages.length !== startLength) {
            this._sub.quiet(this._messages);
        }
    };

    addQuiet = (
        text: string,
        expires: Seconds,
    ) => {
        const message: Message = {
            text,
            expires: {
                created: Date.now(),
                limit: expires,
            },
        };

        this._messages = [
            ...this._messages
                .filter(message => message.text !== text),
            message,
        ];
        this._sub.quiet(this._messages);
    };

    loud = (text: string) => {
        this._sub.loud(text);
    };

    // // this is overengineered for now.
    // // eliminate looping.
    // private _subscribers: Subscriber[] = []; 
    // subscribe = (subscriber: Subscriber) => {
    //     this._subscribers = [
    //         ...this._subscribers,
    //         subscriber,
    //     ];
    // };

};

