import { MapApp } from 'maps';
import {
    Coord,
    coordToIndex,
    loopDisc,
    distance,
    // gridToPolar,
} from 'coords';
import { ItemState, Item, ItemSerialized } from 'plugins/items';
import { theme } from 'color';
import { Bus } from 'message/Bus';
import { StatusState } from 'game';
import { EmojiStringImageEncoder } from 'image';
import { GiveCard } from 'cards';

export type FogStateSerialized = {
    fadeDistance: number;
    cutout: {
        diameter: number;
        radius: number;
    };
    data: string;
    items: ItemSerialized[];
};

// class CoordSet {
//     private s: Set<string>;
//     constructor() {
//         this.s = new Set<string>();
//     }
//     add = (coord: Coord) => this.s.add(coord.join(','));
//     has = (coord: Coord) => this.s.has(coord.join(','));
// }

const serializeFog = {
    encode: (data: Uint8ClampedArray): string => {
        const encoded = [];
        /*
            this ends up being an array of
                [
                    countOfNextValue: number, fogValue: number,
                    countOfNextValue: number, fogValue: number,
                    ...
                ]
        */

        let previousIndexChange = 0;

        for (let i = 1; i <= data.length; i++) {
            // if (i === data.length) {
            //     console.log('MADE IT ALL THE WAY WITH THIS COMPARATOR');
            // }
            if (data[i] !== data[previousIndexChange] || i === data.length) {
                encoded.push(i - previousIndexChange);
                encoded.push(data[previousIndexChange]);
                previousIndexChange = i;
            }
        }
        // console.log('pre-encoding length: ', data.length);
        return JSON.stringify(encoded);
    },
    decode: (data: string): Uint8ClampedArray => {
        const encoded: number[] = JSON.parse(data);

        let totalLength = 0;
        for (let i = 0; i < encoded.length; i+=2) {
            totalLength += encoded[i];
        }

        let decoded = new Uint8ClampedArray(totalLength);

        let currentIndex = 0;
        for (let i = 0; i < encoded.length; i+=2) {
            for (let j = 0; j < encoded[i]; j++) {
                decoded[currentIndex + j] = encoded[i+1];
            }
            currentIndex += encoded[i];
        }
        // console.log('decoded length: ', decoded.length);

        return decoded;
    },
};

export class FogState {
    fadeDistance: number = 20;
    cutout: {
        diameter: number;
        radius: number;
    } = { diameter: 0, radius: 0 };
    // values must be integers between 0 - 255 
    data: Uint8ClampedArray;

    items: ItemState;
    bus: Bus;
    status: StatusState;
    emojiImage: EmojiStringImageEncoder;
    giveCard: GiveCard;
    flashlight: Item | undefined;
    camera: Item | undefined;
    constructor(
        items: ItemState,
        bus: Bus,
        status: StatusState,
        emojiImage: EmojiStringImageEncoder,
        giveCard: GiveCard,
    ) {
        this.items = items;
        this.bus = bus;
        this.status = status;
        this.emojiImage = emojiImage;
        this.data = new Uint8ClampedArray();
        this.giveCard = giveCard;

        const diameter = 50;
        const radius = Math.floor(diameter / 2);
    
        this.cutout = { diameter, radius };
    }

    load(serialized: FogStateSerialized) {
        this.fadeDistance = serialized.fadeDistance;
        this.cutout = serialized.cutout;
        this.data = serializeFog.decode(serialized.data);

        const handlers = {
            'candle': this.onCollectCandle,
            'flashlight': this.onCollectFlashlight,
            'camera': this.onCollectCamera,
            'lantern': this.onCollectLantern,
        };
        const renders = {
            'candle': this.emojiImage.makeApply('candle'),
            'flashlight': this.emojiImage.makeApply('flashlight'),
            'camera': this.emojiImage.makeApply('camera'),
            'lantern': this.emojiImage.makeApply('lantern'),
        };

        this.items.addFactory(this, map =>
            serialized.items.map(itemSerialized =>
                Item.load(
                    itemSerialized,
                    // @ts-ignore
                    handlers[itemSerialized.config.name],
                    // @ts-ignore
                    renders[itemSerialized.config.name],
                )
            )
        );
    }

    initialize() {
        this.items.addFactory(this, map => {
            const itemMinMax = map.gps.generateFromCenter();

            this.flashlight = new Item({
                name: 'flashlight',
                ...itemMinMax(20),
                conceal: true,
                minDist: 100,
                minLevel: 3,
                color: theme.player.core,
                showOnFullRender: true,
                onCollect: this.onCollectFlashlight,
                customRender: this.emojiImage.makeApply('flashlight'),
            });
            this.camera = new Item({
                name: 'camera',
                ...itemMinMax(20),
                conceal: true,
                minDist: 100,
                minLevel: 3,
                color: theme.player.core,
                showOnFullRender: true,
                onCollect: this.onCollectCamera,
                customRender: this.emojiImage.makeApply('camera'),
            });

            return [
                new Item({
                    name: 'candle',
                    ...itemMinMax(6),
                    minDist: 25,
                    minLevel: 3,
                    color: theme.player.core,
                    showOnFullRender: true,
                    onCollect: this.onCollectCandle,
                    customRender: this.emojiImage.makeApply('candle'),
                }),
                this.flashlight,
                this.camera,
                new Item({
                    name: 'lantern',
                    ...itemMinMax(6),
                    minDist: 30,
                    minLevel: 3,
                    showOnFullRender: true,
                    color: theme.player.core,
                    onCollect: this.onCollectLantern,
                    customRender: this.emojiImage.makeApply('lantern'),
                }),
                ...new Array(3).fill(null).map(() =>
                    new Item({
                        name: 'lantern',
                        ...itemMinMax(90),
                        minDist: 400,
                        minLevel: 3,
                        showOnFullRender: true,
                        color: theme.player.core,
                        onCollect: this.onCollectLantern,
                        customRender: this.emojiImage.makeApply('lantern'),
                    })
                ),
            ];
        });
    };

    onCollectLantern = (map: MapApp, lantern: Item) => {
        if (lantern.found) {
            // this lantern already used!
            return true;
        }
        this.bus.addQuiet('the lantern illuminates the area', 10);
        this.cutoutFromPlayer(map, 2);
        return true;
    };

    onCollectCandle = () => {
        const diameter = 60;
        const radius = Math.floor(diameter / 2);
        this.status.put('toolCandle', true);

        this.flashlight!.config.conceal = false;
        this.camera!.config.conceal = false;
        
        this.giveCard('candle');
    
        this.cutout = { diameter, radius };
    };

    onCollectFlashlight = () => {
        this.status.put('toolFlashlight', true);
        this.giveCard('flashlight');
        const diameter = 80;
        const radius = Math.floor(diameter / 2);
    
        this.cutout = { diameter, radius };
        this.fadeDistance = 10;
    };

    onCollectCamera = () => {
        this.status.put('toolCamera', true);
    };

    cutoutDisc = (
        totalWidth: number,
        cutCoord: Coord,
        diameter?: number,
        fadeDistance?: number,
    ) => {
        const _fadeDistance: number = typeof fadeDistance === 'number'
            ? fadeDistance
            : this.fadeDistance;
        const radius = diameter
            ? Math.round(diameter / 2)
            : this.cutout.radius;
        const cutCenter: Coord = [
            cutCoord[0] + radius,
            cutCoord[1] + radius,
        ];
        loopDisc(
            diameter || this.cutout.diameter,
            cutCoord  
        )(coord => {
            const fromEdge = radius - distance(
                coord,
                cutCenter
            );
            const index = coordToIndex(totalWidth, coord);
            const newValue = fromEdge >= _fadeDistance
                ? 255
                : (255 * (fromEdge/_fadeDistance));
            if (newValue > this.data[index]) {
                this.data[index] = newValue;
            }
        });
    };

//     findChunksFromPlayer = (map: MapApp) => {
//         const locCenter = map.gps.locationCenter;
//         let currentAngle = 0;
//         let finished = false;
//         let checked: CoordSet = new CoordSet();
//         let resumes: CoordSet = new CoordSet();
//         let polysToRemove: CoordSet[] = [];
//         let currentPoly: CoordSet | undefined;

//         let currentCoord: Coord = locCenter;
//         const setCurrentFromPlayer = () => (
//             currentCoord = gridToPolar(
//                 locCenter,
//                 this.cutout.radius + 3,
//                 currentAngle
//             )
//         );
//         setCurrentFromPlayer();
//         const leftOfCurrent = (): Coord | undefined => {
//             const x = currentCoord[0] - 1;

//             if (x < 0)
//                 return undefined;

//             return [
//                 x,
//                 currentCoord[1]
//             ];
//         };
//         const upOfCurrent = (): Coord | undefined => {
//             const y = currentCoord[1] - 1;

//             if (y < 0)
//                 return undefined;

//             return [
//                 currentCoord[0],
//                 y
//             ];
//         };

//         const iterate = () => {
//             finished = currentAngle > 360;
//             if (finished) return;

//             currentPoly = undefined;
//             currentAngle += 3;
//             setCurrentFromPlayer()
//         };
//         while (!finished) {
//             const index = coordToIndex(
//                 map.grid.totalWidth,
//                 currentCoord
//             );
//             const fogValue = this.data[index];
//             checked.add(currentCoord); 

//             if (!fogValue) {
//                 currentPoly = undefined;
//             } else {
//                 currentPoly = currentPoly || new CoordSet();
//                 currentPoly.add(currentCoord);
//                 if (distance(locCenter, currentCoord) > 50) {
//                     iterate();
//                     continue;
//                 }
//                 /*
// if left is within the map:
//     if left has not been checked:
//         resumes.add(up).add(right).add(down)
//         set currentCoord to left
//         continue
//     else:
//         // left has been checked
//         if left is not in currentPoly:
//             if left is not clear:
//                 iterate()
//                 continue
//             else:
//                 // left is already checked, clear, not in poly. go up.
//         else:
//             // left is already checked, in poly. go up.
// else:
//     // left not within map. go up.
//                 */
//                 // ditto up... 
//                 // ditto right...
//                 // ditto down...
// /*
// if has resumes:
//     resume = resumes[0]
//     if resume has not been checked:
//         set currentCoord to resume
//         resumes.remove(resume)
//         continue
//     else
// */
//             } 

//             iterate();
//         }
//         // cutout polys
//     }

    cutoutFromPlayer = (map: MapApp, viewMultiplier: number) => {
        const cutSize = Math.floor(
            Math.min(
                map.grid.viewWidth,
                map.grid.viewHeight
            ) * viewMultiplier
        );
        const locCenter = map.gps.locationCenter;
        this.cutoutDisc(
            map.grid.totalWidth,
            [
                Math.round(
                    locCenter[0] - (cutSize / 2)
                ),
                Math.round(
                    locCenter[1] - (cutSize / 2)
                ),
            ],
            cutSize,
            Math.round(cutSize * .45),
        );
    };

    toJSON(): FogStateSerialized {
        return {
            fadeDistance: this.fadeDistance,
            cutout: this.cutout,
            data: serializeFog.encode(this.data),
            items: this.items.selectMine(this)?.map(item => item.toJSON()),
        };
    };

};

