import { Coord, Line } from './types';

export const coordToIndex = (
    width: number, 
    [x, y]: Coord
) => (y * width) + x;

export const indexToCoord = (
    width: number,
    i: number
): Coord => [
    Math.floor(i % width),
    Math.floor(i / width)
];

export const distFromCoordMinMax = (coord: Coord, dist: number): {
    min: Coord;
    max: Coord;
} => ({
    min: [
        coord[0] - dist,
        coord[1] - dist,
    ],
    max: [
        coord[0] + dist,
        coord[1] + dist,
    ]
});

export const distance = (
    coordA: Coord,
    coordB: Coord
) => Math.sqrt(
    Math.pow(
        Math.abs(coordA[0] - coordB[0]),
        2
    ) + Math.pow(
        Math.abs(coordA[1] - coordB[1]),
        2
    )
);
 
export const angle = (
    [cx, cy]: Coord,
    [ex, ey]: Coord
) => {
    const dy = ey - cy;
    const dx = ex - cx;
    let theta = Math.atan2(dy, dx);
    theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
    // if (theta < 0) theta = 360 + theta; // range [0, 360)
    return theta;
};

export const gridToPolar = (
    from: Coord,
    dist: number,
    angleDeg: number
): Coord => {
    const angleRad = angleDeg / 180 * Math.PI;
    return [
        from[0] + Math.round(dist * Math.cos(angleRad)),
        from[1] + Math.round(dist * Math.sin(angleRad)),
    ];
};

export const loopRectangle = (
    width: number,
    height: number,
    [startX, startY]: Coord = [0, 0],
) => (
    fn: ((coord: Coord) => void)
) => {
    const total = width * height;
    for (let i = 0; i < total; i++) {
        const coord: Coord = [
            startX + (i % width),
            startY + Math.floor(i / width)
        ];
        fn(coord);
    }
};

export const loopDisc = (
    width: number,
    startCoord: Coord = [0, 0],
) => (
    fn: ((coord: Coord) => void)
) => {
    const radius = width / 2;
    const mid: Coord = [
        startCoord[0] + radius,
        startCoord[1] + radius,
    ];
    loopRectangle(
        width,
        width, 
        startCoord
    )(coord => {
        if (distance(mid, coord) < radius) {
            fn(coord);
        }
    });
};

// // https://en.wikipedia.org/wiki/Distance_from_a_point_to_a_line#Line_defined_by_two_points
// export const distanceFromLine = (point: Coord, line: Line): number => {
//     const [x0, y0] = point;
//     const [
//         [x1, y1],
//         [x2, y2],
//     ] = line;

//     return Math.abs(
//         ((x2-x1)*(y1-y0)-(x1-x0)*(y2-y1)) / Math.sqrt(Math.pow(x2-x1, 2) + Math.pow(y2-y1, 2))
//     );
// };


// https://stackoverflow.com/a/6853926/397122
export const distanceFromLine = ([x, y]: Coord, [[x1, y1], [x2, y2]]: Line): number => {
    const A = x - x1;
    const B = y - y1;
    const C = x2 - x1;
    const D = y2 - y1;
  
    const dot = A * C + B * D;
    const len_sq = C * C + D * D;
    let param = -1;
    if (len_sq !== 0) //in case of 0 length line
        param = dot / len_sq;
  
    let xx, yy;
  
    if (param < 0) {
      xx = x1;
      yy = y1;
    }
    else if (param > 1) {
      xx = x2;
      yy = y2;
    }
    else {
      xx = x1 + param * C;
      yy = y1 + param * D;
    }
  
    const dx = x - xx;
    const dy = y - yy;
    return Math.sqrt(dx * dx + dy * dy);
};

export class CoordCache<T> {
    private cache: T[][] = [];
    get = ([x, y]: Coord): T | undefined =>
        this.cache[x] && this.cache[x][y];
    set = ([x, y]: Coord, value: T): T => {
        if (!this.cache[x])
            this.cache[x] = [];
        this.cache[x][y] = value;
        return value;
    };
};

// // line intercept math by Paul Bourke http://paulbourke.net/geometry/pointlineplane/
// // https://stackoverflow.com/a/60368757/397122
// export const lineIntersect = (
//     [[x1, y1], [x2, y2]]: Line,
//     [[x3, y3], [x4, y4]]: Line
// ): Coord | false => {
//     // Check if none of the lines are of length 0
//     if ((x1 === x2 && y1 === y2) || (x3 === x4 && y3 === y4)) {
//         return false
//     }
  
//     let denominator = ((y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1))
  
//     // Lines are parallel
//     if (denominator === 0) {
//         return false
//     }
  
//     let ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator;
//     let ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator;
  
//     // is the intersection along the segments
//     if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
//         return false;
//     }
  
//     // Return a object with the x and y coordinates of the intersection
//     let x = x1 + ua * (x2 - x1);
//     let y = y1 + ua * (y2 - y1);

//     return [x, y];
// };
