import React, { FC, useMemo } from 'react';
import styled from '@emotion/styled';
import { HudRecord, Hud } from './types';
import { EmojiText } from 'image';

const HudWrap = styled.div<{ status?: Hud['data']['status']; }>`
    background-color: #FFFFFF99;
    padding: 0.3rem 0.7rem;
    margin: 0.3rem;
    border-radius: 0.2rem;
    box-shadow: 0 1px 2px 1px #00000066;
    color: black;
    display: flex;
    flex-direction: row;
    ${props => {
        if (props.status === 'completed') {
            return `
                ::before {
                    content: '✅';
                    font-family: OutlineEmoji;
                    font-size: 2rem;
                    margin-left: -.4rem;
                    margin-right: .4rem;
                    text-align: center;
                    color: #66666688;
                }
                opacity: .6;
                > p {
                    color: #222222dd;
                    text-decoration: line-through black;
                }
            `;
        }
        return '';
    }}

    > p {
        margin: 0;
        padding: 0;
        flex: 1;
    }
`;
const Items = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
`;
const Item = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const QuestHud: FC<{
    hud: Hud;
    className?: string;
}> = ({
    hud,
    className,
}) => (
    <HudWrap
        className={className}
        status={hud.data.status}
    >
        <p>
            {hud.data.emojis && (
                <EmojiText>{hud.data.emojis}</EmojiText>
            )}
            {hud.data.content}
        </p>
        {hud.data.items && (
            <Items>
                {hud.data.items?.map(item =>
                    <Item key={item.symbol}>
                        <EmojiText>{item.symbol}</EmojiText>
                        <div>
                            {item.current}{item.total && `/${item.total}`}
                        </div>
                    </Item>
                )}
            </Items>
        )}
    </HudWrap>
);

const hudStatusValue = (hud: Hud) => hud.data.status === 'completed' ? 1 : 0;

export const QuestHuds: FC<{
    huds: HudRecord
}> = ({
    huds,
}) => {
    const hudIds = useMemo(() => Object.keys(huds).sort((hudKeyA, hudKeyB) =>
        hudStatusValue(huds[hudKeyB]) - hudStatusValue(huds[hudKeyA])
    ), [huds]);

    return <>
        {hudIds.map(hudId => (
            <QuestHud
                key={hudId}
                hud={huds[hudId]}
            />
        ))}
    </>;
}
