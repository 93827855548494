import { Color } from './types';
import { hexToColor } from './convert';


export const transparent = hexToColor('FFFFFF00');

export type Theme = {
    transparent: typeof transparent,
    fog: Color,
    levels: {
        water: {
            deep: Color,
            medium: Color,
            shallow: Color,
        },
        land: {
            beach: Color,
            plain: Color,
            hills: Color,
        },
    },
    home: {
        zone: Color,
        line: Color,
    },
    woods: {
        light: Color,
        deep: Color,
    },
    player: {
        core: Color,
        outline: Color,
    },
    joystick: {
        anchor: Color,
        thumb: Color,
    },
    scenes: {
        marble: Color,
        sample: Color,
        baddie: Color,
        road: Color,
        bridge: Color,
    },
};

export const themeBlank: Theme = {
    transparent,
    fog: hexToColor('000000'),
    levels: {
        water: {
            deep: hexToColor('000000'),
            medium: hexToColor('000000'),
            shallow: hexToColor('000000'),
        },
        land: {
            beach: hexToColor('000000'),
            plain: hexToColor('000000'),
            hills: hexToColor('000000'),
        },
    },
    home: {
        zone: hexToColor('000000'),
        line: hexToColor('000000'),
    },
    woods: {
        light: hexToColor('000000'),
        deep: hexToColor('000000'),
    },
    player: {
        core: hexToColor('000000'),
        outline: hexToColor('000000'),
    },
    joystick: {
        anchor: hexToColor('000000'),
        thumb: hexToColor('000000'),
    },
    scenes: {
        marble: hexToColor('000000'),
        sample: hexToColor('000000'),
        baddie: hexToColor('000000'),
        road: hexToColor('000000'),
        bridge: hexToColor('000000'),
    },
};

export const themeOriginal: Theme = {
    transparent,
    fog: hexToColor('000033'),
    levels: {
        water: {
            deep: hexToColor('328BBF'),
            medium: hexToColor('389ED9'),
            shallow: hexToColor('41B8FC'),
        },
        land: {
            beach: hexToColor('EDCB74'),
            plain: hexToColor('D9B96A'),
            hills: hexToColor('C4A860'),
        },
    },
    home: {
        zone: hexToColor('6A20A866'),
        line: hexToColor('6A20A8CC'),
    },
    woods: {
        light: hexToColor('44AA44'),
        deep: hexToColor('229922'),
    },
    player: {
        core: hexToColor('FFFFFF'),
        outline: hexToColor('AA00AA'),
    },
    joystick: {
        anchor: hexToColor('99009966'),
        thumb: hexToColor('FFFFFF66'),
    },
    scenes: {
        marble: hexToColor('A32714'),
        sample: hexToColor('FFAAAA'),
        baddie: hexToColor('FFFF00'),
        road: hexToColor('808076'),
        bridge: hexToColor('73736a'),
    },
};

export const themeWaterBased01: Theme = {
    transparent,
    fog: hexToColor('333333'),
    levels: {
        water: {
            deep: hexToColor('196770'),
            medium: hexToColor('2AAEBD'),
            shallow: hexToColor('38E9FC'),
        },
        land: {
            beach: hexToColor('BD9A4F'),
            plain: hexToColor('886F39'),
            hills: hexToColor('70500D'),
        },
    },
    home: {
        zone: hexToColor('1449A366'),
        line: hexToColor('1D6AF0CC'),
    },
    woods: {
        light: hexToColor('157D4D'),
        deep: hexToColor('11633D'),
    },
    player: {
        core: hexToColor('FFFFFF'),
        outline: hexToColor('70244D'),
    },
    joystick: {
        anchor: hexToColor('99009966'),
        thumb: hexToColor('FFFFFF66'),
    },
    scenes: {
        marble: hexToColor('A32714'),
        sample: hexToColor('FFAAAA'),
        baddie: hexToColor('FFFF00'),
        road: hexToColor('808076'),
        bridge: hexToColor('73736a'),
    },
};

export const themePlainBased01: Theme = {
    transparent,
    fog: hexToColor('333333'),
    levels: {
        water: {
            deep: hexToColor('4F889C'),
            medium: hexToColor('63AAC2'),
            shallow: hexToColor('70C0DB'),
        },
        land: {
            beach: hexToColor('E1D0A0'),
            plain: hexToColor('DBB95A'),
            hills: hexToColor('A88E45'),
        },
    },
    home: {
        zone: hexToColor('1E378F66'),
        line: hexToColor('5A77DBCC'),
    },
    woods: {
        light: hexToColor('70DBBC'),
        deep: hexToColor('4F9C86'),
    },
    player: {
        core: hexToColor('FFFFFF'),
        outline: hexToColor('C644DB'),
    },
    joystick: {
        anchor: hexToColor('7444DB66'),
        thumb: hexToColor('FFFFFF66'),
    },
    scenes: {
        marble: hexToColor('A32714'),
        sample: hexToColor('FFAAAA'),
        baddie: hexToColor('FFFF00'),
        road: hexToColor('808076'),
        bridge: hexToColor('73736a'),
    },
};

export const themeWoodsBased01: Theme = {
    transparent,
    fog: hexToColor('333333'),
    levels: {
        water: {
            deep: hexToColor('13ADA1'),
            medium: hexToColor('16C7B8'),
            shallow: hexToColor('17D4C4'),
        },
        land: {
            beach: hexToColor('A1861A'),
            plain: hexToColor('947C18'),
            hills: hexToColor('7A6614'),
        },
    },
    home: {
        zone: hexToColor('2E309466'),
        line: hexToColor('4648E0CC'),
    },
    woods: {
        light: hexToColor('22A14E'),
        deep: hexToColor('1F9447'),
    },
    player: {
        core: hexToColor('FFFFFF'),
        outline: hexToColor('942E88'),
    },
    joystick: {
        anchor: hexToColor('E05A1966'),
        thumb: hexToColor('FFFFFF66'),
    },
    scenes: {
        marble: hexToColor('A32714'),
        sample: hexToColor('FFAAAA'),
        baddie: hexToColor('FFFF00'),
        road: hexToColor('808076'),
        bridge: hexToColor('73736a'),
    },
};

export const themeMixedBasis01: Theme = {
    transparent,
    fog: hexToColor('333333'),
    levels: {
        water: themePlainBased01.levels.water,
        land: {
            ...themePlainBased01.levels.land,
            beach: hexToColor('F5CE64'),
            hills: hexToColor('C2A34F'),
        },
    },
    home: themeWoodsBased01.home,
    woods: themeWoodsBased01.woods,
    player: {
        ...themePlainBased01.player,
        outline: hexToColor('555555'),
    },
    joystick: themePlainBased01.joystick,
    scenes: {
        marble: hexToColor('A32714'),
        sample: hexToColor('00CC00'), // border 02ab45, fill 7ddb8d
        baddie: hexToColor('975ba8'), // top b572c2, bottom 975ba8
        road: hexToColor('808076'),
        bridge: hexToColor('73736a'),
    },
};

export const theme = themeMixedBasis01;

