import { HudData, hudDatasAreEqual, HudRecord, SetHud } from "./types";

export type HudStateSubscriber = (huds: HudRecord) => void;

export class QuestHudState {
    huds: HudRecord;
    subscriber: HudStateSubscriber;

    constructor(
        subscriber: HudStateSubscriber
    ) {
        this.huds = {};
        this.subscriber = subscriber;
    }

    private setData = (huds: HudRecord) => {
        this.huds = Object.freeze(huds);
        this.subscriber(this.huds);
    }

    setHud: SetHud = (
        id: string,
        data: HudData | undefined
    ) => {
        if (data) {
            if (!this.huds[id] || !hudDatasAreEqual(data, this.huds[id].data)) {
                this.setData({
                    ...this.huds,
                    [id]: { id, data },
                });
            }
        } else {
            if (this.huds[id]) {
                const {
                    [id]: _,
                    ...newHuds
                } = this.huds;

                this.setData(newHuds);
            }
        }
    };
}
