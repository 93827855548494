import { theme } from "color";
import { HudData } from "components";
import { addPersonAttributes, EmojiStringImageEncoder } from "image";
import { MapApp } from "maps";
import { Item, ItemConfig } from "plugins/items";
import { Scene } from "./ScenesState";

export class Bureaucrat extends Scene<['bureaucrat']> {
    getVillage: () => Item;
    constructor(
        emojiImage: EmojiStringImageEncoder,
        getVillage: () => Item,
    ) {
        super(
            emojiImage.makePlugin({
                bureaucrat: {
                    icon: '🧑‍💼',
                    size: 12,
                    shrink: 2,
                    person: true
                }
            })
        );
        this.getVillage = getVillage;
    }
    getPrimaryItemsConfig(map: MapApp): ItemConfig[] {
        const itemMinMax = map.gps.generateFromCenter();

        return [
            {
                name: 'bureaucrat',
                ...itemMinMax(15),
                minDist: 60,
                minLevel: 3,
                color: theme.player.core,
                onCollect: this.onCollectBureaucrat,
                customRender: this.emojis.makeRenderer('bureaucrat'),
                showOnFullRender: true,
                generatePerson: true,
            },
        ];
    }
    getSecondaryItemsConfig(map: MapApp): ItemConfig[] {
        return [];
    }

    addRoads = (map: MapApp, bureaucrat: Item) => {
        const village = this.getVillage();

        map.addRoadHorz(bureaucrat.loc[1], bureaucrat.loc[0], village.loc[0]);
        map.addRoadVert(village.loc[0], bureaucrat.loc[1], village.loc[1]);

        this.updateHud();
    }

    onCollectBureaucrat = (map: MapApp, bureaucrat: Item) => {
        if (!bureaucrat.found) {
            this.addRoads(map, bureaucrat);
        }
        return true;
    }

    onLoadComplete = (map: MapApp) => {
        const bureaucrat = this.primary('bureaucrat');
        if (bureaucrat.found) {
            this.addRoads(map, bureaucrat);
        }
    }

    hudId = 'bureaucratHud';
    getHudData() {
        const bureaucrat = this.primary('bureaucrat');
        if (!bureaucrat.found) {
            return undefined;
        }
        const village = this.getVillage();

        return {
            status: village.found ? 'completed' : 'open',
            content: village.found ? (
                'Follow roads to find villages. Joining villages will help you find your way around the kingdom.'
            ) : (
                'Hello, citizen! Follow roads to find villages. Joining villages will help you find your way around the kingdom.'
            ),
            emojis: addPersonAttributes(
                this.emojis.config.bureaucrat.icon,
                bureaucrat.config.skinTone,
                bureaucrat.config.gender
            ),
        } as HudData;
    }
}