import { MapApp } from "maps";
import { RandomInts } from "noise";
import { Item } from "plugins/items";

const generatePivots = (
    map: MapApp,
    village1: Item,
    village2: Item,
    village3: Item,
) => {
    const ints = new RandomInts(
        ((map.grid.noise.seed + 50) * 1007) / 993
    );

    const thirdDistV1toV1 = Math.round(
        (village2.loc[0] - village1.loc[0]) / 3
    );

    const v1tov2xpivot = ints.next(
        village1.loc[0] + thirdDistV1toV1,
        village2.loc[0] - thirdDistV1toV1
    );
    const v3tov1xpivot = ints.next(
        village1.loc[0] + 50,
        v1tov2xpivot - 50
    );
    const v3tov1ypivot = ints.next(
        village1.loc[1] + 100,
        village3.loc[1] - 100
    );
    const v3tov1xpivot2 = ints.next(
        village3.loc[0] + 50,
        v3tov1xpivot
    );

    return {
        v1tov2xpivot,
        v3tov1xpivot,
        v3tov1ypivot,
        v3tov1xpivot2,
    };
};

export type RoadFunction = typeof addRoads1to2;

export const addRoads1to2 = (
    map: MapApp,
    village1: Item,
    village2: Item,
    village3: Item,
) => {
    const {
        v1tov2xpivot,
    } = generatePivots(
        map,
        village1,
        village2,
        village3,
    );

    // village 1 to village 2
    map.addRoadHorz(village1.loc[1], village1.loc[0], v1tov2xpivot);
    map.addRoadVert(v1tov2xpivot, village1.loc[1], village2.loc[1]);
    map.addRoadHorz(village2.loc[1], v1tov2xpivot, village2.loc[0]);
};

export const addRoads2to3 = (
    map: MapApp,
    village1: Item,
    village2: Item,
    village3: Item,
) => {
    // village 2 to village 3
    map.addRoadVert(village2.loc[0], village2.loc[1], village3.loc[1]);
    map.addRoadHorz(village3.loc[1], village3.loc[0], village2.loc[0]);
};

export const addRoads3to1 = (
    map: MapApp,
    village1: Item,
    village2: Item,
    village3: Item,
) => {
    const {
        v3tov1xpivot,
        v3tov1ypivot,
        v3tov1xpivot2,
    } = generatePivots(
        map,
        village1,
        village2,
        village3,
    );

    // village 1 to village 3
    map.addRoadHorz(village1.loc[1], village1.loc[0], v3tov1xpivot);
    map.addRoadVert(v3tov1xpivot, village1.loc[1], v3tov1ypivot);
    map.addRoadHorz(v3tov1ypivot, v3tov1xpivot, v3tov1xpivot2);
    map.addRoadVert(v3tov1xpivot2, v3tov1ypivot, village3.loc[1]);
    map.addRoadHorz(village3.loc[1], v3tov1xpivot2, village3.loc[0]);
};
