import {
    useState,
    useEffect,
    useRef,
    useCallback,
} from 'react';
import {
    HudRecord,
    Hud,
} from './types';

export type ChangedHuds = Array<{
    previous: Hud,
    current: Hud,
    id: string,
}>;

let idSeed: number = 0;

export const useQuestsChanged = (
    huds: HudRecord,
    timeoutSeconds: number,
): {
    changed: ChangedHuds,
    acknowledgeChanged: (id: string) => void,
} => {
    const [changed, setChanged] = useState<ChangedHuds>([]);
    const previousHudsRef = useRef<HudRecord>(huds);

    useEffect(() => {
        const allIds = Object.keys(huds);
        const changedIds = allIds.filter(id =>
            previousHudsRef.current[id]
            && (
                huds[id].data.status !== previousHudsRef.current[id].data.status
                || huds[id].data.emojis !== previousHudsRef.current[id].data.emojis
                || huds[id].data.content !== previousHudsRef.current[id].data.content
            )
        );

        const previousHuds = previousHudsRef.current;
        previousHudsRef.current = huds;

        if (changedIds && changedIds.length) {
            const changedIdToInstanceId: Record<string, string> = changedIds.reduce(
                (acc, hudId) => {
                    acc[hudId] = (++idSeed).toString();
                    return acc;
                },
                {} as Record<string, string>
            );
            const instanceIds = Object.values(changedIdToInstanceId);

            setChanged(currentChanged => {
                const newChanged: ChangedHuds = [
                    ...currentChanged.filter(
                        data => (
                            instanceIds.includes(data.id)
                            || !changedIds.includes(data.current.id)
                        ) 
                    ),
                    ...(changedIds.reduce(
                        (acc, hudId) => {
                            const data = {
                                current: huds[hudId],
                                previous: previousHuds[hudId],
                                id: changedIdToInstanceId[hudId],
                            };
                            // console.log('ADDING NEW CHANGED HUD DATA', data);
                            acc.push(data);
                            return acc;
                        },
                        [] as ChangedHuds
                    ))
                ];
                // console.log(
                //     'SETTING NEW CHANGED HUDS',
                //     newChanged.map(c => c.previous.data),
                //     newChanged.map(c => c.current.data)
                // );
                return newChanged;
            });
            window.setTimeout(() => {
                setChanged(currentChanged =>
                    currentChanged?.filter(({ id, current: { data: { modal } } }) =>
                        modal
                            ? true
                            : !instanceIds.includes(id)
                    )
                );
            }, timeoutSeconds * 1000);
        }
    }, [huds, timeoutSeconds]);

    return {
        changed,
        acknowledgeChanged: useCallback(
            (removeId: string) =>
                setChanged(currentChanged =>
                    currentChanged?.filter(({ current }) =>
                        current.id !== removeId
                    )
                ),
            []
        )
    };
};
