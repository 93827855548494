import { EmojiPlugin, EmojiPluginConfig, EmojiStringImageEncoder } from "image";

export const skinData = {
    robot: { symbol: '🤖', text: 'You can destroy alien monsters!' },
    raccoon: { symbol: '🦝', text: 'Shiny!' }, // slowish everywhere, but extra marbles appear! disappears when not moving in trees?

    lightning: { symbol: '🌩', text: 'Light up the land as you move' }, // move slow everywhere // 💡
    // bee: { symbol: '🐝', text: 'Bzz' }, // collect pollen from flowers?
    elephant: { symbol: '🐘', text: 'Elephants are sure footed, moving quickly through the trees and water' }, // consistent speed that is fast in trees and water, slowish on land.
    monkey: { symbol: '🐵', text: 'Monkeys can move quickly through the trees' }, // no water movement, normal on land, fast trees
    horse: { symbol: '🏇', text: 'Move fast on flat land' }, // v slow trees, slow water // 🐎
    duck: { symbol: '🦆', text: 'Ducks swim like champs, but they waddle a bit when they walk.' },
    fish: { symbol: '🐟', text: 'Nobody is faster in water' }, // no land movement
    unicorn: { symbol: '🦄', text: ' We found a map, to Candy Mountain. Candy Mountain, Charlie.' }, // fast everywhere, magical? // 🚁
};
export type Skin = keyof typeof skinData;

export type PlayerStateSerialized = {
    skin?: Skin;
    enabledSkins: Array<Skin>;
};

export const skinSize = 14;

export class PlayerState {
    skin?: Skin;
    enabledSkins: Array<Skin> = [];
    emojis: EmojiPlugin<Skin[]>;

    constructor(
        emojiImage: EmojiStringImageEncoder,
    ) {
        this.emojis = emojiImage.makePlugin<Skin[]>(
            Object.keys(skinData).reduce(
                (acc, skinName) => {
                    acc[skinName as Skin] = {
                        icon: skinData[skinName as keyof typeof skinData].symbol,
                        size: skinSize,
                        shrink: 2,
                    };
                    return acc;
                },
                {} as Record<Skin, EmojiPluginConfig<Skin[]>[Skin]>
            )
        );
    };
    getSkin = (): Skin | undefined => this.skin;
    setSkin = (skin: Skin | undefined) => {
        this.skin = skin;
    };
    skinData = (skin: Skin) => skinData[skin];
    enableSkin = (skin: Skin) => {
        if (!this.enabledSkins.includes(skin)) {
            this.enabledSkins = [
                ...this.enabledSkins,
                skin
            ];
        }
    }

    load = (loaded?: PlayerStateSerialized) => {
        if (loaded) {
            this.skin = loaded.skin;
            this.enabledSkins = [
                // eliminate duplicates
                ...(new Set([
                    ...this.enabledSkins,
                    ...loaded.enabledSkins
                ]))
            ];
        }
    };

    toJSON = (): PlayerStateSerialized => {
        return {
            skin: this.skin,
            enabledSkins: this.enabledSkins,
        }
    };
}
