import React, {
    FC,
    useCallback,
    useEffect,
    useRef,
    useMemo,
} from 'react';
import styled from '@emotion/styled';
import { useSwipeable } from 'react-swipeable';
import { Card } from '.';
import {
    CardTheme,
    themeToThemeName,
    themeToThemeSymbol,
    useCardImageUrl,
    CardStateApi,
} from '..';
import { cardImageUrls } from '../data';
import {
    getRaritySymbol,
} from '../rarity';

const Flex = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
const Row = styled.div<{ marginTop?: string; justify?: string; align?: string; }>`
    display: flex;
    flex-direction: row;
    justify-content: ${props => props.justify || 'center'};
    align-items: ${props => props.align || 'center'};
    width: 100%;
    box-sizing: border-box;
    margin-top: ${props => props.marginTop || '1em'};
`;
const ThemeChooser = styled.div<{ theme: CardTheme; }>`
    flex: 1;
    position: relative;
    align-self: stretch;
    font-size: 1.2em;
    padding-left: .5em;
    display: flex;
    align-items: center;
    font-family: 'Elsie Swash Caps', cursive;
    > select {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0; 
    }
    > span {
        font-family: 'BenchNine', sans-serif;
        padding-left: 0.4rem;
    }
    ::before {
        content: '${props => themeToThemeSymbol[props.theme as CardTheme]}';
        font-family: OutlineEmoji;
        margin-right: 0.5rem;
    }
`;
const ThemeButton = styled.button<{ radius: 'left' | 'right' | 'both'; }>`
font-family: 'Elsie Swash Caps', cursive;
    align-self: stretch;
    padding: 1.2em;
    border-radius: ${props => props.radius === 'left' ? (
        '1em 0 0 1em'
    ) : props.radius === 'right' ? (
        '0 1em 1em 0'
    ) : '1em'};
    background-color: rgba(255, 255, 255, 0.2);
    border: none;
`;

const Scroller = styled.div`
    display: flex;
    width: 100%;
    height: 100px;
    flex-shrink: 0;
    box-sizing: border-box;
    flex-direction: row;
    gap: 5px;
    overflow-x: auto;
    overflow-y: visible;
    margin: 10px 0;
    ::before, ::after {
        content: ' ';
        display: inline-block;
        width: auto;
        min-width: 30px;
        flex-shrink: 0;
    }

    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    ::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }
`;

type StyledCardImgProps = {
    selected: boolean;
    src: string;
    owned: boolean;
};
const textShadowColor = 'rgba(255, 255, 255, 0.6)';
const StyledCardImage = styled.div<StyledCardImgProps>`
    height: 100px;
    width: ${props => props.selected ? '100px' : '30px'};
    flex-shrink: 0;
    opacity: ${props => props.selected ? '1' : '0.8'};
    overflow: hidden;
    transition: 0.2s ease;
    display: block;
    position: relative;
    overflow: hidden;
    color: black;
    text-shadow: 1px 0 3px white;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Elsie Swash Caps', cursive;
    font-weight: 900;
    font-size: 1.2em;
    i {
        transition: all .5s;
        opacity: ${props => props.selected ? '.5' : '1'};
        width: 100%;
        text-align: center;
        justify-self: flex-start;
        box-sizing: border-box;
        padding-top: 0.25em;
        font-style: normal;
        z-index: 2;
        color: black;
        text-shadow: -1px -1px 0 ${textShadowColor},
                    1px 1px 0 ${textShadowColor},
                    -1px 1px 0 ${textShadowColor},
                    1px -1px 0 ${textShadowColor};
        background-color: #00000099;
    }
    i:empty {
        display: none;
    }
    span {
        z-index: 2;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
    ::before {
        content: ' ';
        ${props => {
            if (props.owned) {
                return `background-image: url(${props.src});`;
            }
            return `
                background-color: #0000FF22;
            `;
        }}
        background-size: cover;
        background-position: center center;
        filter: ${props => props.selected ? 'none' : 'blur(5px)'};
        position: absolute;
        top: 0;
        left: ${props => props.selected ? '0' : '-35px'};
        width: 100px;
        height: 100%;
        transition: 0.2s ease;
        z-index: 1;
    }
`;
const CardImage: FC<{
    theme: CardTheme;
    index: number;
    selected: boolean;
    onClick: () => void;
    owned: boolean;
}> = ({ theme, index, selected, owned, ...props }) => {
    const ref = useRef<HTMLDivElement>(null);
    const imgSrc = useCardImageUrl(theme, index);

    useEffect(() => {
        if (selected && ref.current) {
            ref.current.scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "nearest"
            });
        }
    }, [selected]);

    return (
        <StyledCardImage 
            {...props}
            ref={ref}
            src={imgSrc}
            selected={selected}
            owned={owned}
        />
    );
}

/*
const testCardRarity = (theme: CardTheme, index: number) => () => {
    const count = 100;
    let hits = 0;
    for (let i = 0; i < count; i++) {
        if (weightedRandomCard(theme) === index) {
            hits++;
        }
    }
    alert(`You drew ${count} ${themeToThemeName[theme]} cards.\nThis one was drawn ${hits} times.`)
};
const testThemeRarity = (theme: CardTheme) => {
    const count = 100;
    const runs: [common: number, rare: number, epic: number, other: number][] = [];
    for (let k = 0; k < 5; k++) {
        let common = 0;
        let rare = 0;
        let epic = 0;
        let other = 0;
        for (let i = 0; i < count; i++) {
            const randI = weightedRandomCard(theme);
            const rarityName = getRarityName(theme, randI);
            if (rarityName === 'rare') {
                rare++;
            } else if (rarityName === 'epic') {
                epic++;
            } else if (rarityName === 'common') {
                common++;
            } else {
                other++;
            }
        }
        runs.push([common, rare, epic, other]);
        // alert(`You drew ${count} ${themeToThemeName[theme]} cards.\nCommon: ${common}\nRare: ${rare}\nEpic: ${epic}\nOther: ${other}`);
    }
    alert(`you draw ${count} cards 5 times.
Co, Ra, Ep, Oth
${runs.map((run, i) => run.join(',  ')).join("\n")}
`);
}
*/

export const CardList: FC<{
    theme: CardTheme;
    setTheme: (theme: CardTheme | undefined) => void;
    hasCard: CardStateApi['hasCard'],
    hasAnyInTheme: CardStateApi['hasAnyInTheme'],
    index: CardStateApi['index'],
    setIndex: CardStateApi['setIndex'],
    themeCardCount: CardStateApi['themeCardCount']
}> = ({
    theme,
    setTheme,
    hasCard,
    hasAnyInTheme,
    index,
    setIndex,
    themeCardCount,
}) => {
    const cardCount = useMemo(
        () => themeCardCount(theme),
        [theme, themeCardCount]
    );
    const cardIndices: number[] = useMemo(
        () => {
            const indices: number[] = [];
            const length = cardImageUrls[theme].length;
            for (let i = 0; i < length; i++) {
                if (hasCard(theme, i)) {
                    indices.push(i);
                }
            }
            return indices;
        },
        [theme, hasCard]
    );

    // this isn't funny, change it. lol.
    // haha, at least three past yous have laughed at this. no i'm not going to fix it, i don't know what it means again yet.
    const indexIndexLol = useMemo(
        () => cardIndices.indexOf(index),
        [cardIndices, index]
    );
    const backOne = useCallback(
        () => setIndex(
            typeof cardIndices[indexIndexLol - 1] !== 'number'
                ? cardIndices[cardIndices.length - 1]
                : cardIndices[indexIndexLol - 1]
        ),
        [setIndex, indexIndexLol, cardIndices]
    );
    const forwardOne = useCallback(
        () => setIndex(
            !cardIndices[indexIndexLol + 1]
                ? cardIndices[0]
                : cardIndices[indexIndexLol + 1]
        ),
        [setIndex, indexIndexLol, cardIndices]
    );
    const handlers = useSwipeable({
        onSwipedLeft: (eventData) => setTimeout(forwardOne, 0),
        onSwipedRight: (eventData) => setTimeout(backOne, 0),
    });

    return <>
        <Flex {...handlers}>
            {/*
            <Row marginTop="0" justify="space-between">
                <button onClick={useCallback(() => testCardRarity(theme, index), [theme, index])}>
                    Test this cards rarity
                </button>
                <button onClick={useCallback(() => testThemeRarity(theme), [theme])}>
                    Test {themeToThemeName[theme]} rarity
                </button>
            </Row>
            */}
            {hasCard(theme, index) ? (
                <Card
                    theme={theme}
                    index={index}
                    key={theme}
                />
            ) : (
                <div>Keep searching...</div>
            )}
        </Flex>
        <Row>
            <ThemeButton
                onClick={useCallback(() => setTheme(undefined), [setTheme])}
                radius="both"
            >
                ◀ Back
            </ThemeButton>
            <ThemeChooser theme={theme}>
                {themeToThemeName[theme]}
                <span>({cardCount} of {cardImageUrls[theme].length})</span>
                {/* <select value={theme} onChange={ev => setTheme(ev.target.value as CardTheme)}>
                    {themes.map(theme => hasAnyInTheme(theme) ? (
                        <option value={theme} key={theme}>
                            {themeToThemeName[theme as CardTheme]} ({cardCount} of {cardImageUrls[theme as CardTheme].length})
                        </option>
                    ) : null)}
                </select> */}
            </ThemeChooser>
            <ThemeButton
                onClick={backOne}
                radius='left'
            >◀</ThemeButton>
            <ThemeButton
                onClick={forwardOne}
                radius='right'
            >▶</ThemeButton>
        </Row>
        <Scroller>
            {cardImageUrls[theme].map((_, i) => {
                const owned = hasCard(theme, i);

                return (
                    <CardImage
                        key={`${theme}${i}`}
                        theme={theme}
                        index={i}
                        selected={index === i}
                        onClick={() => owned ? setIndex(i) : null}
                        owned={owned}
                    >
                        {owned && (
                            <i>{getRaritySymbol(theme, i)}</i>
                        )}
                        <span>{i + 1}</span>
                    </CardImage>
                );
            })}
        </Scroller>
    </>;
};
