import { MapApp } from "maps";
import {
    Item,
    ItemState,
    ItemConfig,
    ItemSerialized,
} from "plugins/items";
import { StatusState } from 'game';
import {
    EmojiStringImageEncoder,
    EmojiPlugin,
} from 'image';
import { Gps } from "coords";
import { HudData, SetHud } from "components";

export type SceneStateSerialized = {
    scenes: Record<string, {
        primary: ItemSerialized[];
        secondary: ItemSerialized[];
    }>;
};

export abstract class Scene<EmojiNames extends string[] = any> {
    primaryItems?: Item[];
    secondaryItems?: Item[];
    emojis: EmojiPlugin<EmojiNames>;

    constructor(
        emojis: EmojiPlugin<EmojiNames>
    ) {
        this.emojis = emojis;
    }

    primary = (name: string): Item => {
        const item = this.primaryItems?.find(item =>
            item.config.name === name
        );
        if (!item) {
            throw new Error(`Unable to find primary item ${name}!`);
        }
        return item;
    };

    hasPrimary = (name: string): boolean => {
        try {
            this.primary(name);
            return true;
        } catch(er) {
            return false;
        }
    };

    allPrimary = (name: string): Item[] =>
        this.primaryItems?.filter(item =>
            item.config.name === name
        ) || [];

    secondary = (name: string): Item => {
        const item = this.secondaryItems?.find(item =>
            item.config.name === name
        );
        if (!item) {
            throw new Error(`Unable to find secondary item ${name}!`);
        }
        return item;
    };

    allSecondary = (name: string): Item[] =>
        this.secondaryItems?.filter(item =>
            item.config.name === name
        ) || [];

    hasSecondary = (name: string): boolean => {
        try {
            this.secondary(name);
            return true;
        } catch(er) {
            return false;
        }
    };

    getPrimaryItemsConfig(map: MapApp): ItemConfig[] {
        throw new Error('Not yet implemented');
    }

    getSecondaryItemsConfig(map: MapApp): ItemConfig[] {
        throw new Error('Not yet implemented');
    }

    abstract hudId: string;
    getHudData(): HudData | undefined {
        return undefined;
    }
    async updateHud(): Promise<void> {}

    onLoadComplete(map: MapApp): void {}
};

export class ScenesState {
    scenes: Record<string, Scene>;
    items: ItemState;
    status: StatusState;
    emojiImage: EmojiStringImageEncoder;
    gps: Gps;
    setHud: SetHud;
    constructor(
        scenes: Record<string, Scene>,
        items: ItemState,
        gps: Gps,
        status: StatusState,
        emojiImage: EmojiStringImageEncoder,
        setHud: SetHud,
    ) {
        this.scenes = scenes;
        this.items = items;
        this.gps = gps;
        this.status = status;
        this.emojiImage = emojiImage;
        this.setHud = setHud;
        Object.values(scenes).forEach(scene => {
            // this is some bullshit that took me a while to figure out :lol:
            // make it more obvious, pls. not now, sry.
            scene.updateHud = async () => {
                // give item handlers time to update item state
                await new Promise(resolve =>
                    setTimeout(resolve, 10)
                );
                if (scene.hudId) {
                    const hudData = scene.getHudData();
                    // if (hudData) {
                    //     console.log({hudData});
                    //     debugger;
                    // }
                    setHud(scene.hudId, hudData);
                }
            };
        })
    }

    toJSON(): SceneStateSerialized {
        return {
            scenes: Object.keys(this.scenes).reduce(
                (acc, sceneName) => {
                    const scene = this.scenes[sceneName];
                    acc[sceneName] = {
                        primary: (scene.primaryItems || [])
                            .map(item => item.toJSON()),
                        secondary: (scene.secondaryItems || [])
                            .map(item => item.toJSON()),
                    };
                    return acc;
                },
                {} as SceneStateSerialized["scenes"]
            ),
        };
    }

    load(serialized: SceneStateSerialized) {
        for (let sceneName in serialized.scenes) {
            if (!this.scenes[sceneName]) {
                throw new Error(`Trying to load unknown scene name ${sceneName}`);
            }
            const scene = this.scenes[sceneName];
            const {
                primary,
                secondary,
            } = serialized.scenes[sceneName];

            this.items.addFactory(this, map => {
                const itemsConfig = scene.getPrimaryItemsConfig(map);
                scene.primaryItems = primary.map(itemSerialized => {
                    const itemConfig = itemsConfig.find(config => (
                        itemSerialized.config.name === config.name
                    ));
                    return Item.load(
                        itemSerialized,
                        itemConfig!.onCollect,
                        itemConfig!.customRender,
                    )
                });
                return scene.primaryItems.filter(item => !item.removed);
            });

            this.items.addFactory(this, map => {
                const itemsConfig = scene.getSecondaryItemsConfig(map);
                scene.secondaryItems = secondary.map(itemSerialized => {
                    const itemConfig = itemsConfig.find(config => (
                        itemSerialized.config.name === config.name
                    ));
                    return Item.load(
                        itemSerialized,
                        itemConfig!.onCollect,
                        itemConfig!.customRender,
                    )
                });
                return scene.secondaryItems.filter(item => !item.removed);
            });
        }
    }

    async initialize() {
        Object.values(this.scenes).sort((a, b) => a.hudId.localeCompare(b.hudId)).forEach(scene => {
            this.items.addFactory(scene, map =>
                scene.primaryItems = scene.getPrimaryItemsConfig(map)
                    .map(config => new Item(config))
            );
            this.items.addFactory(scene, map =>
                scene.secondaryItems = scene.getSecondaryItemsConfig(map)
                    .map(config => new Item(config))
            );
        });
    }
 
    onLoadComplete(map: MapApp) {
        Object.values(this.scenes).forEach(scene =>
            scene.updateHud()
        );
        Object.values(this.scenes).forEach(scene =>
            scene.onLoadComplete(map)
        );
    }
}
