import React, { FC, useCallback } from 'react';
import styled from '@emotion/styled';
import { EmojiText } from 'image';
import { useOnClickOutside } from './useOnClickOutside';

const FlyoutAnchor = styled.div`
    position: relative;
    flex: 1;
    width: 400px;
    max-width: 100%;
`;
const Flyout = styled.div<{
    'data-open'?: boolean;
}>`
    position: absolute;
    bottom: 100%;
    display: flex;
    flex-direction: column;
    width: 200px;
    max-width: 100%;
    right: 0;
    max-height: 50vh;
    overflow: hidden;
    transition: right 0.3s;
    box-shadow: 0 0 10px 5px rgba(30, 30, 30, 0.3);
    background-color: rgba(100, 100, 100, 0.8);

    transition: all 0.5s ease;
    ${props => props['data-open'] ? `` : `
        max-height: 0;
        opacity: 0;
    `}

    > p {
        padding: 0 .75em 0 .75em;
        color: #aaa;
        text-shadow: 0 1px 2px rgb(0 0 0 / 50%);
    }
`;
const Action = styled.button`
    text-align: left;
    background-color: rgba(120, 120, 120, 0.6);
    border: none;
    border-top: 1px solid rgba(60, 60, 60, 0.5);
    color: #ccc;
    padding: 0;
    height: 3rem;
    flex-shrink: 0;
    font-size: 1.3rem;
    :hover:enabled {
        background-color: rgba(150, 150, 150, 0.7);
    }
    :active:enabled {
        background-color: rgba(100, 100, 100, 0.7);
    }
    :disabled, [disabled] {
        opacity: 0.4;
    }
`;
const EmojiTextStyled = styled(EmojiText)`
    padding: 0 .75em 0 .75em;
`;

export const MenuFlyout: FC<{
    saveGame: (suppressStatus: boolean) => void;
    shareImage: () => void;
    sharing: boolean;
    returnToMenu: () => void;
    seed?: string;
    closeFlyout: () => void;
    open: boolean;
}> = ({
    saveGame,
    shareImage,
    sharing,
    returnToMenu,
    seed,
    closeFlyout,
    open,
}) => {
    const flyoutRef = useOnClickOutside<HTMLDivElement>(
        open,
        closeFlyout,
        'gamemenu'
    );

    const quitGame = useCallback(() => {
        closeFlyout()
        saveGame(true); // suppress status so we don't set state
        returnToMenu();
    }, [saveGame, returnToMenu, closeFlyout]);

    return <>
        <FlyoutAnchor ref={flyoutRef}>
            <Flyout data-open={open}>
                {seed && <p>seed: {seed}</p>}
                <Action onClick={quitGame}>
                    <EmojiTextStyled>🔙</EmojiTextStyled>Exit to Menu
                </Action>
                <Action
                    onClick={shareImage}
                    disabled={sharing}
                >
                    <EmojiTextStyled>{sharing ? '⏳' : '👁️‍🗨️'}</EmojiTextStyled>Share Pic
                </Action>
                <Action onClick={useCallback(
                    () => {
                        saveGame(false);
                        closeFlyout();
                    },
                    [saveGame, closeFlyout]
                )}>
                    <EmojiTextStyled>💾</EmojiTextStyled>Save Game
                </Action>
            </Flyout>
        </FlyoutAnchor>
    </>;
};
