import { Coord } from 'coords';
import { Color } from 'color';
import { NoiseGrid } from 'noise';
import { ProgressGenerator } from 'generate';
import { Level } from './Levels';

export type NoiseEntityOptions = {
    width: number,
    height: number,
    color: Color,
    limit?: number,
    aboveLevel?: Level,
    belowLevel?: Level,
    scale?: number,
    seed?: number,
};

export class NoiseEntities {
    grid: NoiseGrid;
    options: NoiseEntityOptions;
    constructor(options: NoiseEntityOptions) {
        this.options = options;
        this.grid = new NoiseGrid(
            options.width,
            options.height,
            options.scale,
            options.seed
        );
    }
    async * generate(): ProgressGenerator {
        for await (const progress of this.grid.generate()) {
            yield progress;
        }
    }
    render(coord: Coord, level: Level): Color | undefined {
        const limit = this.options.limit || 0.5;
        if (this.grid.at(coord) > limit) {
            if (this.options.aboveLevel && !level.isAbove(this.options.aboveLevel)) {
                return undefined;
            }
            if (this.options.belowLevel && !level.isBelow(this.options.belowLevel)) {
                return undefined;
            }
            return this.options.color;
        }
        return undefined;
    }
};

