import { MapApp } from 'maps';
import { ImageDataBuilder } from 'image';
import {
    Coord,
    loopRectangle,
} from 'coords';
import { theme } from 'color';
import { GameState } from 'game';

export const treePlugin = (
    state: GameState,
    half: 'top' | 'bottom',
) => (map: MapApp) => {
    const image = new ImageDataBuilder(
        map.renderWidth,
        map.renderHeight
    );
    // leave x even for equal staggering
    const treeDistX = 10;
    const halfTreeDistX = Math.floor(treeDistX * .5);
    // leave treeDistY odd for alternation
    const treeDistY = 7;

    const heightOffset = -2;
    const halfHeight: number = Math.round(
        map.renderHeight * .5
    );
    const height: number = halfHeight + (
       half === 'top' ? heightOffset : -heightOffset
    );
    const start: Coord = [
        0,
        half === 'top'
            ? 0
            : halfHeight + heightOffset
    ];

    return {
        postRender: (width: number) => {
            image.clear();

            loopRectangle(width, height, start)(coord => {
                const absCoord: Coord = [
                    coord[0] + Math.floor(map.gps.location[0]),
                    coord[1] + Math.floor(map.gps.location[1]),
                ];
                const altRow = !(absCoord[1] % 2);
                const xOffset = altRow ? halfTreeDistX : 0;
                if (
                    !((absCoord[0] + xOffset) % treeDistX)
                    && !(absCoord[1] % treeDistY)
                    && map.isInTree(absCoord)
                ) {
                    // const tree = parseInt(
                    //     (
                    //         (
                    //             absCoord[0] / Math.PI 
                    //         ) * absCoord[1]
                    //     ).toFixed(3).slice(-1)
                    // ) % 2
                    const tree = map.trees[1].grid.at(absCoord) > .5 
                        ? 'tree01'
                        : 'tree02';
                    state.emojiImage.apply(
                        tree,
                        image,
                        [
                            coord[0],
                            coord[1] - 5
                        ],
                    );
                }
            });

            return image;
        },
        fullRender: (width: number, height: number) => {
            const image = new ImageDataBuilder(width, height);
            const scaleX = (x: number) => Math.round((x / width) * map.grid.totalWidth);
            const scaleY = (y: number) => Math.round((y / height) * map.grid.totalHeight);

            loopRectangle(width, height)(coord => {
                const scaledCoord: Coord = [
                    scaleX(coord[0]),
                    scaleY(coord[1]),
                ];
                if (map.isInTree(scaledCoord)) {
                    image.put(
                        coord,
                        theme.woods.light
                    );
                }
            });

            return image;
        },
    };
};

