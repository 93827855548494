import React, { FC, useState, useEffect, useMemo } from 'react';

export const FullHeight: FC<{
    className?: string;
    fullHeight?: boolean;
}> = ({
    fullHeight = true,
    ...props
}) => {
    const [innerHeight, setInnerHeight] = useState<number>(() => window.innerHeight);

    useEffect(() => {
        const handler = () => {
            setInnerHeight(window.innerHeight);
        };
        window.addEventListener('resize', handler);

        return () => {
            window.removeEventListener('resize', handler);
        };
    });

    const style = useMemo(() => (
        fullHeight ? {
            height: innerHeight + 'px',
        } : undefined
    ), [fullHeight, innerHeight]); ;

    return (
        <div {...props} style={style} />
    )
};
