
export type GenerationProgress = [
    done: number,
    total: number,
];

export type ProgressGenerator = AsyncGenerator<GenerationProgress>;


export const randomInt = (min: number, max: number) =>
    Math.round(
        Math.random() * (max - min)
    ) + min;
