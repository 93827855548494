import { useEffect, useMemo, useRef, useState } from "react";

type MarbleChangeId = number;
type MarbleChangeMap = Map<MarbleChangeId, number>;

let marbleChangeId: number = 0;
export const marbleChangeAnimMs = 2000;

export const useMarbleChange = (marblesOwned: number) => {
    const [marbleChanges, setMarbleChanges] = useState<MarbleChangeMap>(
        () => new Map()
    );
    const lastMarblesOwnedRef = useRef<number>(marblesOwned);

    useEffect(() => {
        const { current: previousMarblesOwned } = lastMarblesOwnedRef;
        const newId = marbleChangeId++;
        const change = marblesOwned - previousMarblesOwned;

        lastMarblesOwnedRef.current = marblesOwned;
        
        setMarbleChanges(mc => {
            const newChanges: MarbleChangeMap = new Map(mc);
            newChanges.set(newId, change);

            return newChanges;
        });
        setTimeout(() => {
            setMarbleChanges(mc => {
                const newChanges: MarbleChangeMap = new Map(mc);
                newChanges.delete(newId);
    
                return newChanges;
            });
        }, marbleChangeAnimMs);
    }, [marblesOwned]);

    return useMemo(() =>
        [...marbleChanges.entries()].filter(([,v]) => !!v),
        [marbleChanges]
    );
};
