import { MapApp } from 'maps';
import { ImageDataBuilder } from 'image';
import { GameState } from 'game';
import { Direction } from 'plugins/location';
import { Color, theme } from 'color';
import { Coord, gridToPolar } from 'coords';
import { Skin, skinSize } from './PlayerState';

const PLAYER_SIZE = 8;
const PLAYER_SIZE_HALF = PLAYER_SIZE / 2;
const PLAYER_SIZE_DOUBLE = PLAYER_SIZE * 2;
const SKIN_SIZE_DOUBLE = skinSize * 2;

const CLEAR_SIZE = Math.max(PLAYER_SIZE, skinSize);
const CLEAR_SIZE_DOUBLE = Math.max(PLAYER_SIZE_DOUBLE, SKIN_SIZE_DOUBLE);

const SKIN_OUTLINE_COLOR: Color = [
    0,
    0,
    0,
    125,
];

export const playerPlugin = (state: GameState) => (map: MapApp) => {
    const image = new ImageDataBuilder(
        map.renderWidth,
        map.renderHeight
    );
    const halfViewWidth = Math.round(map.grid.viewWidth / 2);
    const halfViewHeight = Math.round(map.grid.viewHeight / 2);

    const lineCenter: Coord = [
        halfViewWidth,
        halfViewHeight 
    ];
    const directionLineCoords: Record<Direction, Coord> = {
        'w': [
            halfViewWidth-PLAYER_SIZE,
            halfViewHeight
        ],
        'nw': gridToPolar(lineCenter, PLAYER_SIZE, -135),
        'n': [
            halfViewWidth,
            halfViewHeight-PLAYER_SIZE
        ],
        'ne': gridToPolar(lineCenter, PLAYER_SIZE, -45),
        'e': [
            halfViewWidth+PLAYER_SIZE,
            halfViewHeight
        ],
        'se': gridToPolar(lineCenter, PLAYER_SIZE, 45),
        's': [
            halfViewWidth,
            halfViewHeight+PLAYER_SIZE
        ],
        'sw': gridToPolar(lineCenter, PLAYER_SIZE, 135),
    };
    const skinDirectionLineCoords: Record<Direction, Coord> = {
        'w': [
            halfViewWidth-skinSize,
            halfViewHeight
        ],
        'nw': gridToPolar(lineCenter, skinSize, -135),
        'n': [
            halfViewWidth,
            halfViewHeight-skinSize
        ],
        'ne': gridToPolar(lineCenter, skinSize, -45),
        'e': [
            halfViewWidth+skinSize,
            halfViewHeight
        ],
        'se': gridToPolar(lineCenter, skinSize, 45),
        's': [
            halfViewWidth,
            halfViewHeight+skinSize
        ],
        'sw': gridToPolar(lineCenter, skinSize, 135),
    };
    let previousDirection: Direction = state.velocity.direction;
    let previousSkin: Skin | undefined = state.player.emojis.generated
        ? state.player.skin
        : undefined;
    const renderPlayer = () => {
        previousDirection = state.velocity.direction;
        previousSkin = state.player.emojis.generated
            ? state.player.skin
            : undefined;
        // clear previous player drawing
        image.rect(
            [
                Math.round(halfViewWidth - CLEAR_SIZE),
                Math.round(halfViewHeight - CLEAR_SIZE)
            ],
            theme.transparent,
            CLEAR_SIZE_DOUBLE,
        );
        if (state.player.skin && state.player.emojis.generated) {
            const halfEmojiSize = Math.floor(skinSize * .5);
            image.line(
                lineCenter,
                skinDirectionLineCoords[state.velocity.direction],
                SKIN_OUTLINE_COLOR
            );
            image.disc(
                [
                    Math.round(halfViewWidth - halfEmojiSize - 1),
                    Math.round(halfViewHeight - halfEmojiSize - 1)
                ],
                SKIN_OUTLINE_COLOR,
                skinSize + 1
            );
            state.player.emojis.apply(
                state.player.skin,
                image,
                lineCenter
            );
        } else {
            // draw outline circle
            const discCoord: Coord = [
                Math.round(halfViewWidth - PLAYER_SIZE_HALF),
                Math.round(halfViewHeight - PLAYER_SIZE_HALF)
            ];
            image.disc(
                [
                    discCoord[0] - 1,
                    discCoord[1] - 1,
                ],
                theme.player.outline,
                PLAYER_SIZE + 2
            );
            image.disc(
                discCoord,
                theme.player.core,
                PLAYER_SIZE
            );
            image.line(
                lineCenter,
                directionLineCoords[state.velocity.direction],
                theme.player.outline 
            );
        }
    };
    renderPlayer();

    return {
        postRender: (width: number, height: number) => {
            if (
                previousDirection !== state.velocity.direction
                || state.player.skin !== previousSkin
            ) {
                renderPlayer();
            };
            return image; 
        },
    };
};

