import styled from '@emotion/styled';

export const BUBBLE_BUTTON_HEIGHT: number = 80;
export const BubbleButton = styled.button`
    position: absolute;
    bottom: 0;
    right: 0;
    width: ${BUBBLE_BUTTON_HEIGHT}px;
    height: ${BUBBLE_BUTTON_HEIGHT}px;
    box-sizing: border-box;
    box-shadow: 0 0 10px 5px rgba(30, 30, 30, 0.3);
    border: none;
    border-radius: ${BUBBLE_BUTTON_HEIGHT}px 0 0 0;
    background-color: rgba(120, 120, 120, 0.6);
    color: #666;
    transition: width 0.2s, height 0.2s;
    ::after {
        transition: margin 0.2s;
        opacity: 0.5;
        content: ' ';
        background-repeat: no-repeat;
        background-position: center center;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    :disabled, [disabled] {
        transition: width 1s, height 1s;
        width: 60px;
        height: 60px;
        background-color: rgba(100, 100, 100, 0.6);
        ::after {
            transition: margin 1s;
        }
    }
`;

export const BubbleCloseButton = styled(BubbleButton)`
    border-radius: 0 100% 0 0;
    right: auto;
    left: 0;
    transition: left 1s ease;
    ::after {
        content: '✖';
        font-family: OutlineEmoji;
        color: black;
        font-size: 2.5rem;
        margin: 1.25rem .7rem 0 0;
    }
`;
