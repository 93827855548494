import React, {
    useRef,
    MutableRefObject,
    useEffect,
    FC,
} from 'react';
import styled from '@emotion/styled';

import { FullHeight } from '../FullHeight';
import { BubbleCloseButton } from '../BubbleButton';

const Panel = styled(({show, ...props}: { show: boolean; }) => <FullHeight {...props} />)<{
    show: boolean;
}>`
    position: absolute;
    left: ${props => props.show ? '0' : '-101%'};
    transition: left 0.5s;
    top: 0;
    width: 100%;
    height: 100%;
`;
const Canvas = styled.canvas`
    background-color: #222;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

export const OverviewMap: FC<{
    draw: (ref: MutableRefObject<HTMLCanvasElement | null>) => void;
    show: boolean;
    close: () => void;
}> = ({
    draw,
    show,
    close,
}) => {
    const ref = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        if (show)
            draw(ref);
    }, [show, draw]);

    return <>
        <Panel show={show}>
            <Canvas ref={ref} />
            <BubbleCloseButton onClick={close} />
        </Panel>
    </>;
};
