
export type QueuedSave<T> = {
    data: T,
    onStart?: () => void,
    onEnd?: () => void,
}[];

export abstract class BaseStore<T extends object> {
    protected key: string;
    protected saving: boolean = false;
    protected queue: QueuedSave<T>;
    constructor(key: string) {
        this.key = key;
        this.queue = [];
    }
    save = (
        data: T,
        onStart?: () => void,
        onEnd?: () => void,
    ) => {
        this.queue.unshift({
            data,
            onStart,
            onEnd
        });
        this.process();
    };
    select = async (): Promise<T> => {
        throw new Error('not yet implemented');
    };
    hasSaved = async (): Promise<boolean> => {
        throw new Error('not yet implemented');
    };
    process = async (): Promise<void> => {
        throw new Error('not yet implemented');
    };
};

