import { theme } from 'color';
import { EmojiStringImageEncoder } from 'image';
import { MapApp } from 'maps';
import { Item, ItemConfig } from 'plugins/items';
import { Scene } from './ScenesState';


export class Village extends Scene<['hut', 'castle', 'fountain']> {
    hudId = "village";
    quadrants: Array<1 | 2 | 3 | 4>;
    minDist: number;
    onCollect: (map: MapApp, castle: Item) => void;
    constructor(
        emojiImage: EmojiStringImageEncoder,
        quadrants: Array<1 | 2 | 3 | 4>,
        minDist: number,
        onCollect: (map: MapApp, castle: Item) => void,
    ) {
        super(
            emojiImage.makePlugin({
                hut: { icon: '🛖', size: 10, shrink: 2 },
                castle: { icon: '🏰', size: 24, shrink: 2 },
                fountain: { icon: '⛲', size: 12, shrink: 2 },
            })
        );
        this.quadrants = quadrants;
        this.minDist = minDist;
        this.onCollect = onCollect;
    }

    onCollectVillage = (map: MapApp, castle: Item) => {
        this.onCollect(map, castle);
        return true;
    }

    getPrimaryItemsConfig(map: MapApp): ItemConfig[] {
        return [
            {
                name: 'castle',
                ...map.gps.zoomMinMaxQuandrant.apply(map.gps, this.quadrants),
                minDist: this.minDist,
                minLevel: 3,
                color: theme.player.core,
                onCollect: this.onCollectVillage,
                customRender: this.emojis.makeRenderer('castle'),
                showOnFullRender: true,
            }
        ];
    }
    getSecondaryItemsConfig(map: MapApp) {
        const castle = this.primary('castle');

        return [
            {
                name: 'fountain',
                min: [
                    castle.loc[0] - 40,
                    castle.loc[1] - 40,
                ],
                max: [
                    castle.loc[0] + 40,
                    castle.loc[1] + 40,
                ],
                minDist: 20,
                minMaxDistFrom: castle.loc,
                minOtherItemDist: 5,
                minLevel: 3,
                color: theme.player.core,
                onCollect: () => true,
                hidePointer: true,
                customRender: this.emojis.makeRenderer('fountain'),
            },
            ...(
                new Array(25).fill({
                    name: 'hut',
                    min: [
                        castle.loc[0] - 50,
                        castle.loc[1] - 50,
                    ],
                    max: [
                        castle.loc[0] + 50,
                        castle.loc[1] + 50,
                    ],
                    minOtherItemDist: 5,
                    minDist: 15,
                    minMaxDistFrom: castle.loc,
                    minLevel: 3,
                    color: theme.player.core,
                    onCollect: () => true,
                    hidePointer: true,
                    customRender: this.emojis.makeRenderer('hut'),
                })
            )
        ];
    }
}
