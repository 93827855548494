import React, { ChangeEvent, FC, ReactChild, ReactChildren, useCallback } from "react";
import styled from "@emotion/styled";

// export const downloadText = (
//     filename: string,
//     text: string,
//     mimetype: string = 'text/plain',
// ) => {
//     const element = document.createElement('a');
//     element.setAttribute('href', 'data:' + mimetype + ';charset=utf-8,' + encodeURIComponent(text));
//     element.setAttribute('download', filename);
  
//     element.style.display = 'none';
//     document.body.appendChild(element);
  
//     element.click();
  
//     document.body.removeChild(element);
// };
const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
`;
const ExportButton = styled.a`
    color: #999;
    text-decoration: none;
    cursor: pointer;
    :visited {
        color: #999;
    }
    :active {
        color: #555;
    }
    :hover {
        color: #aaa;
    }
    > input {
        display: none;
    }
    > i {
        margin: 0 .2rem;
        font-family: OutlineEmoji;
        font-style: normal;
    }
`;
const ImportButton = ExportButton.withComponent('label');

export const ImportExport: FC<{
    importJson: (json: string) => void,
    exportJson: string,
    children?: ReactChildren | ReactChild,
}> = ({
    importJson,
    exportJson,
    children,
}) => {
    const handleFileSelect = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const files = event.target.files;
            if (!files || !files[0])
                return;

            const reader = new FileReader();
            reader.onload = () => {
                try {
                    importJson(`${reader.result}`);
                    alert('Import successful!')
                } catch {
                    alert('Something went wrong');
                }
            };
            reader.readAsText(files[0]);
        },
        [importJson]
    );
    return (
        <Container>
            <ExportButton
                href={`data:application/json;charset=utf-8,${encodeURIComponent(exportJson)}`}
                download="fidgetmap_card_save_data.json"
            >
                Export
                <i>📤</i>
            </ExportButton>
            {children}
            <ImportButton>
                <i>📥</i>
                Import
                <input
                    type="file"
                    accept=".json"
                    onChange={handleFileSelect}
                />
            </ImportButton>
        </Container>
    )
};