import { emojiStrings } from 'image';
import {
    CardTheme,
    CardCategory,
} from './types';

export const themeToThemeName: Record<CardTheme, string> = {
    meadows: 'Meadows',
    beachWoods: 'Beach Wood',
    beachPath: 'Beach Path',
    boots: 'Boots',
    cabins: 'Cabin',
    candle: 'Candle',
    castle: 'Castle',
    flashlight: 'Flashlight',
    goggles: 'Goggles',
    housefire: 'House Fire',
    overlook: 'Overlook',
    path: 'Path',
    shoes: 'Shoes',
    silhouette: 'Adventurer',
    surfboard: 'Surf Board',
};

export const themeToThemeSymbol: Record<CardTheme, string> = {
    meadows: '🏞',
    beachWoods: '🏖',
    beachPath: '🏝',
    boots: emojiStrings.boots,
    cabins: emojiStrings.home01,
    candle: emojiStrings.candle,
    castle: '🏰',
    flashlight: emojiStrings.flashlight,
    goggles: emojiStrings.goggles,
    housefire: '🔥',
    overlook: '🔭',
    path: '🌄',
    shoes: emojiStrings.shoes,
    silhouette: '🧍‍♂️',
    surfboard: emojiStrings.surf,
};

// const __devIsAbleToBeCollected: Record<CardTheme, string> = {
//     meadows: '',
//     beachWoods: 'in scientist collect scene',
//     beachPath: '',
//     boots: 'as a tool',
//     cabins: 'when you leave home',
//     candle: 'as a tool',
//     castle: 'in the village scene',
//     flashlight: 'as a tool',
//     goggles: 'as a tool',
//     housefire: 'in the housefire scene',
//     overlook: '',
//     path: '',
//     shoes: 'as a tool',
//     silhouette: '',
//     surfboard: 'as a tool',
// };

export const themeToTitle: Record<CardTheme, string> = {
    meadows: 'Rolling Meadows',
    beachWoods: 'Woods by the Beach',
    beachPath: 'Path by the Beach',
    boots: 'Old Boots',
    cabins: 'Your Mountain Cabin',
    candle: 'A Small Candle',
    castle: 'A Castle & village',
    flashlight: 'A Flashlight',
    goggles: 'A pair of swim goggles',
    housefire: 'A Burning House',
    overlook: 'A Beautiful Overlook',
    path: 'A well-worn pathway',
    shoes: 'A dusty pair of shoes',
    silhouette: 'Facing the world',
    surfboard: 'A small surfboard',
};

export const themeToDescription: Record<CardTheme, string> = {
    meadows: 'Grass stretches out to the edge of verdant forest.',
    beachWoods: 'Glimpses of the water and sand dance through the tree limbs.',
    beachPath: 'This is such a peaceful place for a pathway.',
    boots: 'The old pair of boots look mighty useful in these dense woods.',
    cabins: 'The old cabin might be drafty, but it feels like home.',
    candle: "It's not much, but the light from this little candle is better than nothing.",
    castle: 'The massive castle looms over the small village. The town fountain acts as a meeting place.',
    flashlight: 'Dim light from the small flashlight dispells a moderate amount of darkness.',
    goggles: 'These should help keep the water out of your eyes while you are splashing.',
    housefire: 'A roof over your head can mean the difference when it comes to survival.',
    overlook: 'Sometimes it feels like you can see forever from up here.',
    path: 'This path has been traveled many times to be so empty today.',
    shoes: 'These shoes will make it much easier to get around.',
    silhouette: '"Not all those who wander are lost" - Tolkien',
    surfboard: 'Maybe you can use this to float across larger bodies of water.',
};

export const themeToCategory: Record<CardTheme, CardCategory> = {
    meadows: 'explore',
    beachWoods: 'explore',
    beachPath: 'explore',
    boots: 'tool',
    cabins: 'explore',
    candle: 'tool',
    castle: 'quest',
    flashlight: 'tool',
    goggles: 'tool',
    housefire: 'quest',
    overlook: 'explore',
    path: 'explore',
    shoes: 'tool',
    silhouette: 'explore',
    surfboard: 'tool',
};

export const categoryToColors: Record<CardCategory, {
    cardBorder: string;
    imgBorder: string;
    iconsBg: string;
    iconsBorder: string;
}> = {
    'tool': {
        cardBorder: '#db6d16', // '#F58024',
        imgBorder: '#db6d16', // '#F58024',
        iconsBg: '#e8a20c', // '#F7AA6F',
        iconsBorder: '#db6d16', // '#F58024',
    },
    'quest': {
        cardBorder: '#3A16DB', // '#074075',
        imgBorder: '#3A16DB', // '#074075',
        iconsBg: '#735AE1', // '#91C1EE',
        iconsBorder: '#3A16DB', // '#074075',
    },
    'explore': {
        cardBorder: '#118f11', // '#186B31',
        imgBorder: '#118f11', // '#186B31',
        iconsBg: '#4EC24E', // '#5FB87A',
        iconsBorder: '#118f11', // '#186B31',
    },
};

