import React, { FC } from 'react';
import { Message } from 'message/Bus';

import styled from '@emotion/styled';
import { keyframes } from '@emotion/react'

const appear = keyframes`
  from {
    transform: translate3d(0, 1rem, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
`;


const MessageList = styled.ul`
    z-index: 99999999999;
    list-style-type: none;
    position: absolute;
    top: 8rem;
    left: 1rem;
    right: 1rem;
    @media screen and (min-width: 700px) {
        left: 10%;
        right: 10%;
    }
    @media screen and (min-width: 900px) {
        left: 25%;
        right: 25%;
    }
    @media screen and (min-width: 1100px) {
        left: 33%;
        right: 33%;
    }
    margin: 0;
    padding: 0.3em 0 0 0;
    text-align: center;
    color: white;
    > li {
        padding: .3rem;
        background-color: rgba(0, 0, 0, 0.7);
        font-size: 1.3rem;
        margin-bottom: .3rem;
        border-radius: .2rem;
        animation: ${appear} .3s ease;
    }
`;

export const Messages: FC<{
    data: Message[],
}> = ({ data }) => {
    if (!data.length) {
        return null;
    }
    return (
        <MessageList>
            {data.map(message => (
                <li key={message.text}>
                    {message.text}
                </li>
            ))}
        </MessageList>
    );
};

