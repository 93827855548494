import {
    useState,
    useEffect,
    useCallback,
    useMemo,
} from 'react';
import { IdbStore } from 'store';
import { weightedRandomCard } from './rarity';
import {
    CardTheme,
} from './types';

export type CardStateSerialized = Record<
    Partial<CardTheme>,
    number[]
>;
export type AddedCard = [
    theme: CardTheme,
    index: number,
];

const cardStore = new IdbStore<CardStateSerialized>('playerCardState');

const copyCardState = (data: CardStateSerialized) =>
    Object.entries(data).reduce(
        (acc, [theme, themeCards]: [string, number[]]) => {
            acc[theme as CardTheme] = [...themeCards];
            return acc;
        },
        {} as CardStateSerialized
    );

export const useCardState = (
    addedTimeoutSeconds: number = 5,
) => {
    const [theme, setTheme] = useState<CardTheme | undefined>(undefined);
    const [open, setOpen] = useState<boolean>(false);
    const [index, setIndex] = useState<number>(0);
    const [data, setData] = useState<
        CardStateSerialized | undefined
    >(undefined);
    const [added, setAdded] = useState<AddedCard[]>([]);
    const hasAnyInTheme = useCallback((theme: CardTheme): boolean => {
        return !!(data && data[theme] && data[theme].some(i => !!i));
    }, [data]);
    const hasAnyCards = useCallback(() => {
        if (!data)
            return false;

        const keys = Object.keys(data);
        if (!keys.length)
            return false;

        return keys.some(theme => hasAnyInTheme(theme as CardTheme));
    }, [data, hasAnyInTheme]);
    const firstCardIndex: number = useMemo(() => {
        if (!data || !theme || !data[theme])
            return 0;

        return data[theme].findIndex(val => !!val);
    }, [data, theme]);

    useEffect(() => {
        (async () => {
            setData(
                await cardStore.select() || {} as CardStateSerialized
            );
        })();
    }, []);
    useEffect(() => {
        setIndex(firstCardIndex);
    }, [theme, firstCardIndex]);
    useEffect(() => {
        if (!open) {
            setTimeout(
                () => {
                    setTheme(undefined);
                    setIndex(0);
                },
                300
            );
        }
    }, [open]);

    return {
        added,
        theme,
        setTheme,
        open,
        setOpen,
        index,
        setIndex,
        loaded: data !== undefined,
        showButton: hasAnyCards(),
        exportJson: useMemo(() => JSON.stringify(data), [data]),
        importJson: useCallback((json: string) => {
            const importData: CardStateSerialized = JSON.parse(json);
            setData(d => {
                if (!d)
                    throw new Error('tried to import before data was loaded');
                const browserData = copyCardState(d);
                Object.entries(importData).forEach(
                    ([importTheme, importThemeCards]: [string, number[]]) => {
                        const browserThemeCards = browserData[importTheme as CardTheme];
                        // console.log({importTheme, importThemeCards, browserThemeCards});
                        if (!browserThemeCards) {
                            browserData[importTheme as CardTheme] = importThemeCards;
                        } else {
                            importThemeCards.forEach((value, i) => {
                                if (value && (!browserThemeCards[i] || value > browserThemeCards[i])) {
                                    browserThemeCards[i] = value;
                                }
                            });
                        }
                    }
                );

                cardStore.save(browserData);

                return browserData;
            })
        }, []),
        giveCard: useCallback((theme: CardTheme) => {
            const randIndex = weightedRandomCard(theme);
            setData(d => {
                if (!d)
                    throw new Error('tried to give card before data was loaded');

                const arr: number[] = d[theme] ? [...d[theme]] : [];

                arr[randIndex] = (arr[randIndex] || 0) + 1;

                const newData = {
                    ...d,
                    [theme]: arr
                };
                
                cardStore.save(newData);

                return newData;
            });
            setAdded(a => [
                ...a,
                [theme, randIndex],
            ]);
            window.setTimeout(() => {
                setAdded(a => a.filter(
                    ([aTheme, aRandIndex]) => !(
                        theme === aTheme
                        && aRandIndex === randIndex
                    )
                ));
            }, addedTimeoutSeconds * 1000);
            return randIndex;
        }, [addedTimeoutSeconds]),
        showCard: useCallback((theme: CardTheme, index: number) => {
            setTimeout(() => {
                setTheme(theme);
                setOpen(true);
                setTimeout(() => {
                    setIndex(index);
                }, 300);
            }, 300);
        }, []),
        hasAnyCards,
        hasCard: useCallback((theme: CardTheme, index: number): boolean => {
            return !!(data && data[theme] && data[theme][index]);
        }, [data]),
        hasAnyInTheme,
        themeCardCount: useCallback((theme: CardTheme): number => {
            if (!data || !data[theme])
                return 0;
            
            return data[theme].reduce((acc, cardCount) => (
                acc + (cardCount ? 1 : 0)
            ), 0);
        }, [data]),
    };
};
export type CardStateApi = ReturnType<typeof useCardState>;
export type GiveCard = CardStateApi['giveCard'];
