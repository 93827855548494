import { MapApp } from 'maps';
import { theme } from 'color';
import { ImageDataBuilder } from 'image';
import { Coord } from 'coords';
import { GameState } from 'game';

const EVENT_STOP = 'stopjoystick';
export const stopJoystick = () => window.dispatchEvent(
    new CustomEvent(EVENT_STOP)
);

const eventToCoord = (event: MouseEvent | TouchEvent): Coord => {
    const rect = (event.target as HTMLCanvasElement).getBoundingClientRect();
    if ('touches' in event) {
        event = event as TouchEvent;
        return [
            Math.round(event.touches[0].pageX - rect.x),
            Math.round(event.touches[0].pageY - rect.y)
        ];
    }
    return [
        Math.round(event.pageX - rect.x),
        Math.round(event.pageY - rect.y),
    ];
};

const DRAGGER_SIZE = 30;
const DRAGGER_SIZE_HALF = DRAGGER_SIZE / 2;
export const joystickPlugin = (state: GameState) => (map: MapApp) => {
    const target = map.grid.canvas;
    const multiplier = .1 / map.grid.blockSize;
    let dragStart: Coord = [0, 0];
    let gridAnchorTopLeft: Coord;
    const setDragStart = (coord: Coord) => {
        dragStart = coord;
        const gridAnchorCenter = map.pixelCoordToViewport(coord);
        gridAnchorTopLeft = [
            Math.floor(gridAnchorCenter[0] - DRAGGER_SIZE_HALF),
            Math.floor(gridAnchorCenter[1] - DRAGGER_SIZE_HALF),
        ];
    };

    const handleMove = (event: MouseEvent | TouchEvent) => {
        event.preventDefault();

        const [x, y] = eventToCoord(event);
        state.velocity.current = [
            (dragStart[0] - x) * multiplier,
            (dragStart[1] - y) * multiplier
        ];
    };
    const handleEnd = (event: Event) => {
        event.preventDefault();
        state.velocity.current = undefined;
        target.removeEventListener('mousemove', handleMove);
        target.removeEventListener('touchmove', handleMove);
        target.removeEventListener('mouseup', handleEnd);
        target.removeEventListener('touchend', handleEnd);
        window.removeEventListener(EVENT_STOP, handleEnd);
    };

    const handleStart = (event: MouseEvent | TouchEvent) => {
        event.preventDefault();
        setDragStart(eventToCoord(event));
        target.addEventListener('mousemove', handleMove);
        target.addEventListener('touchmove', handleMove);
        target.addEventListener('mouseup', handleEnd);
        target.addEventListener('touchend', handleEnd);
        window.addEventListener(EVENT_STOP, handleEnd);
    };
    target.addEventListener('mousedown', handleStart);
    target.addEventListener('touchstart', handleStart);

    const arrowKeys: {
        up: boolean,
        right: boolean,
        down: boolean,
        left: boolean,
    } = {
        up: false,
        right: false,
        down: false,
        left: false,
    };
    window.addEventListener('keydown', (event: KeyboardEvent) => {
        setDragStart([
            Math.floor(map.grid.canvas.clientWidth * 0.5),
            Math.floor(map.grid.canvas.clientHeight * 0.8),
        ]);
        const keyHandler = ({
            "ArrowDown": () => {
                arrowKeys.down = true;
                arrowKeys.up = false;
            },
            "ArrowUp": () => {
                arrowKeys.up = true;
                arrowKeys.down = false;
            },
            "ArrowRight": () => {
                arrowKeys.right = true;
                arrowKeys.left = false;
            },
            "ArrowLeft": () => {
                arrowKeys.left = true;
                arrowKeys.right = false;
            },
        } as Record<string, () => any>)[event.key];

        
        if (keyHandler) {
            event.preventDefault();
            keyHandler();
            const horz = arrowKeys.left || arrowKeys.right;
            const vert = arrowKeys.up || arrowKeys.down;
            state.velocity.current = [
                horz ? (
                    state.velocity.max * (arrowKeys.left ? 1 : -1)
                ) : 0,
                vert ? (
                    state.velocity.max * (arrowKeys.up ? 1 : -1)
                ) : 0,
            ];
        }
    });
    window.addEventListener('keyup', (event: KeyboardEvent) => {
        const keyHandler = ({
            "ArrowDown": () => {
                arrowKeys.down = false;
                state.velocity.current = [
                    (state.velocity.current && state.velocity.current[0]) || 0,
                    0,
                ];
            },
            "ArrowUp": () => {
                arrowKeys.up = false;
                state.velocity.current = [
                    (state.velocity.current && state.velocity.current[0]) || 0,
                    0,
                ];
            },
            "ArrowRight": () => {
                arrowKeys.right = false;
                state.velocity.current = [
                    0,
                    (state.velocity.current && state.velocity.current[1]) || 0,
                ];
            },
            "ArrowLeft": () => {
                arrowKeys.left = false;
                state.velocity.current = [
                    0,
                    (state.velocity.current && state.velocity.current[1]) || 0,
                ];
            },
        } as Record<string, () => any>)[event.key];
        if (keyHandler) {
            event.preventDefault();
            keyHandler();
            if (Object.values(arrowKeys).every(active => !active)) {
                state.velocity.current = undefined;
            }
        }
    });
    const data = new ImageDataBuilder(
        map.renderWidth,
        map.renderHeight
    );

    return {
        postRender: (width: number, height: number) => {
            data.clear();
            state.velocity.apply(
                map,
                state.player.skin
            ); 
            if (state.velocity.current) {
                data.disc(
                    gridAnchorTopLeft,
                    theme.joystick.anchor,
                    DRAGGER_SIZE
                );

                data.disc(
                    [
                        Math.floor(gridAnchorTopLeft[0] - (state.velocity.current[0] * 5)),
                        Math.floor(gridAnchorTopLeft[1] - (state.velocity.current[1] * 5)),
                    ],
                    theme.joystick.thumb,
                    DRAGGER_SIZE
                );
            }
            return data;
        },
    };
};

