import { useMemo } from 'react';
import { CardTheme } from './types';
import { cardImageUrls } from './data';

export * from './components';
export * from './types';
export * from './data';
export * from './mappings';
export * from './rarity';
export * from './CardState';


export const selectCardImageUrl = (
    theme: CardTheme,
    index: number,
) => cardImageUrls[theme][index]?.url;

export const useCardImageUrl = (
    theme: CardTheme,
    index: number,
) => useMemo(
    () => selectCardImageUrl(theme, index),
    [theme, index]
);

export const useCardImageId = (
    theme: CardTheme,
    index: number,
) => useMemo(
    () => cardImageUrls[theme][index]?.id,
    [theme, index]
);

export const cardCount = (theme: CardTheme) => cardImageUrls[theme].length;


