import { GiveCard } from "cards";
import { theme } from "color";
import { HudData } from "components";
import { Throttler } from "game";
import { addPersonAttributes, EmojiStringImageEncoder } from "image";
import { MapApp } from "maps";
import { Bus } from "message";
import { Item, ItemConfig } from "plugins/items";
import { stopJoystick } from 'plugins/location';
import { Skin } from "plugins/player";
import { Scene } from "./ScenesState";

export class ScientistCollect extends Scene<['scientist', 'sample']> {
    totalSamples: number = 50;
    costPerCard: number = 10;
    bus: Bus;
    giveCard: GiveCard;
    enableSkin: (skin: Skin) => void;
    setSkin: (skin: Skin | undefined) => void;
    constructor(
        emojiImage: EmojiStringImageEncoder,
        bus: Bus,
        giveCard: GiveCard,
        enableSkin: (skin: Skin) => void,
        setSkin: (skin: Skin | undefined) => void,
    ) {
        super(
            emojiImage.makePlugin({
                scientist: { icon: '👨‍🔬', size: 12, shrink: 2, person: true },
                sample: { icon: '🦠', size: 10, shrink: 2 }, // 🧫, 🧪
            })
        );
        this.bus = bus;
        this.giveCard = giveCard;
        this.enableSkin = enableSkin;
        this.setSkin = setSkin;
    }
    getPrimaryItemsConfig(map: MapApp): ItemConfig[] {
        const itemMinMax = map.gps.generateFromCenter();

        return [
            {
                name: 'scientist',
                ...itemMinMax(10),
                minDist: 30,
                minLevel: 3,
                color: theme.player.core,
                onCollect: this.onCollectScientist,
                customRender: this.emojis.makeRenderer('scientist'),
                showOnFullRender: true,
                generatePerson: true,
            },
            ...(
                new Array(this.totalSamples).fill({
                    name: 'sample',
                    ...itemMinMax(80),
                    minDist: 20,
                    minLevel: 1,
                    maxLevel: 2,
                    color: theme.scenes.sample,
                    onCollect: () => {
                        this.updateHud();
                    },
                    conceal: true,
                    customRender: this.emojis.makeRenderer('sample'),
                    showOnFullRender: true,
                    minOtherItemDist: 80,
                })
            )
        ];
    }
    getSecondaryItemsConfig(map: MapApp): ItemConfig[] {
        return [];
    }

    get samplesFound(): number {
        return this.allPrimary('sample').filter(s => s.found).length;
    }

    get rewarded(): number {
        const rewarded = this.primary('scientist').data.rewarded as number;
        return rewarded || 0;
    }

    get balance() {
        return this.samplesFound - this.rewarded;
    }

    throttler = new Throttler(6);
    onCollectScientist = (map:MapApp, scientist: Item) => {
        if (this.throttler.waits()) {
            return true;
        }
        if (!scientist.found) {
            this.allPrimary('sample').forEach(sample =>
                sample.config.conceal = false
            );
            this.updateHud();
            return true;
        }
        if (this.balance < this.costPerCard) {
            this.bus.addQuiet("See me when you've collected more samples.", 4);
            return true;
        }
        stopJoystick();
        this.bus.addQuiet(`Traded ${this.costPerCard} samples for a card!`, 4);
        scientist.data.rewarded = this.rewarded + this.costPerCard;
        if (scientist.data.rewarded === this.costPerCard) {
            this.enableSkin('duck');
            this.setSkin('duck');
        }
        this.giveCard('beachWoods');
        this.updateHud();
        return true;
    }

    hudId = 'scientistCollectHud';
    getHudData() {
        const scientist = this.primary('scientist');
        if (!scientist.found) {
            return undefined;
        }
        const samplesFound = this.samplesFound;
        const balance = this.balance;
        const completed = this.totalSamples === samplesFound && !balance;
        const givenDuck = scientist.data.rewarded >= this.costPerCard && scientist.data.rewarded < (this.costPerCard * 2);
        return {
            modal: true,
            status: completed ? 'completed' : 'open',
            content: completed ? `You've helped many people by assisting my research today. Thank you!`
                : balance >= this.costPerCard ? `You have ${balance} samples, come back and I'll give you rewards!`
                : givenDuck ? `My newest invention will help find microbes faster. You can transmogrify into a duck! Ducks swim really fast!`
                : samplesFound ? `Keep searching for microbe samples!`
                : `Hello! I'm a researcher. I'll reward you for microbe samples collected from the local waters for me. What do you think?`,
            emojis: addPersonAttributes(
                this.emojis.config.scientist.icon,
                scientist.config.skinTone,
                scientist.config.gender
            ),
            items: [
                {
                    symbol: this.emojis.config.sample.icon,
                    current: this.balance,
                }
            ]
        } as HudData;
    }
};
