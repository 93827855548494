import { MapApp } from 'maps';
import { ImageDataBuilder } from 'image';
import {
    Coord,
    distance,
    gridToPolar,
    angle,
} from 'coords';
import { GameState } from 'game';
import { Item } from 'plugins/items';
import { Bus } from 'message/Bus';

const __DEVELOPER_DEBUG__ = false;

const ITEM_DIAMETER = 8;
const ITEM_RADIUS = Math.round(
    ITEM_DIAMETER / 2
);
const LINE_START_DIST = 15;
const LINE_LENGTH = 4;
const LINE_END_DIST = LINE_START_DIST + LINE_LENGTH;

export const itemPlugin = (
    state: GameState,
    bus: Bus,
) => (map: MapApp) => {
    const data = new ImageDataBuilder(
        map.renderWidth,
        map.renderHeight
    );
    const drawLine = (item: Item) => {
        const currentAngle = angle(
            map.gps.locationCenter,
            item.loc
        );
        const lineStart: Coord = gridToPolar(
            map.gps.viewCenter,
            LINE_START_DIST,
            currentAngle 
        );
        const lineEnd: Coord = gridToPolar(
            lineStart,
            LINE_LENGTH,
            currentAngle
        );

        data.line(
            lineStart,
            lineEnd,
            item.config.color 
        );
    };

    return {
        postRender: (width: number, height: number) => {
            data.clear();

            const topLeft: Coord = [
                Math.floor(map.gps.location[0]),
                Math.floor(map.gps.location[1])
            ];
            const botRight = [
                topLeft[0] + width,
                topLeft[1] + height,
            ];
            const center = map.gps.locationCenter;

            state.items.items = state.items.items.filter(item => {
                if (item.config.conceal) {
                    return true;
                }
                const withinX = (
                    item.loc[0] > topLeft[0]
                    && item.loc[0] < botRight[0]
                );
                const withinY = (
                    item.loc[1] > topLeft[1]
                    && item.loc[1] < botRight[1]
                );
                if (withinX && withinY) {
                    if (item.config.customRender) {
                        item.config.customRender(
                            data,
                            [
                                item.loc[0] - topLeft[0],
                                item.loc[1] - topLeft[1],
                            ],
                            item
                        );
                    } else {
                        data.disc(
                            [
                                item.loc[0] - topLeft[0] - ITEM_RADIUS,
                                item.loc[1] - topLeft[1] - ITEM_RADIUS,
                            ],
                            item.config.color,
                            ITEM_DIAMETER 
                        );
                    }
                    const dist = distance(
                        center,
                        item.loc
                    );
                    if (!item.found && !item.config.hidePointer && dist > LINE_END_DIST) {
                        drawLine(item);
                    }
                    const pickupDistance = item.config.collectRadius || ITEM_DIAMETER;
                    if (dist < pickupDistance) {
                        item.removed = !item.config.onCollect(map, item);
                        item.found = true;
                        return !item.removed;
                    }
                }
                return true;
            });

            return data;
        },
    };
};


export const fullRenderItemPlugin = (
    state: GameState,
    isOverFog?: boolean,
) => (
    map: MapApp
) => {
    return {
        fullRender: (width: number, height: number) => {
            const image = new ImageDataBuilder(width, height);
            const scaleX = (x: number) => Math.round((x / map.grid.totalWidth) * width);
            const scaleY = (y: number) => Math.round((y / map.grid.totalHeight) * height);

            state.items.items.forEach(item => {
                if (!__DEVELOPER_DEBUG__) {
                    if (
                        !item.config.showOnFullRender
                        || item.removed
                        || item.config.conceal
                    ) {
                        return;
                    }
                    if (state.status.values.atHome && !item.config.showOnFullRenderAtHome) {
                        // don't show most items when you're at home.
                        return;
                    }
                    if (isOverFog && !item.config.showOverFog) {
                        return;
                    }
                }

                if (item.config.customRender) {
                    item.config.customRender(
                        image,
                        [
                            scaleX(item.loc[0]),
                            scaleY(item.loc[1]),
                        ],
                        item
                    );
                } else {
                    image.disc(
                        [
                            scaleX(item.loc[0]) - ITEM_RADIUS,
                            scaleY(item.loc[1]) - ITEM_RADIUS,
                        ],
                        item.config.color,
                        ITEM_DIAMETER 
                    );
                }
            });

            return image;
        },
    };
};
