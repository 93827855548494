
export const marblesOwned = {
    select: () => parseInt(
        window.localStorage.getItem('marblesOwned')
        || '0'
    ) || 0,
    save: (count: number) =>
        window.localStorage.setItem(
            'marblesOwned',
            `${count}`
        ),
};
export const initialStatus = {
    atHome: true,
    saving: false,
    marblesOwned: marblesOwned.select(),
    marblesRemaining: 0,
    toolCandle: false,
    toolFlashlight: false,
    toolCamera: false,
    toolShoes: false,
    toolBoots: false,
    toolGoggles: false,
    toolSurfboard: false,
};

export type Status = typeof initialStatus;
export type StatusSubscriber = (status: Status) => void;
export type OnStatusChange = (
    newValue: boolean | number,
) => void;

export class StatusState {
    values: Status = Object.freeze({ ...initialStatus });
    private subscribers: StatusSubscriber[] = [];
    private handleChange: Partial<
        Record<
            keyof Status,
            OnStatusChange[]
        >
    > = {};

    subscribe = (subscriber: StatusSubscriber) => {
        this.subscribers.push(subscriber);
    };

    onChange = (
        key: keyof Status,
        onChange: OnStatusChange
    ) => {
        if (!this.handleChange[key])
            this.handleChange[key] = [];
        this.handleChange[key]!.push(onChange);
    }

    load = (status: Status) => {
        this.values = Object.freeze({ ...status });
        this.subscribers.forEach(subscriber =>
            subscriber(this.values)
        );
    };

    put = (
        key: keyof Status,
        value: boolean | number
    ) => {
        if (this.values[key] !== value) {
            this.values = Object.freeze({
                ...this.values,
                [key]: value,
            });
            this.subscribers.forEach(subscriber =>
                subscriber(this.values)
            );
            if (this.handleChange[key]?.length) {
                this.handleChange[key]!.forEach(
                    (onChange) => onChange(value)
                );
            }
        }
    };
}
