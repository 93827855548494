import { useMemo } from 'react';
import { Status } from 'game';
import { emojiStrings } from 'image';
import {
    HudRecord,
    Hud,
} from '../questHuds';

const marbleHud = (marblesRemaining: number): Hud => ({
    id: marbleHud.id,
    data: {
        status: !marblesRemaining ? 'completed' : 'open',
        content: (
            !marblesRemaining ? 'Congratulations, you found all the marbles!'
            : marblesRemaining < 10 ? `Only ${marblesRemaining} marbles left!`
            : `There are ${marblesRemaining} valuable marbles lost around the map.`
        ),
        items: [
            {
                symbol: emojiStrings.marble,
                current: marblesRemaining
            }
        ]
    }
});
marbleHud.id = 'marbles';

const shoesHud = (shoes: boolean, boots: boolean): Hud => ({
    id: shoesHud.id,
    data: {
        modal: true,
        status: shoes && boots ? 'completed' : 'open',
        content: boots ? (
            "These boots will help move around rougher terrain."
        ) : shoes ? (
            "I couldn't get around without my shoes. There may be some boots, too."
        ) : (
            "Where are my shoes?! I have to find them if I want to get around. They should be very closeby."
        ),
        items: [
            {
                symbol: emojiStrings.shoes,
                current: shoes ? 1 : 0,
            },
            {
                symbol: emojiStrings.boots,
                current: boots ? 1 : 0,
            },
        ]
    }
});
shoesHud.id = 'shoes';

const lightHud = (candle: boolean, flashlight: boolean): Hud => ({
    id: lightHud.id,
    data: {
        modal: true,
        status: flashlight ? 'completed' : 'open',
        content: (
            flashlight ? (
                "This flashlight will help light my way"
            ) : candle ? (
                "This candle is better than nothing, but I know I lost a flashlight around here somewhere"
            ) : (
                "I can barely see a thing! There should be something nearby to help me see in this darkness."
            )
        ),
        items: [
            {
                symbol: emojiStrings.candle,
                current: candle ? 1 : 0,
            },
            {
                symbol: emojiStrings.flashlight,
                current: flashlight ? 1 : 0,
            },
        ]
    }
});
lightHud.id = 'lights';

const cameraHud = (): Hud => ({
    id: cameraHud.id,
    data: {
        modal: true,
        status: 'open',
        content: 'You found a camera! You don\'t have any film but the flash will help see around you. It has to recharge after use.',
        items: [
            {
                symbol: emojiStrings.camera,
                current: 1,
            },
        ]
    }
});
cameraHud.id = 'camera';

export const useStatusHuds = (
    status: Status,
    huds: HudRecord
): HudRecord => {
    const {
        toolShoes,
        toolBoots,
        toolCandle,
        toolFlashlight,
        toolCamera,
        marblesRemaining,
    } = status;

    return useMemo(() => {
        const camera = !toolCamera
            ? {}
            : { [cameraHud.id]: cameraHud() };
        if (!toolFlashlight) {
            return {
                [shoesHud.id]: shoesHud(toolShoes, toolBoots),
                [lightHud.id]: lightHud(toolCandle, toolFlashlight),
                ...camera,
                ...huds,
            };
        }
        return {
            [shoesHud.id]: shoesHud(toolShoes, toolBoots),
            [lightHud.id]: lightHud(toolCandle, toolFlashlight),
            [marbleHud.id]: marbleHud(marblesRemaining),
            ...camera,
            ...huds,
        };
    }, [
        huds,
        marblesRemaining,
        toolShoes,
        toolBoots,
        toolCandle,
        toolFlashlight,
        toolCamera,
    ]);
};
