import React, {
    FC,
    MutableRefObject,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react'
import { Status } from 'game';
import {
    HudRecord,
    QuestHuds,
    QuestHud,
    useQuestsAdded,
    useQuestsChanged,
    QuestModal,
    QuestModalBackdrop,
    ChangedHuds,
} from '../questHuds';
import { OverviewMap } from './OverviewMap';
import { StatusBar } from './StatusBar';
import { MenuFlyout } from './MenuFlyout';
import { useStatusHuds } from './useStatusHuds';
import { useOnClickOutside } from './useOnClickOutside';
import { Skin, skinData } from 'plugins/player';
import { EmojiText } from 'image';
import { stopJoystick } from 'plugins/location';

const CHROME_WIDTH = '400px';

const Wrap = styled.div`
    position: absolute;
    bottom: .25rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 100vh;
`;
const Chrome = styled.div`
    background-color: rgba(0, 0, 0, .6);
    width: 100vw;
    max-width: ${CHROME_WIDTH};
    display: flex;
    position: relative;
    border-radius: 1.2em;
    padding: .2em;
    gap: .2em;
`;
const Button = styled.button<{ 'data-elevate'?: boolean; }>`
    font-family: OutlineEmoji;
    font-weight: 800;
    font-size: 1.8em;
    background-color: #999999dd;
    color: #333333dd;
    border: none;
    border-radius: 1rem;
    aspect-ratio: 1;
    flex: 1;
    width: 20%;
    box-sizing: border-box;
    :hover {
        background-color: #AAAAAAee;
        color: #222222ee;
    }
    :active {
        background-color: #777777dd;
        color: #333333ee;
    }
    :disabled {
        background-color: #666666cc;
    }
    transition: transform .3s ease;
    ${props => props['data-elevate'] ? `
        transform: translate3d(0, -1rem, 0);
    ` : ''}
`;
const MenuPanelChrome = styled.div<{ 'data-open': boolean; }>`
    background-color: rgba(0, 0, 0, .6);
    border-radius: .3rem;
    transition: all 1s ease;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    margin-bottom: .25rem;
    width: 100%;
    max-width: ${CHROME_WIDTH};

    flex: 1;
    ${props => props['data-open'] ? `
        max-height: 100vh;
    ` : `
        transition-duration: .35s;
        transition-timing-function: cubic-bezier(0.230, 1.000, 0.320, 1.000);
        max-height: 0;
    `}

    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    ::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }
`;
const MenuPanelPadd = styled.div`
    padding: .5rem;
`;

const MenuPanel: FC<{
    open: boolean,
    close: () => void,
    foreignTarget: string,
}> = ({
    open,
    close,
    foreignTarget,
    children
}) => {
    const ref = useOnClickOutside<HTMLDivElement>(
        open,
        close,
        foreignTarget
    );
    return (
        <MenuPanelChrome data-open={open} ref={ref}>
            <MenuPanelPadd>
                {children}
            </MenuPanelPadd>
        </MenuPanelChrome>
    )
};

const questChangedAppearAnim = keyframes`
    from {
        transform: translate3d(0, 1rem, 0) scale(.7);
        opacity: 0;
    }
    10% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
    15% {
        transform: scale(1);
        opacity: 1;
    }
    18% {
        transform: scale(.7) blur(3px);
        opacity: .5;
    }
    22% {
        transform: scale(.7) blur(5px);
        opacity: 0;
    }
    to {
        transform: scale(.7) blur(5px);
        opacity: 0;
    }
`;
const questChangedRemoveAnim = keyframes`
    from {
        transform: scale(.7) blur(5px);
        opacity: 0;
    }
    15% {
        transform: scale(.7) blur(5px);
        opacity: 0;
    }
    18% {
        transform: scale(.7) blur(3px);
        opacity: .5;
    }
    22% {
        transform: scale(1);
        opacity: 1;
    }
    80% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
    to {
        transform: translate3d(0, 1rem, 0) scale(.7);
        opacity: 0;
    }
`;
const ChangedHudWrap = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    max-width: ${CHROME_WIDTH};
    box-sizing: border-box;

    pointer-events: none;
    user-select: none;
    * {
        pointer-events: none;
        user-select: none;
    }
`;
const PlaceholderHud = styled(QuestHud)`
    opacity: 0;
`;
const AppearChangedHud = styled(QuestHud)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    animation: ${questChangedAppearAnim} 5s ease;
    animation-iteration-count: 1;
    opacity: 0;
`;
const RemoveChangedHud = styled(QuestHud)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    animation: ${questChangedRemoveAnim} 5s ease;
    animation-iteration-count: 1;
    opacity: 0;
`;
const SkinButton = styled.div`
    display: flex;
    flex-direction: row;
    margin: .25rem .25rem;
    padding: .25rem;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 0.1rem;
    > p {
        padding: 0;
        margin: 0 .5rem;
    }
`;


export const ToolBar: FC<{
    flashCamera: () => void;
    drawOverview: (ref: MutableRefObject<HTMLCanvasElement | null>) => void;
    setCardPanelOpen: (open: boolean) => void;
    status: Status;
    huds: HudRecord,
    saveGame: (suppressStatus: boolean) => void;
    shareImage: () => void;
    sharing: boolean;
    returnToMenu: () => void;
    seed?: string;
    setSkin: (skin: Skin | undefined) => void;
    getSkin: () => Skin | undefined;
    getEnabledSkins: () => Array<Skin>
}> = ({
    flashCamera,
    drawOverview,
    setCardPanelOpen,
    status,
    huds: externalHuds,
    saveGame,
    shareImage,
    sharing,
    returnToMenu,
    seed,
    setSkin,
    getSkin,
    getEnabledSkins,
}) => {
    const [mapOpen, setMapOpen] = useState<boolean>(false);
    const [flyoutOpen, setFlyoutOpen] = useState<boolean>(false);
    const [hudsOpen, setHudsOpen] = useState<boolean>(false);
    const [skinsOpen, setSkinsOpen] = useState<boolean>(false);
    const huds = useStatusHuds(status, externalHuds);
    const skin = getSkin();
    const enabledSkins = getEnabledSkins();

    const {
        added: addedHuds,
        acknowledgeAdded,
    } = useQuestsAdded(huds);
    const {
        changed: changedHuds,
        acknowledgeChanged,
    } = useQuestsChanged(huds, 5);

    const changedModal: ChangedHuds = useMemo(
        () => changedHuds?.filter(
            changed => changed.current.data.modal
        ),
        [changedHuds]
    );
    const hasModals = (addedHuds && addedHuds.length)
        || (changedModal && changedModal.length);
    const [disableCamera, setDisableCamera] = useState<boolean>(false);
    const handleCamera = useCallback(() => {
        setDisableCamera(true);
        flashCamera();
        setTimeout(() => {
            setDisableCamera(false);
        }, 10000);
    }, [flashCamera]);

    useEffect(() => {
        if (hasModals) {
            stopJoystick();
        }
    }, [hasModals]);

    return <>
        <Wrap>
            {hasModals ? (
                <QuestModalBackdrop>
                    {addedHuds?.map(hud =>
                        <QuestModal
                            hud={hud}
                            key={hud.id}
                            acknowledge={acknowledgeAdded}
                        />
                    )}
                    {changedModal?.map(changed =>
                        <QuestModal
                            hud={changed.current}
                            key={changed.id}
                            acknowledge={acknowledgeChanged}
                        />
                    )}
                </QuestModalBackdrop>
            ) : null}
            {changedHuds && changedHuds.map(changed =>
                changed.current.data.modal
                    ? null
                    : (
                        <ChangedHudWrap key={changed.id}>
                            <PlaceholderHud hud={
                                changed.previous.data.content.length > changed.current.data.content.length
                                    ? changed.previous
                                    : changed.current
                            } />
                            <AppearChangedHud hud={changed.previous} />
                            <RemoveChangedHud hud={changed.current} />
                        </ChangedHudWrap>
                    )
            )}
            <MenuPanel
                foreignTarget="hudsmenu"
                open={hudsOpen}
                close={useCallback(() => setHudsOpen(false), [])}
            >
                <QuestHuds huds={huds} />
            </MenuPanel>
            <MenuPanel
                foreignTarget="skinsmenu"
                open={skinsOpen}
                close={useCallback(() => setSkinsOpen(false), [])}
            >
                {Object.keys(skinData).map((skinName) =>
                    !enabledSkins.includes(skinName as Skin) ? null :
                        <SkinButton
                            key={skinName}
                            onClick={() => {
                                setSkin && setSkin(skinName as Skin);
                                setSkinsOpen(false);
                            }}
                        >
                            <EmojiText size="2rem">{skinData[skinName as Skin].symbol}</EmojiText>
                            <p>
                                {skinData[skinName as Skin].text}
                                {skin === skinName
                                    ? <EmojiText outline={true}>✅</EmojiText>
                                    : ''}
                            </p>
                        </SkinButton>
                )}
                <SkinButton
                    onClick={useCallback(
                        () => {
                            setSkin && setSkin(undefined);
                            setSkinsOpen(false);
                        },
                        [setSkin]
                    )}
                >
                    <p>
                        Normal Clothes
                        {!skin && <EmojiText outline={true}>✅</EmojiText>}
                    </p>
                </SkinButton>
            </MenuPanel>
            <MenuFlyout
                open={flyoutOpen}
                saveGame={saveGame}
                shareImage={shareImage}
                sharing={sharing}
                returnToMenu={returnToMenu}
                seed={`${seed}`}
                closeFlyout={useCallback(
                    () => setFlyoutOpen(false),
                    []
                )}
            />
            <StatusBar status={status} />
            <Chrome>
                <Button onClick={useCallback(() => setMapOpen(true), [])}>
                    🗺 
                </Button>
                <Button onClick={useCallback(
                    () => setCardPanelOpen(true),
                    [setCardPanelOpen]
                )}>
                    🎴
                </Button>
                {status.toolCamera && (
                    <Button
                        onClick={handleCamera}
                        disabled={disableCamera}
                    >
                        📸
                    </Button>
                )}
                {enabledSkins.length > 0 && (
                    <Button
                        data-elevate={skinsOpen}
                        onClick={() => setSkinsOpen(o => !o)}
                        data-clickoutsideforeign="skinsmenu"
                    >
                        👕
                    </Button>
                )}
                <Button
                    data-elevate={!!addedHuds?.length || !!changedHuds?.length || hudsOpen}
                    onClick={useCallback(() => setHudsOpen(o => !o), [])}
                    data-clickoutsideforeign="hudsmenu"
                >
                    ⚔
                </Button>
                <Button
                    onClick={useCallback(
                        () => setFlyoutOpen(o => !o),
                        []
                    )}
                    data-elevate={flyoutOpen}
                    data-clickoutsideforeign="gamemenu"
                >
                    ⚙
                </Button>
            </Chrome>
        </Wrap>
        <OverviewMap
            draw={drawOverview}
            show={mapOpen}
            close={useCallback(() => setMapOpen(false), [])}
        />
    </>;
};

