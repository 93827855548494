import { useRef, useEffect } from 'react';

export const useOnClickOutside = <T extends HTMLElement>(
    open: boolean,
    close: () => void,
    foreignTarget: string,
) => {
    const closableRef = useRef<T>(null);

    useEffect(() => {
        if (!open) {
            return;
        }
        const flyout = closableRef!.current;
        
        const handler = (ev: MouseEvent | TouchEvent) => {
            const target = ev.target as Node;

            if (target && flyout && flyout.contains(target)) {
                return;
            }
            // @ts-ignore
            if (target && target.closest(`[data-clickoutsideforeign="${foreignTarget}"]`)) {
                return;
            }
            close();
        };
        window.addEventListener('click', handler);
        window.addEventListener('touchstart', handler);
        return () => {
            window.removeEventListener('click', handler);
            window.removeEventListener('touchstart', handler);
        };
    }, [open, close, foreignTarget]);

    return closableRef;
};