import { MapApp } from 'maps';
import { ImageDataBuilder } from 'image';
import {
    Coord,
    loopRectangle,
} from 'coords';
import { theme } from 'color';
import { GameState } from 'game';
import { Level } from 'maps/Levels';

export const roadPlugin = (
    state: GameState,
) => (map: MapApp) => {
    const image = new ImageDataBuilder(
        map.renderWidth,
        map.renderHeight
    );

    return {
        pixelRenderer: {
            image,
            renderStart: () => image.clear(),
            onRenderPixel: (
                absCoord: Coord,
                screenCoord: Coord,
                level: Level
            ) => {
                if (map.isOnRoad(absCoord)) {
                    if (level.depth <= 2)
                        image.put(screenCoord, theme.scenes.bridge);
                    else
                        image.put(screenCoord, theme.scenes.road);

                    map.entities.some(entity => {
                        const color = entity.render(absCoord, level);
                        if (color) {
                            image.put(screenCoord, color);
                            return true;
                        }
                        return false;
                    });
                }
            },
        },
        // postRender: (width: number, height: number) => {
        //     image.clear();

        //     // const leftEdge: Line = [
        //     //     map.gps.location,
        //     //     [
        //     //         map.gps.location[0],
        //     //         map.gps.location[1] + map.gps.viewDim[1],
        //     //     ]
        //     // ];
        //     // const rightEdge: Line = [
        //     //     [
        //     //         map.gps.location[0] + map.gps.viewDim[0],
        //     //         map.gps.location[1],
        //     //     ],
        //     //     [
        //     //         map.gps.location[0] + map.gps.viewDim[0],
        //     //         map.gps.location[1] + map.gps.viewDim[1],
        //     //     ]
        //     // ]

        //     // map.roads.forEach((road: Line) => {
        //     //     const leftIntersect = lineIntersect(road, leftEdge);
        //     //     const rightIntersect = lineIntersect(road, rightEdge);
        //     //     if (leftIntersect || rightIntersect) {
        //     //         image.rect(
        //     //             leftIntersect ? 0 : road[0][0],
        //     //             theme.scenes.road,
        //     //             leftIntersect && rightIntersect ? map.gps.viewDim[0] : FUCK, WHAT A MESS
        //     //         );
        //     //     }
        //     // });

        //     loopRectangle(width, height)(coord => {
        //         const absCoord: Coord = [
        //             coord[0] + Math.floor(map.gps.location[0]),
        //             coord[1] + Math.floor(map.gps.location[1]),
        //         ];
        //         if (map.isOnRoad(absCoord)) {
        //             image.put(coord, theme.scenes.road);
        //         }
        //     });

        //     return image;
        // },
        fullRender: (width: number, height: number) => {
            const image = new ImageDataBuilder(width, height);
            const scaleX = (x: number) => Math.round((x / width) * map.grid.totalWidth);
            const scaleY = (y: number) => Math.round((y / height) * map.grid.totalHeight);

            loopRectangle(width, height)(coord => {
                const scaledCoord: Coord = [
                    scaleX(coord[0]),
                    scaleY(coord[1]),
                ];
                if (map.isOnRoad(scaledCoord)) {
                    image.put(
                        coord,
                        theme.scenes.road
                    );
                }
            });

            return image;
        },
    };
};

