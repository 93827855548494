import {
    useState,
    useEffect,
    useRef,
    useCallback,
} from 'react';
import {
    HudRecord,
    Hud,
} from './types';

export const useQuestsAdded = (
    huds: HudRecord,
) => {
    const [added, setAdded] = useState<Hud[] | undefined>(undefined);
    const previousIdsRef = useRef<string[]>([]);

    useEffect(() => {
        const allIds = Object.keys(huds);
        const newIds = allIds.filter(id =>
            previousIdsRef.current.indexOf(id) === -1 && huds[id].data.status !== 'completed'
        );

        if (newIds && newIds.length) {
            setAdded(a => 
                (a || [] as Hud[]).concat(
                    newIds.reduce(
                        (acc, hudId) => {
                            acc.push(huds[hudId]);
                            return acc;
                        },
                        [] as Hud[]
                    )
                )
            );
        }
        previousIdsRef.current = allIds;
    }, [huds]);

    return {
        added,
        acknowledgeAdded: useCallback(
            (removeId: string) =>
                setAdded(currentAdded =>
                    currentAdded?.filter(hud =>
                        hud.id !== removeId
                    )
                ),
            []
        ),
    };
};
