import { Scene } from "./ScenesState";
import { EmojiStringImageEncoder } from "image";
import { Item, ItemConfig } from "plugins/items";
import { MapApp } from "maps";
import { theme } from "color";
import { HudData } from "components";
import { Skin, skinData, skinSize } from "plugins/player";
import { distFromCoordMinMax } from "coords";

type Stage = | 'unstarted'
    | 'elephantTouched'
    | 'wizardTouched'
    | 'transformed'
    | 'finished';

const getStage = (
    elephant: boolean,
    wizard: boolean,
    destination: boolean,
    skin: Skin | undefined,
): Stage => {
    if (elephant && wizard && destination) {
        return 'finished';
    }
    if (elephant && wizard && skin === 'elephant') {
        return 'transformed';
    }
    if (elephant && wizard) {
        return 'wizardTouched';
    }
    if (elephant) {
        return 'elephantTouched';
    }
    return 'unstarted';
};

export class Elephant extends Scene<[
    'elephant',
    'wizard',
    'destination',
]> {
    totalMonsters = 50;
    hudId = 'elephantHud';
    enableSkin: (skin: Skin) => void;
    setSkin: (skin: Skin | undefined) => void;
    getSkin: () => Skin | undefined;
    constructor(
        emojiImage: EmojiStringImageEncoder,
        enableSkin: (skin: Skin) => void,
        setSkin: (skin: Skin | undefined) => void,
        getSkin: () => Skin | undefined,
    ) {
        super(
            emojiImage.makePlugin({
                elephant: {
                    icon: skinData.elephant.symbol,
                    size: skinSize,
                    shrink: 2,
                },
                wizard: {
                    icon: '🧙',
                    size: 12,
                    shrink: 2,
                    person: true,
                }, // or a fairy? 🧚
                destination: {
                    icon: '🎪',
                    size: 20,
                    shrink: 2,
                },
            })
        );
        this.enableSkin = enableSkin;
        this.setSkin = setSkin;
        this.getSkin = getSkin;
    }
    getPrimaryItemsConfig(map: MapApp): ItemConfig[] {
        const itemMinMax = map.gps.generateFromCenter();

        const possibleDestinations: Array<Array<1 | 2 | 3 | 4>> = [
            [1, 2],
            [2, 4],
            [4, 3],
            [3, 1],
        ];
        const destination = map.ints.next(0, possibleDestinations.length);

        return [
            {
                name: 'elephant',
                ...itemMinMax(15),
                minDist: 50,
                minLevel: 3,
                color: theme.player.core,
                customRender: this.emojis.makeRenderer('elephant'),
                showOnFullRender: true,
                onCollect: this.onTouchElephant,
            },
            {
                name: 'destination',
                ...map.gps.zoomMinMaxQuandrant.apply(
                    map.gps,
                    possibleDestinations[destination]
                ),
                minLevel: 3,
                color: theme.player.core,
                customRender: this.emojis.makeRenderer('destination'),
                showOnFullRender: true,
                showOnFullRenderAtHome: true,
                showOverFog: true,
                conceal: true,
                onCollect: this.onTouchDestination,
            },
        ];
    }
    getSecondaryItemsConfig(map: MapApp): ItemConfig[] {
        const elephant = this.primary('elephant');

        return [
            {
                name: 'wizard',
                ...distFromCoordMinMax(elephant.loc, 50),
                minDist: 10,
                minMaxDistFrom: elephant.loc,
                minLevel: 3,
                color: theme.player.core,
                conceal: true,
                customRender: this.emojis.makeRenderer('wizard'),
                onCollect: this.onTouchWizard
            }
        ];
    };
    onTouchWizard = (map: MapApp, wizard: Item) => {
        if (!wizard.found) {
            this.updateHud();
        }
        return true;
    };
    onTouchDestination = () => {
        this.setSkin(undefined);
        this.enableSkin('elephant');
        this.updateHud();
    };
    onTouchElephant = () => {
        const wizard = this.secondary('wizard');

        if (!wizard.found) {
            if (wizard.config.conceal) {
                this.updateHud();
                wizard.config.conceal = false;
            }
            return true;
        }

        this.setSkin('elephant');
        this.primary('destination').config.conceal = false;

        this.updateHud();
        return false;
    };
    getHudData(): HudData | undefined {
        const elephant = this.primary('elephant').found;

        if (!elephant) {
            return undefined;
        }

        const wizard = this.secondary('wizard').found;
        const destination = this.primary('destination').found;

        const stage = getStage(elephant, wizard, destination, this.getSkin());

        return {
            modal: true,
            status: stage === 'finished' ? 'completed' : 'open',
            content: stage === 'elephantTouched' ? (
                'RRRrrrraaaaaarrrrrrmmmmm... (Where did this elephant come from?)'
            ) : stage === 'wizardTouched' ? (
                'Hello traveler! You can get around this world much better using MAGIC. Want to try?! Touch that elephant again.'
            ) : stage === 'transformed' ? (
                `ISN'T BEING AN ELEPHANT FUN? Reach the spot marked on your map to change back!`
            ) : (
                'That was fun! Now you can become an elephant whenever you want. Watch for other magic around the world to gain more power!'
            ),
            emojis: stage === 'wizardTouched' || stage === 'finished' ? (
                this.emojis.config.wizard.icon
            ) : (
                this.emojis.config.elephant.icon
            ),
            items: [
                {
                    symbol: stage === 'elephantTouched' ? (
                        this.emojis.config.wizard.icon
                    ) : stage === 'wizardTouched' ? (
                        this.emojis.config.elephant.icon
                    ) : stage === 'transformed' ? (
                        this.emojis.config.destination.icon
                    ) : (
                        this.emojis.config.elephant.icon
                    ),
                    current: stage === 'finished' ? 1 : 0,
                },
            ]
        }
    }
};
