import styled from '@emotion/styled';
import { Coord } from 'coords';
import {
    // GenerationProgress,
    ProgressGenerator,
} from 'generate';
import { ImageDataBuilder } from './ImageDataBuilder';
import { Item } from 'plugins/items';

export enum SkinToneModifiers {
    skinTone2 = '\u{1f3fb}', // "🏻", // skin type 1-2
    skinTone3 = '\u{1f3fc}', // "🏼", // skin type 3
    skinTone4 = '\u{1f3fd}', // "🏽", // skin type 4
    skinTone5 = '\u{1f3fe}', // "🏾", // skin type 5
    skinTone6 = '\u{1f3ff}', // "🏿", // skin type 6
};
export type SkinToneName = keyof typeof SkinToneModifiers;
export const skinToneNames = Object.keys(SkinToneModifiers) as SkinToneName[];
export const randomSkinToneName = (): SkinToneName => skinToneNames[
    Math.floor(Math.random() * skinToneNames.length)
];

export enum Gender {
    male = '👨',
    female = '👩',
    nb = '🧑',
};
export type GenderName = keyof typeof Gender;
export const genderNames = Object.keys(Gender) as GenderName[];
export const randomGender = (): GenderName => genderNames[
    Math.floor(Math.random() * genderNames.length)
];

const zwj = '\u{200D}';
export const addPersonAttributes = (
    personSymbol: string,
    skinToneName?: SkinToneName,
    genderName?: GenderName,
): string => {
    if (!skinToneName || !genderName) {
        return personSymbol;
    }
    const iconParts = personSymbol.split(zwj);
    const modifier = SkinToneModifiers[skinToneName as SkinToneName];

    const isGenderFirst = Object.values(Gender)
        .includes(iconParts[0] as Gender)

    if (isGenderFirst) {
        const gender = Gender[genderName as GenderName];

        return [
            gender + modifier,
            ...iconParts.slice(1)
        ].join(zwj);
    }
    return [
        iconParts[0] + modifier,
        ...iconParts.slice(1)
    ].join(zwj);
}

export const EmojiText = styled.i<{
    size?: string;
    outline?: boolean;
}>`
    font-family: ${props => props.outline ? 'OutlineEmoji' : 'CustomEmoji'};
    font-style: normal;
    ${props => props.size ? `font-size: ${props.size};` : ''}
`;

export const emojiStrings = {
    candle: '🕯️',
    flashlight: '🔦',
    camera: '📸',
    shoes: '👟',
    boots: '🥾',
    goggles: '🤿',
    marble: '🔴',
    lantern: '🏮',
    surf: '🏄',
    tree01: '🌳',
    tree02: '🌲',
    home01: '🏚',
    mountain: '🏔',
};

type EmojiName = keyof typeof emojiStrings;
export type EmojiImageDatas = Record<EmojiName, ImageData>;

export const emojiSizes: Record<EmojiName, {
    size: number;
    shrink: number;
}> = {
    candle: { size: 12, shrink: 2 },
    flashlight: { size: 12, shrink: 2 },
    camera: { size: 12, shrink: 2 },
    shoes: { size: 12, shrink: 2 },
    boots: { size: 12, shrink: 2 },
    goggles: { size: 12, shrink: 2 },
    marble: { size: 10, shrink: 6 },
    lantern: { size: 16, shrink: 2 },
    surf: { size: 12, shrink: 2 },
    tree01: { size: 12, shrink: 2 },
    tree02: { size: 12, shrink: 2 },
    home01: { size: 24, shrink: 2 },
    mountain: { size: 24, shrink: 2 },
};

export type EmojiPluginConfig<PluginEmojiNames extends string[]> = Record<
    PluginEmojiNames[number],
    {
        icon: string,
        size: number,
        shrink: number,
        person?: boolean,
    }
>;

export type EmojiPlugin<PluginEmojiNames extends string[]> = {
    apply: (
        key: PluginEmojiNames[number],
        data: ImageDataBuilder,
        itemDataLocation: Coord,
        skinTone?: SkinToneName,
        gender?: GenderName,
    ) => void;
    makeRenderer: (
        key: PluginEmojiNames[number],
    ) => (
        data: ImageDataBuilder,
        itemDataLocation: Coord,
        item: Item,
    ) => void;
    config: EmojiPluginConfig<PluginEmojiNames>,
    generated: boolean;
}

export class EmojiStringImageEncoder {
    canvas: HTMLCanvasElement;
    context: CanvasRenderingContext2D;
    imageData: EmojiImageDatas | undefined;
    plugins: Array<{
        config: EmojiPluginConfig<any>,
        imageData: Record<string, ImageData>,
        api: EmojiPlugin<any>,
    }>;

    constructor() {
        this.canvas = document.createElement('canvas');
        this.context = this.canvas.getContext('2d', { willReadFrequently: true })!;
        this.plugins = [];
        // this.canvas.width = size;
        // this.canvas.height = size;
        // const ratio = window.devicePixelRatio;
        // this.canvas.width = size * ratio;
        // this.canvas.height = size * ratio;
        // this.context.scale(ratio, ratio);
    }
    makePlugin = <PluginEmojiNames extends string[], >(
        config: EmojiPluginConfig<PluginEmojiNames>
    ): EmojiPlugin<PluginEmojiNames> => {
        const plugin = { config, imageData: {} };

        const pluginApi: EmojiPlugin<PluginEmojiNames> = {
            generated: false,
            apply: (
                baseKey: PluginEmojiNames[number],
                data: ImageDataBuilder,
                itemDataLocation: Coord,
                skinTone?: SkinToneName,
                gender?: GenderName,
            ) => {
                const imageData = plugin.imageData as Record<
                    string,
                    ImageData
                >;
                const key = skinTone && gender ? `${baseKey}-${gender}-${skinTone}` : baseKey
                if (!imageData[key]) {
                    throw new Error(`cannot render ${key}`);
                }
                const size = plugin.config[baseKey].size;

                const halfSize = Math.round(size * .5);
                let emojiData: ImageData = imageData[key];
                data.overlay(
                    emojiData,
                    [
                        itemDataLocation[0] - halfSize,
                        itemDataLocation[1] - halfSize,
                    ]
                );
            },
            makeRenderer: (
                key: PluginEmojiNames[number],
            ) => (
                data: ImageDataBuilder,
                itemDataLocation: Coord,
                item: Item
            ) => pluginApi.apply(
                key,
                data,
                itemDataLocation,
                item.config.skinTone,
                item.config.gender
            ),
            config,
        };
        this.plugins.push({
            ...plugin,
            api: pluginApi,
        });
        return pluginApi;
    }
    apply = (
        key: EmojiName,
        data: ImageDataBuilder,
        itemDataLocation: Coord,
        item?: Item,
    ) => {
        if (!this.imageData) {
            throw new Error(`cannot render ${key}`);
        }
        const halfSize = Math.round(this.size(key).size * .5);
        let emojiData: ImageData = this.imageData[key];
        data.overlay(
            emojiData,
            [
                itemDataLocation[0] - halfSize,
                itemDataLocation[1] - halfSize,
            ]
        );
    }

    size = (key: EmojiName) => emojiSizes[key];

    makeApply = (key: EmojiName) => (
        data: ImageDataBuilder,
        itemDataLocation: Coord,
        item: Item,
    ) => this.apply(key, data, itemDataLocation, item);

    generateImageData = (
        emoji: string,
        size: number,
        shrink: number
    ) => {
        const halfSize = Math.round(size * .5);
        const y = Math.round(size - (shrink / 2));
        this.canvas.width = size;
        this.canvas.height = size;
        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

        this.context.imageSmoothingEnabled = false;
        this.context.textAlign = 'center';
        this.context.textBaseline = 'bottom';
        this.context.font = `${size - shrink}px CustomEmoji`;

        // // bounding box
        // this.context.fillStyle = "blue";
        // this.context.fill();
        // this.context.rect(0, 0, this.size, this.size);

        this.context.fillText(emoji, halfSize, y);
        return this.context.getImageData(0, 0, this.canvas.width, this.canvas.height);
    }

    async * generate(): ProgressGenerator {
        const keys = Object.keys(emojiStrings);

        yield await new Promise(resolve => setTimeout(() => resolve([0, keys.length]), 0));

        const data: Record<EmojiName, any> = { ...emojiStrings };
        let totalCount: number = keys.length;

        this.plugins.forEach(plugin => 
            totalCount += Object.keys(plugin.config).length
        )

        for (let i = 0; i < keys.length; i++) {
            const key = keys[i] as EmojiName;
            const size = this.size(key);
            data[key] = this.generateImageData(emojiStrings[key], size.size, size.shrink);
            // eslint-disable-next-line no-loop-func
            yield await new Promise(resolve => setTimeout(() => resolve([i, totalCount]), 100));
        }
        this.imageData = data;

        let added: number = keys.length;
        for (let i = 0; i < this.plugins.length; i++) {
            const plugin = this.plugins[i];
            const keys = Object.keys(plugin.config);
            for (let k = 0; k < keys.length; k++) {
                const key = keys[k];
                const config = plugin.config[key];
                plugin.imageData[key] = this.generateImageData(
                    config.icon,
                    config.size,
                    config.shrink
                );
                if (config.person) {
                    genderNames.forEach(genderName => {
                        skinToneNames.forEach(skinToneName => {
                            plugin.imageData[key + `-${genderName}-${skinToneName}`] = this.generateImageData(
                                addPersonAttributes(
                                    config.icon,
                                    skinToneName as SkinToneName,
                                    genderName as GenderName
                                ),
                                config.size,
                                config.shrink
                            );
                        });
                    })
                }
                added++;
                // eslint-disable-next-line no-loop-func
                yield await new Promise(resolve => setTimeout(() => resolve([added, totalCount]), 100));
            }
            plugin.api.generated = true;
        }

        return [keys.length, keys.length];
    }
}
