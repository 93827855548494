import { theme } from 'color';
import { Coord, distFromCoordMinMax } from 'coords';
import { addPersonAttributes, EmojiStringImageEncoder, ImageDataBuilder } from 'image';
import { MapApp } from 'maps';
import { Item, ItemConfig } from 'plugins/items';
import { Bus } from 'message/Bus';
import { Scene } from './ScenesState';
import { HudData } from 'components';
import { Skin } from "plugins/player";


export class BurningHouse extends Scene<[
    'fire',
    'house',
    'personSad',
    'personHappy',
    'water',
]> {
    distPercent: number;
    minDist: number;
    onFinish: () => void;
    bus: Bus;
    enableSkin: (skin: Skin) => void;
    setSkin: (skin: Skin | undefined) => void;
    constructor(
        emojiImage: EmojiStringImageEncoder,
        distPercent: number,
        minDist: number,
        onFinish: () => void,
        bus: Bus,
        enableSkin: (skin: Skin) => void,
        setSkin: (skin: Skin | undefined) => void,
    ) {
        super(
            emojiImage.makePlugin({
                fire: { icon: '🔥', size: 12, shrink: 2 },
                house: { icon: '🏚', size: 16, shrink: 2 },
                personSad: { icon: '🙍', size: 12, shrink: 2, person: true },
                personHappy: { icon: '🙇', size: 12, shrink: 2, person: true },
                water: { icon: '🪣', size: 12, shrink: 2 },
            })
        );
        this.distPercent = distPercent;
        this.minDist = minDist;
        this.onFinish = onFinish;
        this.bus = bus;
        this.enableSkin = enableSkin;
        this.setSkin = setSkin;
    }

    hudId = 'burningHouseHud';
    getHudData() {
        const person = this.secondary('person');
        if (!person.found) {
            return undefined;
        }
        const fires = this.allSecondary('fire');
        const firesRemaining = fires.filter(fire => !fire.removed).length;
        const hasWater = !!this.secondary('water').found;

        return {
            status: person.removed ? 'completed' : 'open',
            content: person.removed ? (
                "Will you watch after my horse until I can rebuild?"
            ) : !firesRemaining ? (
                "Thank you for saving my home! Return to me for a reward."
            ) : hasWater ? (
                "You have the bucket, now put out the fire with it!"
            ) : (
                "I lost a bucket around here somewhere, we can use it to put out the fire!"
            ),
            emojis: !firesRemaining ? (
                addPersonAttributes(
                    this.emojis.config.personHappy.icon,
                    person.config.skinTone,
                    person.config.gender
                )
            ) : hasWater ? (
                `${this.emojis.config.water.icon}${this.emojis.config.fire.icon}`
            ) : (
                addPersonAttributes(
                    this.emojis.config.personSad.icon,
                    person.config.skinTone,
                    person.config.gender
                )
            ),
            items: [
                {
                    symbol: this.emojis.config.fire.icon,
                    current: firesRemaining,
                    total: fires.length,
                },
                {
                    symbol: this.emojis.config.water.icon,
                    current: hasWater ? 1 : 0,
                },
            ],
        } as HudData;
    }

    hasFire = () => !!this.allSecondary('fire').some(
        fire => !fire.removed
    )

    onTouchPerson = (map: MapApp, person: Item) => {
        if (this.hasFire()) {
            this.bus.addQuiet('help, my house is on fire!', 2);
            this.secondary('water').config.conceal = false;
            this.updateHud();
        } else {
            this.updateHud();
            this.onFinish();
            this.enableSkin('horse');
            this.setSkin('horse');
            return false;
        }
        return true;
    }
    onTouchFire = (map: MapApp, fire: Item) => {
        if (!this.secondary('water').found) {
            this.bus.addQuiet('you have to find something to put out the fire!', 3);
            return true;
        }
        this.updateHud();
        return false;
    }

    getPrimaryItemsConfig(map: MapApp): ItemConfig[] {
        const itemMinMax = map.gps.generateFromCenter();

        return [
            {
                name: 'house',
                ...itemMinMax(this.distPercent),
                minDist: this.minDist,
                minLevel: 3,
                color: theme.player.core,
                onCollect: () => true,
                customRender: this.emojis.makeRenderer('house'),
                minOtherItemDist: 2,
            }
        ];
    }
    getSecondaryItemsConfig(map: MapApp) {
        const house = this.primary('house');

        return [
            ...(
                new Array(5).fill({
                    name: 'fire',
                    ...distFromCoordMinMax(house.loc, 10),
                    minDist: 5,
                    minMaxDistFrom: house.loc,
                    minLevel: 3,
                    color: theme.player.core,
                    onCollect: this.onTouchFire,
                    hidePointer: true,
                    customRender: this.emojis.makeRenderer('fire'),
                    minOtherItemDist: 1,
                })
            ),
            {
                name: 'person',
                ...distFromCoordMinMax(house.loc, 20),
                minDist: 10,
                minMaxDistFrom: house.loc,
                minLevel: 3,
                color: theme.player.core,
                onCollect: this.onTouchPerson,
                hidePointer: true,
                generatePerson: true,
                minOtherItemDist: 10,
                customRender: (builder: ImageDataBuilder, coord: Coord, person: Item) => {
                    return this.hasFire()
                        ? this.emojis.apply('personSad', builder, coord, person.config.skinTone, person.config.gender)
                        : this.emojis.apply('personHappy', builder, coord, person.config.skinTone, person.config.gender);
                },
            },
            {
                name: 'water',
                ...distFromCoordMinMax(house.loc, 100),
                minDist: 50,
                minMaxDistFrom: house.loc,
                minLevel: 3,
                color: theme.player.core,
                onCollect: () => {
                    this.bus.addQuiet('you have water, now put out the fire!', 5);
                    this.updateHud();
                    return false;
                },
                hidePointer: true,
                conceal: true,
                customRender: this.emojis.makeRenderer('water'),
            },
        ];
    }
}

