import { Coord, Dim } from './types';

export class Gps {
    location: Coord = [0, 0];
    targetLoc: Coord = [0, 0];
    totalDim: Dim = [2000, 2000];
    viewDim: Dim = [0, 0];
    totalCenter: Coord;

    constructor() {
        this.totalCenter = [
            Math.round(this.totalDim[0] / 2),
            Math.round(this.totalDim[1] / 2),
        ];
    }

    centerOnCalibrate: boolean = true;
    calibrate = (viewDim: Dim) => {
        this.viewDim = viewDim;

        if (this.centerOnCalibrate) {
            this.setLocation(this.totalViewCenter);
            this.location = this.targetLoc;
        }
    }

    load = (location: Coord) => {
        this.location = location;
        this.targetLoc = location;
        this.centerOnCalibrate = false;
    }

    setLocation = (coord: Coord) => {
        const right = this.totalDim[0] - this.viewDim[0];
        const bottom = this.totalDim[1] - this.viewDim[1];
        if (coord[0] < 0) {
            coord[0] = 0;
        } else if (coord[0] > right) {
            coord[0] = right; 
        }
        if (coord[1] < 0) {
            coord[1] = 0;
        } else if (coord[1] > bottom) {
            coord[1] = bottom;
        }
        this.targetLoc = [
            coord[0],
            coord[1]
        ];
    };

    apply = (multiplier?: number) => {
        if (
            this.location[0] === this.targetLoc[0]
            && this.location[1] === this.targetLoc[1]
        ) return;

        if (!multiplier) {
            multiplier = .3;
        }

        this.location = [
            this.location[0] - ((this.location[0] - this.targetLoc[0]) * multiplier),
            this.location[1] - ((this.location[1] - this.targetLoc[1]) * multiplier),
        ];
    };

    get totalViewCenter(): Coord {
        return [
            (this.totalDim[0] / 2) - (this.viewDim[0] / 2),
            (this.totalDim[1] / 2) - (this.viewDim[1] / 2),
        ];
    }

    get viewCenter(): Coord {
        return [
            Math.round(this.viewDim[0] / 2),
            Math.round(this.viewDim[1] / 2),
        ];
    };

    get locationCenter(): Coord {
        const viewCenter = this.viewCenter;
        return [
            Math.floor(this.location[0] + viewCenter[0]),
            Math.floor(this.location[1] + viewCenter[1]),
        ];
    };

    generateFromCenter() {
        const radiusX = this.totalDim[0] * 0.5;
        const radiusY = this.totalDim[1] * 0.5;

        return (percentDist: number) => {
            const maxX = (percentDist / 100) * radiusX;
            const maxY = (percentDist / 100) * radiusY;

            return {
                min: [
                    radiusX - maxX,
                    radiusY - maxY,
                ] as Coord,
                max: [
                    radiusX + maxX,
                    radiusY + maxY,
                ] as Coord,
            };
        };
    }

    zoomMinMaxQuandrant(
        ...quadrants: Array<1 | 2 | 3 | 4>
    ) {
        let basis = this.minMaxInQuadrant(quadrants[0]);
        for (let i = 1; i < quadrants.length; i++) {
            basis = this.minMaxInQuadrant(quadrants[1], basis);
        }
        return basis;
    }

    minMaxInQuadrant(
        quadrant: 1 | 2 | 3 | 4,
        basis?: { min: Coord, max: Coord },
    ) {
        // quadrants:
        // 1, 2
        // 3, 4
        basis = basis || {
            min: [0, 0] as Coord,
            max: this.totalDim as Coord,
        };
        const dim: Dim = basis
            ? [
                basis.max[0] - basis.min[0],
                basis.max[1] - basis.min[1],
            ]
            : this.totalDim;
        const quadWidth = dim[0] * 0.5;
        const quadHeight = dim[1] * 0.5;
        const right = quadrant === 2 || quadrant === 4;
        const bottom = quadrant === 3 || quadrant === 4;

        return {
            min: [
                basis.min[0] + (right ? quadWidth : 0),
                basis.min[1] + (bottom ? quadHeight : 0),
            ] as Coord,
            max: [
                basis.min[0] + (right ? dim[0] : quadWidth),
                basis.min[1] + (bottom ? dim[1] : quadHeight),
            ] as Coord,
        };
    }

};
