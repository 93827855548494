import {
    Coord,
    loopRectangle,
    loopDisc,
    coordToIndex,
} from 'coords';
import { Color, combineColors } from 'color';

export class ImageDataBuilder {

    data: Uint8ClampedArray;
    width: number;
    height: number;
    smallContext: CanvasRenderingContext2D;

    constructor(width: number, height: number) {
        this.data = new Uint8ClampedArray(
            (width*height)*4
        );
        this.width = width;
        this.height = height;

        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;

        this.smallContext = canvas.getContext('2d')!;
    };

    put = (
        coord: Coord,
        [r, g, b, a]: Color
    ) => {
        if (
            coord[0] < 0 ||
            coord[0] >= this.width ||
            coord[1] < 0 ||
            coord[1] >= this.height
        ) {
            // can we safely ignore "puts" when they are out of bounds?
            // or should we be more noisy and error so upstream
            // consumers can detect failures?
            return;
        }
        const baseIndex = coordToIndex(this.width, coord) * 4;
        this.data[baseIndex] = r;
        this.data[baseIndex + 1] = g;
        this.data[baseIndex + 2] = b;
        this.data[baseIndex + 3] = a;
        // this.data.set([r, g, b, 255], baseIndex);
        // console.log("does array update?", r === this.data[baseIndex]);
    };

    at = (coord: Coord): Color => {
        const baseIndex = coordToIndex(this.width, coord) * 4;
        
        return [
            this.data[baseIndex],
            this.data[baseIndex + 1],
            this.data[baseIndex + 2],
            this.data[baseIndex + 3],
        ];
    };

    fill = ([r, g, b, a]: Color) => {
        for (let i = 0; i < this.data.length; i+=4) {
            this.data[i] = r;
            this.data[i + 1] = g;
            this.data[i + 2] = b;
            this.data[i + 3] = a;
        }
    };

    clear = () => {
        // for (let i = 0; i < this.data.length; i+=4) {
        //     this.data[i + 3] = 0;
        // }
        this.data.fill(0);
    };

    rect = (
        topLeft: Coord,
        color: Color,
        width: number,
        height?: number,
    ) => loopRectangle(width, height || width, topLeft)(coord =>
        this.put(coord, color)
    );

    disc = (
        topLeft: Coord,
        color: Color,
        width: number,
    ) => loopDisc(width, topLeft)(coord =>
        this.put(coord, color)
    );

    line = (
        start: Coord,
        end: Coord,
        color: Color,
    ) => {
        // https://stackoverflow.com/a/4672319
        let [x0, y0] = start;
        const [x1, y1] = end;
        const dx = Math.abs(x1 - x0);
        const dy = Math.abs(y1 - y0);
        const sx = (x0 < x1) ? 1 : -1;
        const sy = (y0 < y1) ? 1 : -1;
        let err = dx - dy;
    
        while (x0 !== x1 || y0 !== y1) {
            this.put([x0, y0], color);
    
            let e2 = 2*err;
            if (e2 > -dy) {
                err -= dy;
                x0 += sx;
            }
            if (e2 < dx) {
                err += dx;
                y0 += sy;
            }
        }
    };

    overlay = (data: ImageData, at: Coord = [0, 0]) => {
        const getPixel = (x: number, y: number): Color => {
            let index = (x + y * data.width) * 4;

            return [
                data.data[index],
                data.data[index + 1],
                data.data[index + 2],
                data.data[index + 3],
            ]
        }
        loopRectangle(data.width, data.height, at)(coord => {
            this.put(
                coord,
                combineColors(
                    this.at(coord),
                    getPixel(
                        coord[0] - at[0],
                        coord[1] - at[1]
                    ),
                )
            );
        })
    }

    private _apply = (
        context: CanvasRenderingContext2D,
        [x, y]: Coord = [0, 0]
    ) => {
        context.putImageData(
            new ImageData(this.data, this.width),
            x,
            y
        );
    };

    apply = (
        context: CanvasRenderingContext2D,
        [x, y]: Coord = [0, 0],
        width?: number,
        height?: number,
    ) => {
        this._apply(this.smallContext);
    
        context.imageSmoothingEnabled = false;
        if (width && height) {
            context.drawImage(
                this.smallContext.canvas,
                x,
                y,
                width,
                height,
            );
        } else {
            context.drawImage(
                this.smallContext.canvas,
                x,
                y
            );
        }
    };

};
