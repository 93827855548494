import { MapApp } from 'maps';
import { ImageDataBuilder } from 'image';
import {
    Coord,
    coordToIndex,
    loopRectangle,
} from 'coords';
import { GameState } from 'game';
import { Color, theme } from 'color';

export const fogPlugin = (state: GameState) => (map: MapApp) => {
    // values must be integers between 0 - 255
    if (!state.loaded) {
        state.fog.data = new Uint8ClampedArray(
            map.grid.totalWidth * map.grid.totalHeight
        );
    }
    const halfViewWidth = map.grid.viewWidth / 2;
    const halfViewHeight = map.grid.viewHeight / 2;

    const image = new ImageDataBuilder(
        map.renderWidth,
        map.renderHeight
    );
    
    return {
        // // wtf this slows it down?
        // pixelRenderer: {
        //     image,
        //     renderStart: () => {
        //         const location: Coord = [
        //             Math.floor(map.gps.location[0]),
        //             Math.floor(map.gps.location[1]),
        //         ];
        //         // cutout current location
        //         state.fog.cutoutDisc(
        //             map.grid.totalWidth,
        //             [
        //                 Math.floor(
        //                     (location[0] + halfViewWidth) - state.fog.cutout.radius
        //                 ),
        //                 Math.floor(
        //                     (location[1] + halfViewHeight) - state.fog.cutout.radius
        //                 ),
        //             ]
        //         );
        //     },
        //     onRenderPixel: (absCoord: Coord, screenCoord: Coord) => {
        //         const index = coordToIndex(map.grid.totalWidth, absCoord);
        //         image.put(
        //             screenCoord,
        //             [
        //                 theme.fog[0],
        //                 theme.fog[1],
        //                 theme.fog[2],
        //                 255 - state.fog.data[index]
        //             ] as Color
        //         );
        //     }
        // },
        postRender: (width: number, height: number) => {
            const location: Coord = [
                Math.floor(map.gps.location[0]),
                Math.floor(map.gps.location[1]),
            ];
            // cutout current location
            state.fog.cutoutDisc(
                map.grid.totalWidth,
                [
                    Math.floor(
                        (location[0] + halfViewWidth) - state.fog.cutout.radius
                    ),
                    Math.floor(
                        (location[1] + halfViewHeight) - state.fog.cutout.radius
                    ),
                ]
            );

            loopRectangle(width, height)(coord => {
                const index = coordToIndex(map.grid.totalWidth, [
                    coord[0] + location[0],
                    coord[1] + location[1]
                ]);
                image.put(
                    coord,
                    [
                        theme.fog[0],
                        theme.fog[1],
                        theme.fog[2],
                        255 - state.fog.data[index]
                    ] as Color
                );
            });

            return image;
        },
        fullRender: (width: number, height: number) => {
            const image = new ImageDataBuilder(width, height);
            const scaleX = (x: number) => Math.round((x / width) * map.grid.totalWidth);
            const scaleY = (y: number) => Math.round((y / height) * map.grid.totalHeight);

            if (!state.status.values.atHome) {
                loopRectangle(width, height)(coord => {
                    const index = coordToIndex(map.grid.totalWidth, [
                        scaleX(coord[0]),
                        scaleY(coord[1]),
                    ]);
                    image.put(
                        coord,
                        [
                            theme.fog[0],
                            theme.fog[1],
                            theme.fog[2],
                            255 - state.fog.data[index]
                        ] as Color
                    );
                });
            }

            return image;
        },
    };
};

