export type ItemCollection = {
    symbol: string;
    current: number;
    total?: number;
};

export type HudData = {
    content: string;
    emojis?: string;
    items?: ItemCollection[];
    status?: 'open' | 'completed';
    modal?: boolean;
}

export type Hud = {
    id: string;
    data: HudData;
};

export type HudRecord = Record<string, Hud>;

export type SetHud = (id: string, data: HudData | undefined) => void;

export const itemCollectionsAreEqual = (
    itemsA?: ItemCollection[],
    itemsB?: ItemCollection[]
): boolean => {
    if (itemsA && !itemsB) {
        return false;
    }
    if (itemsB && !itemsA) {
        return false;
    }
    if (!itemsA && !itemsB) {
        return true;
    }
    if (itemsA && itemsA.length) {
        for (let i = 0; i < itemsA.length; i++) {
            if (itemsA[i].symbol !== itemsB![i].symbol) {
                return false;
            }
            if (itemsA[i].current !== itemsB![i].current) {
                return false;
            }
            if (itemsA[i].total !== itemsB![i].total) {
                return false;
            }
        }
    }
    return true;
}
export const hudDatasAreEqual = (
    dataA: HudData,
    dataB: HudData
): boolean => (
    dataA.content === dataB.content
    && dataA.emojis === dataB.emojis
    && itemCollectionsAreEqual(dataA.items, dataB.items)
);

