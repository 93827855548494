import React, {
    useState,
    useEffect,
    FC,
} from 'react';
import { Global, css } from '@emotion/react';
import styled from '@emotion/styled';
import { IdbStore } from 'store';
import { GameStateSerialized } from "game";
import {
    FullHeight,
    MapComponent,
    Accordion,
    BackgroundImage,
    // PreloadImages,
} from 'components';
import {
    GameMode,
    dailySeed,
} from 'game';
import { emojiStrings, EmojiText } from 'image';
import {
    CardsLayout,
    useCardState,
} from 'cards';

const Layout = styled(FullHeight)`
    position: fixed;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5em;
    /* justify-content: center; // breaks overflow */
    ::before, ::after {
        content: '';
        margin: auto; /* Make it push flex items to the center */
    }
    a, a:visited {
        color: #2255cc;
        background-color: rgba(0, 0, 0, 0.4);
        padding: 0 .3em;
        border-radius: 0.2em;
    }
    a:hover {
        color: #2266dd;
    }
`;

const MAX_WIDTH = '300px';
const Button = styled.button`
    display: block;
    width: 100%;
    max-width: ${MAX_WIDTH};
    background-color: rgba(255, 255, 255, 0.3);
    color: black;
    border: transparent;
    padding: 1em;
    font-size: 1em;
    white-space: nowrap;
    font-weight: 700;
    > span {
        font-size: 0.85em;
        display: block;
        margin-top: 0.4em;
        opacity: 0.8;
    }
    > i {
        font-family: OutlineEmoji;
        font-style: normal;
    }
    :hover:enabled {
        background-color: rgba(255, 255, 255, 0.6);
    }
    :active {
        background-color: rgba(200, 200, 200, 0.4);
    }
    :disabled, [disabled] {
        background-color: rgba(170, 170, 170, 0.3);
        color: #333;
    }
`;
const HelpContent = styled.div`
    max-width: ${'500px' || MAX_WIDTH};
    margin: 2em 1em;
    width: 90%;
`;
const Pre = styled.pre`
    overflow: auto;
`;
const RandomRow = styled.div`
    display: flex;
    width: 100%;
    max-width: ${MAX_WIDTH};
    box-sizing: border-box;
    flex-direction: row;
    gap: .5em;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 0.25em;
    > p {
        color: rgba(255, 255, 255, 0.5);
    }
`;

const calculateRemainingTillNew = () => {
    const milliseconds = dailySeed(1) - (new Date().getTime());

    // https://bobbyhadz.com/blog/javascript-convert-milliseconds-to-hours-and-minutes
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
  
    seconds = seconds % 60;
    minutes = seconds >= 30 ? minutes + 1 : minutes;
  
    minutes = minutes % 60;
    hours = hours % 24;

    return (
        hours < 10 ? `0${hours}` : hours.toString()
    ) + ':' + (
        minutes < 10 ? `0${minutes}` : minutes.toString()
    );
};

const dailyStore = new IdbStore<GameStateSerialized>('dailyGameState');
const secondaryStore = new IdbStore<GameStateSerialized>('secondaryGameState');

const App: FC = () => {
    const [shouldLoad, setShouldLoad] = useState<boolean>(false);
    const [hasSavedDaily, setHasSavedDaily] = useState<boolean | undefined>(undefined);
    const [savedDailyIsToday, setSavedDailyIsToday] = useState<boolean>(false);
    const [hasSavedSecondary, setHasSavedSecondary] = useState<boolean | undefined>(undefined);
    const [mode, setMode] = useState<GameMode | undefined>(undefined);
    const [remainingTime, setRemainingTime] = useState<string>(calculateRemainingTillNew)
    const cardsApi = useCardState();
    useEffect(() => {
        if (mode) {
            // if there's a mode, they're playing
            return;
        }
        setRemainingTime(
            calculateRemainingTillNew()
        );
        (async () => {
            const data = await dailyStore.select();

            setSavedDailyIsToday(data ? data.seed === dailySeed() : false);
            setHasSavedDaily(!!data);
        })();
        (async () => {
            setHasSavedSecondary(
                !!await secondaryStore.hasSaved()
            );
        })();
    }, [mode]);

    return <>
        <Global styles={css`
            html, body {
                margin: 0;
                padding: 0;
                background-color: #111;
                color: #999;
                overscroll-behavior: contain;
                font-family: 'BenchNine', sans-serif;
                font-weight: 300;
            }
            button {
                font-family: 'BenchNine', sans-serif;
                font-weight: 300;
            }
            h1, h2 {
                font-family: 'Elsie Swash Caps', cursive;
            }
            h3, h4, h5, h6 {
                font-family: 'BenchNine', sans-serif;
                font-weight: 700;
            }
        `} />
        <CardsLayout {...cardsApi} />
        {mode ? (
            <MapComponent
                store={mode === 'daily' ? dailyStore : secondaryStore}
                mode={mode}
                returnToMenu={() => setMode(undefined)}
                shouldLoad={shouldLoad}
                giveCard={cardsApi.giveCard}
                setCardPanelOpen={cardsApi.setOpen}
            />
        ) : <>
            <BackgroundImage />
            {/* <PreloadImages /> // 400 is too many :O */}
            <Layout>
                <Button
                    onClick={() => {
                        setShouldLoad(savedDailyIsToday);
                        setMode('daily');
                    }}
                    disabled={typeof hasSavedDaily === 'undefined' || !cardsApi.loaded}
                >
                    <i>{emojiStrings.tree01}</i>
                    Play Now!
                    <i>{emojiStrings.tree01}</i>
                    <span>
                        {savedDailyIsToday ? 'Continue' : 'Start'} Today's Map - {remainingTime} until it resets
                    </span>
                </Button>
                {hasSavedDaily && (
                    <RandomRow>
                        <p>Custom/Random</p>
                        <Button
                            onClick={() => {
                                const userResponse = window.prompt('Custom seed (enter nothing for random)?');

                                if (typeof userResponse != 'string')
                                    return;

                                const seed = parseInt(userResponse || '');
                                setShouldLoad(false);
                                if (!isNaN(seed) && seed !== 0) {
                                    setMode(seed);
                                } else {
                                    setMode(
                                        Math.round(Math.random() * 999999999) + 100000
                                    );
                                }
                            }}
                            disabled={typeof hasSavedSecondary === 'undefined' || !cardsApi.loaded}
                        >
                            New
                        </Button>
                        <Button
                            onClick={() => {
                                setShouldLoad(true);
                                setMode(1);  // doesn't matter, will be overwritten
                            }}
                            disabled={!hasSavedSecondary || !cardsApi.loaded}
                        >
                            Load Saved
                        </Button>
                    </RandomRow>
                )}
                {cardsApi.loaded && cardsApi.hasAnyCards() && (
                    <Button
                        onClick={() => cardsApi.setOpen(true)}
                        disabled={!cardsApi.loaded}
                    >
                        <i>🎴</i> Your Cards
                    </Button>
                )}
                <HelpContent>
                    <h2>
                        Welcome to FidgetMap!
                    </h2>
                    <p>
                        FidgetMap is a casual game about exploration and discovery. There's a daily map that saves automatically and can be resumed all day. Tomorrow it will be gone but a new one will be ready to explore.
                    </p>
                    <Accordion
                        buttonText='First Time Players'
                        expandContent={<>
                            <p>
                                There are a few tools lost near your cabin that will help you on your journeys. You'll likely find lots of marbles, too. You get to keep them from map-to-map. Lucky you!
                            </p>
                            <p>
                                When you're at your cabin, you can see the entire world map. When you leave your cabin, you can only see where you've been before.
                            </p>
                            <p>
                                Ponds, lakes, and oceans have three layers of depth. With your items and upgrades you can swim across shallow and medium depth water. 
                            </p>
                            <p>
                                The map has large forests. Without hiking boots the forest will slow you down because the trees are thick.
                            </p>
                            <h3>Items to Collect</h3>
                            <p>
                                <EmojiText>{emojiStrings.candle}➡️{emojiStrings.flashlight}</EmojiText> You'll find a candle and a flashlight on the map. Each of these items make it easier to see in the fog. The flashlight is an upgrade to the candle, allowing you to see farther.
                            </p>
                            <p>
                                <EmojiText>{emojiStrings.shoes}➡️{emojiStrings.boots}</EmojiText> You'll find tennis shoes and hiking boots on the map. When you start at home, you're barefoot, so you are slow. The tennis shoes speed you up, but don't help you traverse the woods. The hiking boots speed you up in the woods.
                            </p>
                            <p>
                                <EmojiText>{emojiStrings.goggles}➡️{emojiStrings.surf}</EmojiText> You'll find a diving mask in shallow water, then a surfboard in slightly deeper water. The diving mask will make it possible to swim into deeper water. The surfboard will speed you up on most water. 
                            </p>
                            <p>
                                <EmojiText>{emojiStrings.marble}</EmojiText> Collect marbles across the map. They save across maps, soon we hope to offer upgrades with your marbles.
                            </p>
                            <p>
                                <EmojiText>{emojiStrings.lantern}</EmojiText> You'll find lanterns across the land. The lanterns light up large portions of the map when touched. 
                            </p>
                        </>}
                    />
                    <Accordion
                        buttonText='The Fine Print'
                        expandContent={<>
                            <p>
                                fidgetmap is in constant, spontaneous development. The devs chase whatever ridiculous idea they have at the moment. But aren't the maps pretty?
                            </p>
                            <p>
                                As such, fidgetmap comes with no guarantee to be bug-free or prefect. Or even work all the time, really. Please be patient with us in these early stages.
                            </p>
                            <p>
                                That said, we have a strong commitment to making an inclusive, safe, relaxing experience for everyone. There will be no "jump scares". Anything requiring extra dexterity will be opt-in.
                            </p>
                            <p>
                                Making a game like this accessible is very very difficult. But we hope to be available to as many users as possible for a relaxing romp around fidgetmap.
                            </p>
                        </>}
                    />
                    <Accordion
                        buttonText="Technical Details"
                        expandContent={<>
                            <p>
                               FidgetMap is written in Typescript and React, using the browser Canvas API. It does not use any game building framework (such as phaser or unity). The graphics are generated by drawing color data into an array that is then composited onto a canvas. 
                            </p>
                            <p>
                                Slobaum has been generating maps in JavaScript for years. Only recently did he have a breakthrough that made them fast enough to be practical for a generative game. It uses a simplex noise library to layer noise values into the land masses and water-ways you see. It layers other noise values to generate trees and variations among them.
                            </p>
                            <p>
                                The item graphics are all generated in your browser using your system emojis. That means the game will look subtly (or not so subtly) different on different platforms.
                            </p>
                            <p>
                                It uses indexedDB to save your in-progress game to your local file system. If you switch browsers, phones, or computers, your saved map will not come with you. This may change in the future. 
                            </p>
                            <p>
                                Older maps by slobaum (just experiments, not games):
                            </p>
                            <ul>
                                <li>
                                    <a href="https://codepen.io/slobaum/pen/gOBGJe">
                                        Isometric map using Obelisk.js and NoiseJs
                                    </a>
                                </li>
                                <li>
                                    <a href="https://codepen.io/slobaum/pen/xxRbKve">
                                        Map generator v3 - 12 concurrent web workers rendering
                                    </a>
                                </li>
                                <li>
                                    <a href="https://codepen.io/slobaum/pen/qBazZEE">
                                        Map generator v2
                                    </a>
                                </li>
                                <li>
                                    <a href="https://codepen.io/slobaum/pen/bGwZPMB">
                                        Map generator v1
                                    </a>
                                </li>
                                <li>
                                    <a href="https://codepen.io/slobaum/pen/aeqvLq">
                                        React Isometric SVG
                                    </a>
                                </li>
                                <li>
                                    <a href="https://codepen.io/slobaum/pen/peyJwW">
                                        React + noise
                                    </a>
                                </li>
                                <li>
                                    <a href="https://codepen.io/slobaum/pen/RaxYMJ">
                                        Random side scroller landscape (similar tech)
                                    </a>
                                </li>
                                <li>
                                    And one that was lost to time. I probably have the source somewhere…
                                </li>
                            </ul>
                        </>}
                    />
                    <Accordion
                        buttonText="AI Images from DALL-E MINI"
                        expandContent={<>
                            <p>
                                You will likely see some weird looking, dream-like imagery in this game. These were pre-generated from text prompts by <a href="https://github.com/borisdayma/dalle-mini" target="_blank" rel="noopener noreferrer">DALL-E mini</a> using AI. Image generation credit goes to them, I wrote the text prompts and harvested the images.
                            </p>
                            <p>
                                <a href="https://github.com/borisdayma/dalle-mini" target="_blank" rel="noopener noreferrer">DALL-E Mini Github</a>
                            </p>
                            <p>
                                <a href="https://huggingface.co/spaces/dalle-mini/dalle-mini" target="_blank" rel="noopener noreferrer">DALL-E Mini Official Demo</a>
                            </p>
<Pre>{`@misc{Dayma_DALL·E_Mini_2021,
      author = {Dayma, Boris and Patil, Suraj and Cuenca, Pedro and Saifullah, Khalid and Abraham, Tanishq and Lê Khắc, Phúc and Melas, Luke and Ghosh, Ritobrata},
      doi = {10.5281/zenodo.5146400},
      month = {7},
      title = {DALL·E Mini},
      url = {https://github.com/borisdayma/dalle-mini},
      year = {2021}
}`}</Pre>
                        </>}
                    />
                </HelpContent>
            </Layout>
        </>}
    </>;
}

export default App;

