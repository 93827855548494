import React, { FC, useCallback } from 'react';
import styled from '@emotion/styled';
import { Hud } from './types';
import { EmojiText } from 'image';

export const QuestModalBackdrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    background: #00000009;
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
    align-items: center;
    // allows overflow out the top
    // justify-content: center;
    gap: 1rem;
    padding: 1rem 0;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 9999;
    /* & + & {
        display: none;
    } */
    box-sizing: border-box;
`;
const ModalWrap = styled.div`
    background-color: #FFFFFF99;
    padding: 1rem 2rem;
    margin: auto 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 1px 2px 1px #00000066;
    color: black;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    > p {
        margin: 0;
        padding: 0;
        flex: 1;
        font-size: 1.3rem;
    }
`;
const Items = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    font-size: 2rem;
`;
const Item = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const Button = styled.button`
    display: block;
    width: 100%;
    box-sizing: border-box;
    font-size: 1.5rem;
    background: #00000022;
    border: 0;
`;

export const QuestModal: FC<{
    hud: Hud;
    acknowledge: (id: string) => void;
}> = ({
    hud,
    acknowledge,
}) => (
    <ModalWrap>
        <p>
            {hud.data.emojis && (
                <EmojiText>{hud.data.emojis}</EmojiText>
            )}
            {hud.data.content}
        </p>
        {hud.data.items && (
            <Items>
                {hud.data.items?.map(item =>
                    <Item key={item.symbol}>
                        <EmojiText>{item.symbol}</EmojiText>
                        <div>
                            {item.current}{item.total && `/${item.total}`}
                        </div>
                    </Item>
                )}
            </Items>
        )}
        <div>
            <Button
                type="button"
                onClick={useCallback(
                    () => acknowledge(hud.id),
                    [acknowledge, hud.id]
                )}
            >
                {hud.data.status === 'completed'
                    ? 'Complete ✅'
                    : 'Okay'}
            </Button>
        </div>
    </ModalWrap>
);

