import { theme } from 'color';
import {
    Coord,
    loopRectangle,
    Gps,
    Dim,
} from 'coords';
import { ImageDataBuilder } from 'image';
import {
    GenerationProgress,
    ProgressGenerator,
} from 'generate';
import { Levels, Level } from './Levels';
import { NoiseEntities } from './NoiseEntities';
import { MapGrid } from './MapGrid';
import { RandomInts } from 'noise';

let benchmarkHistory: number[] = [];
const startBenchmark = () => {
    // @ts-ignore
    if (!window.fidgetmapdebug) { // set in index.html
        return () => {};
    }

    const startTime = Date.now();
    return () => {
        const renderTime = Date.now() - startTime;
        benchmarkHistory = [
            ...benchmarkHistory.slice(0, 100),
            renderTime,
        ];

        let total = 0;
        let len = benchmarkHistory.length;
        for (let i = 0; i < len; i++) {
            total += benchmarkHistory[i];
        }
        // @ts-ignore
        window.fidgetmaprendertime = total / len;
    }
};

const LEVEL_DEEP_WATER = new Level(10, theme.levels.water.deep);
const LEVEL_WATER = new Level(6, theme.levels.water.medium);
const LEVEL_SHALLOW_WATER = new Level(2, theme.levels.water.shallow);
const LEVEL_BEACH = new Level(3, theme.levels.land.beach);
const LEVEL_PLAIN = new Level(5, theme.levels.land.plain);
const LEVEL_HILLS = new Level(15, theme.levels.land.hills);

export type RenderPlugin = (
    width: number,
    height: number
) => ImageDataBuilder;
export type PixelRenderPlugin = {
    image: ImageDataBuilder;
    renderStart: () => void;
    onRenderPixel: (
        absoluteCoord: Coord,
        screenCoord: Coord,
        level: Level,
    ) => void;
}
export type MapPlugin = (map: MapApp) => void | {
    pixelRenderer?: PixelRenderPlugin;
    postRender?: RenderPlugin;
    fullRender?: RenderPlugin;
};

const fixSize = (canvas: HTMLCanvasElement) => {
    if (canvas.clientWidth !== canvas.width || canvas.clientHeight !== canvas.height) {
        // @ts-ignore
        canvas.width = undefined;
        // @ts-ignore
        canvas.height = undefined;
        canvas.width = canvas.clientWidth;
        canvas.height = canvas.clientHeight;
    }
};

const BLOCK_SIZE = 3;
export class MapApp {
    grid: MapGrid;
    levels: Levels;
    context: CanvasRenderingContext2D;
    entities: NoiseEntities[];
    trees: NoiseEntities[];
    gps: Gps;
    playing: boolean = false;
    renderPlugins: Array<{
        postRender: RenderPlugin | void;
        pixelRender: PixelRenderPlugin | void;
     }> = []; 
    fullRenderPlugs: RenderPlugin[] = []; 
    onRenderEnd: () => void;
    private builder: ImageDataBuilder;
    ints: RandomInts;
    constructor(
        canvas: HTMLCanvasElement,
        plugins: MapPlugin[] = [],
        gps: Gps,
        onRenderEnd: () => void,
        seed?: number,
    ) {
        // @ts-ignore
        this.context = canvas.getContext('2d');

        if (!this.context) {
            throw new Error('unable to get canvas context');
        }
        fixSize(canvas);
        this.onRenderEnd = onRenderEnd;
        this.grid = new MapGrid(
            canvas,
            BLOCK_SIZE,
            gps.totalDim,
            seed
        );
        this.ints = new RandomInts(this.grid.noise.seed);
        this.gps = gps;
        this.gps.calibrate([
            this.grid.viewWidth,
            this.grid.viewHeight
        ]);
        this.levels = new Levels([
            LEVEL_DEEP_WATER,
            LEVEL_WATER,
            LEVEL_SHALLOW_WATER,
            LEVEL_BEACH,
            LEVEL_PLAIN,
            LEVEL_HILLS,
        ], this.grid.noise);
        this.trees = [
            new NoiseEntities({
                width: this.grid.totalWidth,
                height: this.grid.totalHeight,
                aboveLevel: LEVEL_BEACH,
                color: theme.woods.light,
                limit: .5,
                seed,
            }),
            new NoiseEntities({
                width: this.grid.totalWidth,
                height: this.grid.totalHeight,
                aboveLevel: LEVEL_BEACH,
                color: theme.woods.deep,
                limit: .7,
                seed: seed && seed * 123,
                scale: 11,
            }),
            // new NoiseEntities({
            //     width: this.grid.totalWidth,
            //     height: this.grid.totalHeight,
            //     aboveLevel: LEVEL_PLAIN,
            //     color: theme.woods.deep,
            //     limit: .6,
            //     seed,
            // }),
        ];
        this.entities = [
            // sand/hill texture
            new NoiseEntities({
                width: this.grid.totalWidth,
                height: this.grid.totalHeight,
                aboveLevel: LEVEL_SHALLOW_WATER,
                color: theme.levels.land.plain,
                limit: .65,
                seed,
                scale: .3,
            }),

            // shrubs
            new NoiseEntities({
                width: this.grid.totalWidth,
                height: this.grid.totalHeight,
                aboveLevel: LEVEL_BEACH,
                color: theme.woods.light,
                limit: .7,
                seed,
                scale: 1,
            }),

            // ...this.trees,
        ];
        plugins.forEach(plugin => {
            const result = plugin(this);

            this.renderPlugins.push({
                postRender: result && result.postRender,
                pixelRender: result && result.pixelRenderer,
            })
            if (result && result.fullRender) {
                this.fullRenderPlugs.push(
                    result.fullRender
                );
            }
        });
        this.builder = new ImageDataBuilder(
            this.renderWidth,
            this.renderHeight
        );
    };

    roadWidth: number = 14;
    halfRoadWidth: number = 7;

    roads: Array<{ coord: Coord, dim: Dim }> = [];
    addRoadHorz = (y: number, x1: number, x2: number) => {
        const xLow = Math.min(x1, x2);
        const xHigh = Math.max(x1, x2);

        this.roads.push({
            coord: [ xLow-this.halfRoadWidth, y-this.halfRoadWidth ],
            dim: [ (xHigh-xLow)+this.roadWidth, this.roadWidth ],
        });
    };
    addRoadVert = (x: number, y1: number, y2: number) => {
        const yLow = Math.min(y1, y2);
        const yHigh = Math.max(y1, y2);

        this.roads.push({
            coord: [ x-this.halfRoadWidth, yLow-this.halfRoadWidth ],
            dim: [ this.roadWidth, (yHigh-yLow)+this.roadWidth ],
        });
    };

    // caching is more expensive than this calculation.
    isOnRoad = (at: Coord) =>
        this.roads.some(({ coord, dim }) => {
            const right: number = coord[0] + dim[0];
            const bottom: number = coord[1] + dim[1];

            return (
                (at[0] > coord[0] && at[0] < right)
                && (at[1] > coord[1] && at[1] < bottom)
            );
        });

    // oh wow, caching is more expensive than this calculation.
    // ah, tree entities are already backed by a LUT.
    // private treeCache: CoordCache<boolean> = new CoordCache();
    isInTree = (at: Coord) => {
        if (this.isOnRoad(at)) {
            return false;
        }

        const level = this.levels.getLevelAt(at);

        return this.trees.some(entity => entity.render(at, level));
    }

    pixelCoordToViewport = (coord: Coord): Coord => {
        return [
            Math.round(coord[0] / this.grid.blockSize),
            Math.round(coord[1] / this.grid.blockSize),
        ];
    }
    get renderWidth() {
        return this.grid.viewWidth + this.renderOverage;
    }
    get renderHeight() {
        return this.grid.viewHeight + this.renderOverage;
    }
    async * generate(): ProgressGenerator {
        const generators = [
            this.grid.generate(),
            ...this.entities.map(entity => entity.generate()),
            ...this.trees.map(entity => entity.generate()),
        ];
        let allDone = false;

        while (!allDone) {
            const results = [];
            for (let i = 0; i < generators.length; i++) {
                results.push(await generators[i].next());
            }
            allDone = results.every(result => result.done);
            yield results.reduce(
                (acc, result) => result && result.value ? [
                    acc[0] + result.value[0],
                    acc[1] + result.value[1]
                ] : acc,
                [0, 1] as GenerationProgress
            );
        }
    };
    animate = async (
        onStep?: (() => void),
    ) => {
        this.playing = true;
        do {
            await new Promise(resolve =>
                requestAnimationFrame(() => {
                    onStep && onStep();
                    this.render().then(resolve);
                })
            );
        } while(this.playing);
    };

    renderFull = async (canvas: HTMLCanvasElement) => {
        fixSize(canvas);
        let width: number;
        let height: number;
        let topLeft: Coord;

        if (canvas.width > canvas.height) {
            height = canvas.height;
            width = Math.round(
                (
                    this.grid.totalWidth / this.grid.totalHeight
                ) * height
            );
            topLeft = [
                Math.round(
                    (canvas.width - width) * .5
                ),
                0,
            ];
        } else {
            width = canvas.width;
            height = Math.round(
                (
                    this.grid.totalHeight / this.grid.totalWidth
                ) * width
            );
            topLeft = [
                0,
                Math.round(
                    (canvas.height - height) * .5
                ),
            ];
        }

        const scaleX = (x: number) => Math.round((x / width) * this.grid.totalWidth);
        const scaleY = (y: number) => Math.round((y / height) * this.grid.totalHeight);
        const builder = new ImageDataBuilder(width, height);

        loopRectangle(width, height)(coord => {
            const scaledCoord: Coord = [
                scaleX(coord[0]),
                scaleY(coord[1])
            ];
            const level = this.levels.getLevelAt(
                scaledCoord
            );
            builder.put(coord, level.color);
            this.entities.forEach((entity, i) => {
                const color = entity.render(scaledCoord, level);
                if (color)
                    builder.put(coord, color);
            });
        });

        const context = canvas.getContext('2d')!;
        builder.apply(context, topLeft);

        this.fullRenderPlugs.forEach(plug => {
            plug(width, height)
                .apply(context, topLeft);
        });

        const topBuilder = new ImageDataBuilder(width, height);
        const center: Coord = [
            Math.round(width / 2) - 4,
            Math.round(height / 2) - 4,
        ];
        topBuilder.rect(
            center,
            theme.home.line,
            8
        );

        const loc: Coord = [
            Math.round(((this.gps.location[0] + (this.grid.viewWidth / 2)) / this.grid.totalWidth) * width) - 4,
            Math.round(((this.gps.location[1] + (this.grid.viewHeight / 2)) / this.grid.totalHeight) * height) - 4,
        ];
        topBuilder.rect(
            loc,
            theme.player.outline,
            8
        );
        topBuilder.rect(
            [ loc[0] + 1, loc[1] + 1 ],
            theme.player.core,
            6
        );
        topBuilder.apply(context, topLeft);

        // console.group('player distance from roads');
        // this.roads.some((road, iterator) =>
        //     console.log(
        //         `road ${iterator}`,
        //         distanceFromLine(this.gps.locationCenter, road)
        //     )
        // );
        // console.groupEnd();
    };

    renderOverage = 20;
    render = async () => {
        const wide = this.renderWidth;
        const tall = this.renderHeight;

        const pxOverage = this.renderOverage * BLOCK_SIZE;
        const halfPx = Math.round(pxOverage * .5);
        const placement: Coord = [
            -(halfPx + (this.gps.location[0] % 1)),
            -(halfPx + (this.gps.location[1] % 1))
        ];
        const applyWidth = this.context.canvas.width + pxOverage;
        const applyHeight = this.context.canvas.height + pxOverage;

        const benchmarkRender = startBenchmark();
        this.renderPlugins.forEach(({ pixelRender }) =>
            pixelRender && pixelRender.renderStart()
        );
        loopRectangle(wide, tall)(coord => {
            const absCoord: Coord = [
                coord[0] + Math.floor(this.gps.location[0]),
                coord[1] + Math.floor(this.gps.location[1])
            ];
            const level = this.levels.getLevelAt(absCoord);

            this.builder.put(coord, level.color);
            this.entities.forEach((entity, i) => {
                const color = entity.render(absCoord, level);
                if (color)
                    this.builder.put(coord, color);
            });
            this.renderPlugins.forEach(({ pixelRender }) =>
                pixelRender && pixelRender.onRenderPixel(
                    absCoord,
                    coord,
                    level
                )
            );
        });
        this.builder.apply(
            this.context,
            placement,
            applyWidth,
            applyHeight
        );
        this.renderPlugins.forEach(({
            postRender,
            pixelRender,
        }) => {
            postRender && postRender(wide, tall).apply(
                this.context,
                placement,
                applyWidth,
                applyHeight
            );
            pixelRender && pixelRender.image.apply(
                this.context,
                placement,
                applyWidth,
                applyHeight
            );
        });
        this.onRenderEnd();
        benchmarkRender();
    };
};
